import React, { useContext, useEffect, useState } from "react";
import { GoHeart } from "react-icons/go";
import { PiHandbagSimpleDuotone } from "react-icons/pi";
import { BsPerson } from "react-icons/bs";
import { MdOutlinePhoneInTalk } from "react-icons/md";
import { CiSearch } from "react-icons/ci";
import { CiLocationOn } from "react-icons/ci";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { SlHandbag } from "react-icons/sl";
import Pickle from "../components/Data.js";
import { NavContext } from "../context/NavProvider";
import "../components/css/navbar.css";
import logo from "../Forms/components/Avakaaya logo.png";
const Navbar = ({ scrollToSection, handleLogin }) => {
  const currentlocation = useLocation();
  const {
    NavTabs,
    myprofile,
    tabfilter,
    UserProfile,
    token,
    setFilter,
    filter,
  } = useContext(NavContext);

  const [search, setSearch] = useState("");
  const [category, setCategory] = useState(tabfilter);
  const [activeTab, setActiveTab] = useState(null);
  const [collapsed, setCollapsed] = useState(true);
  const navigate = useNavigate();

  const handleTab = (tabvalue) => {
    NavTabs(tabvalue);
    setActiveTab(tabvalue);
    setCollapsed(true);
  };

  const handleAboutUsClick = () => {
    navigate("/", { state: { scrollToAboutUs: true } });
    setTimeout(() => {
      scrollToSection("aboutUs");
    }, 100);
    setCollapsed(true);
    setActiveTab(null);
  };

  const handleContactUsClick = () => {
    navigate("/", { state: { scrollToContact: true } });
    setTimeout(() => {
      scrollToSection("contactUs");
    }, 100);
    setCollapsed(true);
    setActiveTab(null);
  };
  const handleProfileClick = () => {
    navigate("/profile");
    setCollapsed(true);
    setActiveTab(null);
  };

  const handleCartClick = () => {
    navigate("/cart");
    setCollapsed(true);
    setActiveTab(null);
  };

  const handleHomeClick = () => {
    navigate("/");
    setCollapsed(true);
    setActiveTab(null);
  };
  const loginbutton = () => {
    setCollapsed(true);
  };
  const handleTabChange = (selectedTab) => {
    setCategory(selectedTab);
  };

  const handleSearch = () => {
    navigate("/shopingpage", { state: { searchFilter: search } });
  };

  const handleSearchChange = (e) => {
    const searchVal = e.target.value;
    setSearch(searchVal);
    setFilter(searchVal);
  };

  useEffect(() => {}, [search, category, tabfilter]);
  useEffect(() => {
    UserProfile();
  }, []);


  return (
    <>
      <div className="">
        <div className="">
          <div className="sticky-top">
            <div className="location-div">
              <CiLocationOn className="location-icon" />
              <p className="location-para">
                1st Lane Brindavan Gardens, Opp Saffron Restaurant, Beside HP
                Petrol Bunk, Guntur, Andhra Pradesh- 522007
              </p>
            </div>
            <div class="navbar-div">
              <div>
                <Link to="/" onClick={handleHomeClick}>
                  {" "}
                  <img src={logo} alt="Bootstrap" className="top-logo" />
                </Link>
              </div>
              <div className="nav-center-inp">
                <div className="nav-inps">
                  <CiSearch className="search-icon" />
                  <input
                    id="searchInput"
                    className="search-inp"
                    placeholder="search"
                    value={search}
                    onChange={handleSearchChange}
                  />
                </div>
                <div>
                  <button
                    className="btn btn-outline-primary search-btn"
                    type="submit"
                    onClick={handleSearch}
                  >
                    Search
                  </button>
                </div>
              </div>
              <div className="top-nav-icons">
                <Link to="/cart" onClick={handleCartClick}>
                  <div
                    className={`cart-icon-container ${
                      activeTab === "cart" ? "active" : ""
                    }`}
                    onClick={scrollToSection}
                  >
                    <SlHandbag className="nav-icons bags" />
                    {myprofile?.carts &&
                      myprofile.carts?.length > 0 && (
                        <div className="cart-item-count">
                          {myprofile?.carts?.length}
                        </div>
                      )}
                  </div>
                </Link>

                <hr className="navbar-hr" />
                {handleLogin ? (
                  <Link to="/profile" onClick={handleProfileClick}>
                    <BsPerson
                      className={`nav-icons ${
                        activeTab === "profile" ? "active" : ""
                      }`}
                    />
                  </Link>
                ) : (
                  <Link to="/login">
                    {" "}
                    <button className="navbar-login-btn">Login</button>
                  </Link>
                )}
              </div>
            </div>
            <nav className="navbar navbar-expand-lg bg-body-tertiary p-0 nav-main ">
              <div className="container-fluid navbar-container ">
                <div className="container">
                  <nav className="navbar navbar-expand-lg ">
                  <Link to="/"> <img
                      src={logo}
                      alt=" image not found"
                      className="top-logos"
                    /></Link>
                   
                    <button
                      className="navbar-toggler   ms-auto nav-icon"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#navbarText"
                      aria-controls="navbarText"
                      aria-expanded="false"
                      aria-label="Toggle navigation"
                      onClick={() => setCollapsed(!collapsed)}
                    >
                      <span className="navbar-toggler-icon "></span>
                    </button>
                    <div
                      className={`collapse navbar-collapse inner-nav ${
                        collapsed ? "" : "show"
                      }`}
                      id="navbarText"
                    >
                      <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                        <li
                          className={`nav-item ${
                            activeTab === "Non" ? "active" : ""
                          }`}
                          onClick={() => handleTab("Non")}
                        >
                          <Link
                            to="/shopingpage"
                            className={`nav-link anchor-elements ${
                              activeTab === "Non" ? "active-tab" : ""
                            }`}
                          >
                            Non Veg Pickles
                          </Link>
                        </li>
                        <li
                          className={`nav-item ${
                            activeTab === "Veg" ? "active" : ""
                          }`}
                          onClick={() => handleTab("Veg")}
                        >
                          <Link
                            to="/shopingpage"
                            className={`nav-link anchor-elements ${
                              activeTab === "Veg" ? "active-tab" : ""
                            }`}
                          >
                            Veg Pickles
                          </Link>
                        </li>
                        <li
                          className={`nav-item ${
                            activeTab === "masala" ? "active" : ""
                          }`}
                          onClick={() => handleTab("masala")}
                        >
                          <Link
                            to="/shopingpage"
                            className={`nav-link anchor-elements ${
                              activeTab === "masala" ? "active-tab" : ""
                            }`}
                          >
                            Masalas
                          </Link>
                        </li>
                        <li
                          className={`nav-item ${
                            activeTab === "powder" ? "active" : ""
                          }`}
                          onClick={() => handleTab("powder")}
                        >
                          <Link
                            to="/shopingpage"
                            className={`nav-link anchor-elements ${
                              activeTab === "powder" ? "active-tab" : ""
                            }`}
                          >
                            Chilli Powders
                          </Link>
                        </li>
                        <li
                          className={`nav-item ${
                            activeTab === "sweets" ? "active" : ""
                          }`}
                          onClick={() => handleTab("sweets")}
                        >
                          <Link
                            to="/shopingpage"
                            className={`nav-link anchor-elements ${
                              activeTab === "sweets" ? "active-tab" : ""
                            }`}
                          >
                            Sweets
                          </Link>
                        </li>
                        <li
                          className={`nav-item ${
                            activeTab === "kara and vadiyalu" ? "active" : ""
                          }`}
                          onClick={() => handleTab("kara and vadiyalu")}
                        >
                          <Link
                            to="/shopingpage"
                            className={`nav-link anchor-elements ${
                              activeTab === "kara and vadiyalu"
                                ? "active-tab"
                                : ""
                            }`}
                          >
                            Kara Items And Vadiyalu
                          </Link>
                        </li>

                        <li
                          className={`nav-item ${
                            activeTab === "aboutus" ? "active" : ""
                          }`}
                        >
                          <button
                            className={`nav-link anchor-elements ${
                              activeTab === "aboutus" ? "active-tab" : ""
                            }`}
                            onClick={handleAboutUsClick}
                          >
                            About Us
                          </button>
                        </li>
                        <li
                          className={`nav-item ${
                            activeTab === "contactus" ? "active" : ""
                          }`}
                        >
                          <button
                            className={`nav-link anchor-elements ${
                              activeTab === "contactus" ? "active-tab" : ""
                            }`}
                            onClick={handleContactUsClick}
                          >
                            Contact Us
                          </button>
                        </li>
                        <li
                          className={`nav-item   nav-mobile-login${
                            activeTab === "login" ? "active" : ""
                          }`}
                          onClick={loginbutton}
                        >
                          <Link to="/login">
                            {" "}
                            <button className="navbar-login-btn">Login</button>
                          </Link>
                        </li>
                      </ul>
                      {/* <span className="navbar-text nav-right">
                      <MdOutlinePhoneInTalk /> +91 8520034998/9963262324
                    </span> */}
                    </div>
                  </nav>
                </div>
               
              </div>
            </nav>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
