import React, { useContext, useEffect, useState } from "react";
import apiUrl from "../../config/config";
import axios from "axios";
import Pickles from "../../components/Data.js";
import "../css/cart.css";
import { MdOutlineCancel } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";

import { NavContext } from "../../context/NavProvider";
import { HiOutlineMinus } from "react-icons/hi2";
import { FiPlus } from "react-icons/fi";

import { FaArrowLeftLong } from "react-icons/fa6";
import BottomNavbar from "../../components/BottomNavbar";
import { BsCheckLg } from "react-icons/bs";
import { ToastContainer, toast } from "react-toastify";

const Cart = () => {
  const { myprofile, UserProfile } = useContext(NavContext);
  const [cartlist, setCartlist] = useState([]);
  const [subtotal, setSubtotal] = useState(0);
  const [total, setTotal] = useState(0);
  
  const navigate = useNavigate();

  useEffect(() => {
    setCartlist(myprofile?.carts || []);
  }, [myprofile]);



  useEffect(() => {
    if (cartlist?.length > 0) {
      let subTotalAmount = cartlist.reduce(
        (acc, item) => acc + item.price * item.quantity,
        0
      );
      setSubtotal(subTotalAmount);
      setTotal(subTotalAmount);
    } else {
      setSubtotal(0);
      setTotal(0);
    }
  }, [cartlist]);

  const handleProceedToCheckout = () => {
    if (cartlist.length === 0) {
     toast.error("Your cart is currently empty. Please add some items to proceed with your purchase.",{
      className: "toast-message",
     });
    } else {
      navigate("/billing", { state: cartlist });
    }
  };

  const increaseQuantity = (id) => {

    setCartlist(
      cartlist.map((item) => {
        if (item.id === id) {
          return { ...item, quantity: item.quantity + 1 };
        }
        return item;
      })
    );
    updateCartQuantity(id, "inc");
  };

  const decreaseQuantity = (id) => {
    setCartlist(
      cartlist.map((item) => {
        if (item.id === id && item.quantity > 1) {
          return { ...item, quantity: item.quantity - 1 };
        }
        if (item.id === id && item.quantity === 1) {
          removeItem(id);
        }
        return item;
      })
    );
    updateCartQuantity(id, "dec");
  };

  const updateCartQuantity = async (id, scope) => {
    try {
      const res = await axios.post(
        apiUrl + `/v2/cart/updatacartquantity/${myprofile.id}`,
        {
          cartId: id,
          scope: scope,
        },{
          headers: {
            "Content-Type": "application/json",
          },
        
        }
      );
      if (res.status == 200) {
        UserProfile();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const removeItem = async (id) => {
    try {
      const res = await axios.post(apiUrl + `/v2/cart/removeCart/${myprofile.id}`, {
        cartId: id,
      },{ headers: {
        "Content-Type": "application/json",
      },

    });
      if (res.status == 200) {
        UserProfile();
      
      }
    } catch (error) {
      console.log(error);
    }
  };
  const cartToBack = () => {
    navigate("/");
  };
 


  return (
    <>
    <ToastContainer position="top-left"/>
      <div className="container-fluid">
        <div className="container">
          <div className="cart-back-btn">
            <p className="cart-name-mobileview">
              <span className="cart-back-arrow">
                <FaArrowLeftLong size={20} onClick={cartToBack} />
              </span>{" "}
              My Shopping Cart
            </p>
          </div>
          <h1 className="myshoping-cart">My Shopping Cart</h1>
          <div className="cart-container ">
            <div className="cart-left">
              <div className="cart-head-container">
                <div className="cart-head">
                  <h3 className="cart-head-name">PRODUCT</h3>
                  <h3 className="cart-head-name">PRICE</h3>
                </div>
                <div className="cart-head-1">
                  <h3 className="cart-head-name">QUANTITY</h3>
                  <h3 className="cart-head-name cart-name-subtotal">
                    SUBTOTAL
                  </h3>
                </div>
              </div>
              <div className="cart-product-list-container">
                {cartlist && cartlist?.length > 0 ? (
                  <>
                    {cartlist?.map((item, index) => {
                      const productDetails = Pickles.find(
                        (pickle) => pickle.id === item.productId
                      );

                      return (
                        <>
                          <div key={index} className="cart1">
                            <div className="imgPart">
                              <div className="imageDiv">
                                <img
                                  src={productDetails.imageUrl}
                                  alt=""
                                  className="cart-product-img"
                                />
                                <h6 className="cart-product-name">
                                  {productDetails.name}({item.size})
                                  <p className="cart-price-mobile">
                                    <b>
                                      ₹{(item.price * item.quantity).toFixed(2)}
                                    </b>
                                  </p>
                                </h6>
                              </div>
                              <div className="price">
                                <h6 className="cart-product-price">
                                  {item.price}
                                </h6>
                              </div>
                            </div>
                            <div className="quantity">
                              <div className="plus-num-sub-btn">
                                <p
                                  className="sub"
                                  onClick={() => decreaseQuantity(item.id)}
                                >
                                  <HiOutlineMinus />
                                </p>
                                <p className="quantity-number">
                                  {item.quantity}
                                </p>
                                <p
                                  className="plus"
                                  onClick={() => increaseQuantity(item.id)}
                                >
                                  <FiPlus />
                                </p>
                              </div>
                            </div>
                            <div className="removeBtn">
                              <h6 className="cart-product-price">
                                ₹{(item.price * item.quantity).toFixed(2)}
                              </h6>
                              <MdOutlineCancel
                                className="cancel ms-2"
                                onClick={() => removeItem(item.id)}
                              />
                            </div>
                          </div>
                          <p
                            className="mobile-remove-btn"
                            onClick={() =>
                              removeItem(item.id)
                            }
                          >
                            Remove
                          </p>
                        </>
                      );
                    })}
                  </>
                ) : (
                  <p className="cart-head-name text-center p-2 ">
                    Your cart is empty. Please add items to continue shopping.
                  </p>
                )}

                <div className="retun-to-shop-div">
                  <Link to="/shopingpage">
                    <button className="retun-to-shop-btn">
                      Return to shop
                    </button>
                  </Link>
                </div>
              </div>
            </div>

            <div className="cart-left-inner">
              <div className="cart-right">
                <div>
                  <h2 className="cart-total">Cart Total</h2>
                </div>
                <div className="cart-subtotal-div">
                  <p className="subtatal">Subtotal:</p>
                  <p className="cart-amount">₹{subtotal.toFixed(2)}</p>
                </div>
                <hr />
                <div className="cart-subtotal-div">
                  <p className="subtatal">Delivery charges extra:</p>
                  {/* <p className="cart-amount">Free</p> */}
                </div>
                <hr />
                <div className="cart-subtotal-div">
                  <p className="subtatal">Total:</p>
                  <p className="cart-amount">₹{total.toFixed(2)}</p>
                </div>
                <button
                  className="proceed-btn"
                  onClick={handleProceedToCheckout}
                >
                  {" "}
                  Proceed to checkout
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="sticky-bottom">
        <BottomNavbar />
      </div>
    </>
  );
};

export default Cart;
