import React, { useEffect, useState } from "react";
import "../css/login.css";
import { Link, useNavigate } from "react-router-dom";
import { FaRegEyeSlash, FaEye } from "react-icons/fa";
import apiUrl from "../../config/config";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";

const Register = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
    mobile: "",
    termsAccepted: false,
  });
  const [isFieldFocused, setIsFieldFocused] = useState(false);
  const [errors, setErrors] = useState({});
  const [formError, setFormError] = useState("");
  const navigate = useNavigate();
  const togglePasswordVisibility = () => {
    setIsFieldFocused(!isFieldFocused);
  };

  const handleChange = (e) => {
    const { name, value, checked, type } = e.target;

    // Update form data
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: type === "checkbox" ? checked : value,
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));

    if (name === "password" || name === "confirmPassword") {
      const trimmedValue = value.replace(/^\s+/g, "");
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: trimmedValue,
      }));
    }
    if (name === "name") {
      const trimmedValue = value.replace(/^\s+/g, "");
      const nonNumericValue = trimmedValue.replace(/\d/g, "");
      setFormData((prevDetails) => ({
        ...prevDetails,
        [name]: nonNumericValue,
      }));
    }
  };

  useEffect(() => {
    const referrerPolicy = document.createElement("meta");
    referrerPolicy.name = "referrer";
    referrerPolicy.content = "strict-origin-when-cross-origin";
    document.head.appendChild(referrerPolicy);

    return () => {
      document.head.removeChild(referrerPolicy);
    };
  }, []);

  const handleRegister = async (e) => {
    e.preventDefault();

    const { name, email, password, confirmPassword, mobile, termsAccepted } =
      formData;
    const newErrors = {};

    if (!name) {
      newErrors.name = "Please enter your name.";
    }
    if (!email) {
      newErrors.email = "Please enter your email.";
    }

    if (!password) {
      newErrors.password = "Please enter your password.";
    }
    if (!confirmPassword) {
      newErrors.confirmPassword = "Please confirm your password.";
    }
    const gmailRegex = /^[^\s@]+@gmail\.com$/i;
    const yahooRegex = /^[^\s@]+@yahoo\.com$/i;
    const hotmailRegex = /^[^\s@]+@hotmail\.com$/i;

    if (
      gmailRegex.test(email) ||
      yahooRegex.test(email) ||
      hotmailRegex.test(email)
    ) {
      // At least one of the conditions is true
      // console.log("Email matches gmail.com, yahoo.com, or hotmail.com");
    } else {
      newErrors.email =
        "Please enter a valid email address ending with gmail.com, yahoo.com, or hotmail.com.";
    }

    if (!mobile) {
      newErrors.mobile = "Please enter your mobile number.";
    }
    if (password !== confirmPassword) {
      newErrors.confirmPassword = "Passwords do not match.";
    }
    if (password.trim().length < 8) {
      newErrors.confirmPassword =
        "Password must be at least 8 characters long.";
    }
    if (!termsAccepted) {
      newErrors.termsAccepted = "Please accept the terms & conditions.";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    try {
      const res = await axios.post(apiUrl + "/v1/user/register", formData, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (res.status == 201) {
        toast.success(res.data.message);
        setTimeout(() => {
          navigate("/login");
        }, 5000);
      }
    } catch (error) {
      console.error("Registration failed:", error);
      setFormError(error.response.data.error);
    }
  };

  return (
    <>
      <ToastContainer position="top-left" />
      <div className="form-container">
        <form onSubmit={handleRegister} className="login-form-data">
          <h4 className="login-heading">Create Account</h4>

          <div className="input-group">
            <div className="register-inputs">
              <input
                type="text"
                placeholder="Enter name"
                className="form-control login-name"
                name="name"
                value={formData.name}
                onChange={handleChange}
              />
              {errors.name && (
                <div className="error-message">{errors.name}</div>
              )}
              <input
                type="text"
                placeholder="Email"
                className="form-control login-name"
                name="email"
                value={formData.email}
                onChange={handleChange}
              />
              {errors.email && (
                <div className="error-message">{errors.email}</div>
              )}
              <input
                // type="tel"
                placeholder="Mobile Number"
                className="form-control login-name"
                name="mobile"
                value={formData.mobile}
                onChange={handleChange}
                maxLength={10}
                minLength={10}
                onInput={(e) => {
                  const numericValue = e.target.value
                    .replace(/\D/g, "")
                    .slice(0, 10);
                  handleChange({
                    target: { name: "mobile", value: numericValue },
                  });
                }}
                onKeyDown={(e) => {
                  if (
                    !(e.key === "Backspace" || e.key === "Delete") &&
                    !/[\d]/.test(e.key)
                  ) {
                    e.preventDefault();
                  }
                }}
                type="text"
              />

              {errors.mobile && (
                <div className="error-message">{errors.mobile}</div>
              )}
              <div className="input-container">
                <input
                  type={isFieldFocused ? "text" : "password"}
                  placeholder="Password"
                  className="form-control login-name"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                />
                {isFieldFocused ? (
                  <FaRegEyeSlash
                    onClick={togglePasswordVisibility}
                    className="eye-icon"
                  />
                ) : (
                  <FaEye
                    onClick={togglePasswordVisibility}
                    className="eye-icon"
                  />
                )}
              </div>
              {errors.password && (
                <div className="error-message">{errors.password}</div>
              )}
              <div className="input-container">
                <input
                  type={isFieldFocused ? "text" : "password"}
                  placeholder="Confirm Password"
                  className="form-control login-name"
                  name="confirmPassword"
                  value={formData.confirmPassword}
                  onChange={handleChange}
                />
                {isFieldFocused ? (
                  <FaRegEyeSlash
                    onClick={togglePasswordVisibility}
                    className="eye-icon"
                  />
                ) : (
                  <FaEye
                    onClick={togglePasswordVisibility}
                    className="eye-icon"
                  />
                )}
              </div>
              {errors.confirmPassword && (
                <div className="error-message">{errors.confirmPassword}</div>
              )}
              <div className="checkbox">
                <input
                  type="checkbox"
                  name="termsAccepted"
                  checked={formData.termsAccepted}
                  onChange={handleChange}
                />
                <span className="login-remember">
                  Accept all Terms & Conditions
                </span>
              </div>
              {errors.termsAccepted && (
                <div className="error-message">{errors.termsAccepted}</div>
              )}
              {formError && <div className="error-message">{formError}</div>}
            </div>
            <div className="mt-5">
              <button type="submit" className="register-btn">
                Create Account
              </button>
            </div>
            <div className="navigate-login login-remember">
              <span>Already have an account </span>
              <Link to="/login" className="navigate-register">
                Login
              </Link>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default Register;
