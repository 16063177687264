import React from "react";
import  "../css/termsandconditions.css"
import BottomNavbar from "../../components/BottomNavbar";
const TermsAndConditions = () => {
  return (
    <>
      <div className="container-fluid"> 
        <div className="container">
          <h1 className="terms-main-heading">Terms and Conditions</h1>
          <h2 className="terms-headings">Introduction</h2>
          <p className="terms-para">
            Welcome to Avakaaya Mart. These terms and conditions outline the
            rules and regulations for the use of our website, located at {" "}
           <a href="www.avakaayamart.com">www.avakaayamart.com.</a> 
          </p>
          <p className="terms-para">
            By accessing this website, we assume you accept these terms and
            conditions. Do not continue to use Avakaaya Mart if you do not agree
            to all the terms and conditions stated on this page.
          </p>
          <h2  className="terms-headings">Accounts and Registration</h2>
          <p className="terms-para">
            To access certain features of the website, you may be required to
            create an account. You agree to provide accurate, current, and
            complete information during the registration process and to update
            such information to keep it accurate, current, and complete.
          </p>
          <p className="terms-para">
            You are responsible for maintaining the confidentiality of your
            account password and for all activities that occur under your
            account. You agree to notify us immediately of any unauthorized use
            of your account.
          </p>
          <h2 className="terms-headings">Orders and Payment</h2>
          <p className="terms-para">
            By placing an order through our website, you are offering to
            purchase a product on and subject to the following terms and
            conditions:
          </p>
          <ul>
            <li className="terms-para">
              All orders are subject to availability and confirmation of the
              order price.
            </li>
            <li className="terms-para">
             Orders will be acceptable by 24/7 365 days
            </li>
            <li className="terms-para">
              Delivery times may vary according to availability and any
              guarantees or representations made as to delivery times are
              subject to any delays resulting from postal delays or force
              majeure for which we will not be responsible.
            </li>
            <li className="terms-para">
              In order to contract with Avakaaya Mart, you must be over 18 years
              of age and possess a valid credit or debit card issued by a bank
              acceptable to us. Avakaaya Mart retains the right to refuse any
              request made by you.
            </li>
          </ul>
          <h2 className="terms-headings"> Pricing and Availability</h2>
          <p className="terms-para">
            While we try to ensure that all details, descriptions, and prices
            appearing on the website are accurate, errors may occur. If we
            discover an error in the price of any goods which you have ordered,
            we will inform you of this as soon as possible and give you the
            option of reconfirming your order at the correct price or canceling
            it. If we are unable to contact you, we will treat the order as
            canceled.
          </p>
          <h2 className="terms-headings">Delivery</h2>
          <p className="terms-para">
            Delivery costs will be charged in addition to the purchase price;
            such additional charges are clearly displayed where applicable and
            included in the 'Total Cost'.
          </p>
        
        </div>
      </div>
      <div>
        <BottomNavbar/>
      </div>

    </>
  );
};

export default TermsAndConditions;
