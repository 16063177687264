import React from "react";
import BottomNavbar from "../../components/BottomNavbar";

const DeliveryandShippingPolicy = () => {
  return (
    <>
      <div className="container-fluid">
        <div className="container">
          <h1 className="terms-main-heading">Delivery and Shipping Policy</h1>
          <p className="terms-para">
            Thank you for choosing Avakaayamart . We are dedicated to providing
            you with the best service and ensuring that your order reaches you
            in a timely manner. Please read the following information regarding
            our delivery and shipping policies:
          </p>
          <h2 className="terms-headings">Order Processing Time:</h2>
          <p className="terms-para">
            All orders are processed within 2-3 business days after they are
            placed, excluding weekends and holidays. Orders placed after 12:00
            PM IST will be processed the next business day.
          </p>
          <h2 className="terms-headings">Shipping Rates:</h2>
          <ul>
            <li className="terms-para">
              Shipping charges are calculated based on the weight of your order
              and the destination address.
            </li>
            <li className="terms-para">
              We offer flat-rate shipping for domestic orders shipping options
              are available with varying rates.
            </li>
          </ul>
          <h2 className="terms-headings">Delivery Time:</h2>
          <ul>
            <li className="terms-para">
              Delivery times vary depending on the shipping method selected
              during checkout and the destination of the package.
            </li>
            <li className="terms-para">
              Domestic orders typically arrive within 3-7 business days after
              they have been processed.
            </li>
          </ul>
          <h2 className="terms-headings">Order Tracking:</h2>
          <ul>
            <li className="terms-para">
              Once your order has been shipped, you will receive a confirmation
              email or what's up with tracking information.
            </li>
          </ul>

          <h2 className="terms-headings">Shipping Carriers:</h2>
          <ul>
            <li className="terms-para">
              We partner with reliable shipping carriers to ensure that your
              order is delivered safely and on time.
            </li>
          </ul>
          <h2 className="terms-headings">Shipping Address:</h2>
          <ul>
            <li className="terms-para">
              Please ensure that the shipping address provided during checkout
              is accurate and complete.
            </li>
            <li className="terms-para">
              We are not responsible for orders shipped to incorrect or
              incomplete addresses provided by the customer.
            </li>
          </ul>
          <h2 className="terms-headings">Delivery Issues:</h2>
          <ul>
            <li className="terms-para">
              In the event of any delivery issues such as lost or damaged
              packages, please contact our customer service team immediately for
              assistance.
            </li>
            <li className="terms-para">
              We will work with the shipping carrier to resolve the issue and
              ensure that you receive your order as soon as possible.
            </li>
          </ul>
          <h2 className="terms-headings">Additional Information:</h2>
          <ul>
            <li className="terms-para">
              All delivery and shipping times are estimates and may vary
              depending on external factors such as weather conditions,
              holidays, and carrier delays.
            </li>
            <li className="terms-para">
              We are not responsible for delays caused by factors beyond our
              control, but we will do our best to assist you in any way we can.
            </li>
          </ul>
          <p className="terms-para">
            If you have any further questions or concerns regarding our delivery
            and shipping policies, please feel free to contact our customer
            service team for assistance.
          </p>
          <p className="terms-para">Thank you for choosing Avakaayamart !</p>
        </div>
      </div>
      <div>
        <BottomNavbar/>
      </div>
    </>
  );
};

export default DeliveryandShippingPolicy;
