import React from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import {
  Arrow90degDown,
  ArrowLeft,
  ArrowRight,
  CaretLeft,
  CaretRight,
  Person,
  PersonCircle,
  StarFill,
} from "react-bootstrap-icons";

import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper/modules";
import "swiper/css";
import "../css/reviews.css";
const Reviews = () => {
  const companies = [
    {
      image: <PersonCircle />,
      rating: 5,
      review:
        "I recently purchased sambar masala, and I'm delighted with the results! The blend of spices is perfect, giving my sambar that authentic taste I love. ",
      name: "Shaifali Holani ",
      position: "Customer.",
    },
    {
      image: <PersonCircle />,
      rating: 5,

      review:
        "I recently bought chicken, and I'm thoroughly impressed! The quality is top-notch, and it's incredibly fresh. Whether I'm grilling, roasting, or making a hearty stew.",
      name: "Sarah Johnson",
      position: "Customer ",
    },
    {
      image: <PersonCircle />,
      rating: 4,

      review:
        "I recently purchased Mongo Pickle, and I must say, it's fantastic! The flavor is authentic and bold, with just the right amount of tanginess. It's perfect for adding an extra kick to my meals. ",
      name: "Sai Kumar",
      position: "Customer",
    },
    {
      image: <PersonCircle />,
      rating: 5,
      review:
        "It seems you've found a pickle you really like! Its flavor is bold and tangy, adding extra oomph to your meals. You've decided it's a must-have in your kitchen from now on.",
      name: "Shaifali Holani ",
      position: "Customer.",
    },
  
   
  ];
  return (
    <div>
    
      <div className="container-fluid p-0 mt-5 golbal-companies-container">
        <div className="container">
          <div className="reviews-main-container ">
            <div className="reviews-head-part mb-4">
              <div className="reviews-head-content ">
                <h1 className="reviews-heading">Customer reviews</h1>
              </div>
              <div className="reviews-head-buttons">
                <div className="swiper-button-prev">
                  <ArrowLeft
                    className="reviews-CaretLeft"
                    size={30}
                    // style={{ color: "black" }}
                  />
                </div>
                <div className="swiper-button-next">
                  <ArrowRight
                    className="reviews-CaretLeft"
                    size={30}
                    // style={{ color: "#ffffff" }}
                  />
                </div>
              </div>
            </div>

            <Swiper
              modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
              spaceBetween={50}
              navigation={{
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
              }}
              pagination={{
                dynamicBullets: true,
              }}
              breakpoints={{
                640: {
                  slidesPerView: 1,
                },
                768: {
                  slidesPerView: 1,
                },
                1024: {
                  slidesPerView: 3,
                },
              }}
            >
              {companies.map((data, index) => (
                <SwiperSlide key={index} className="global-company-card ">
                  <div
                    className="reviews-card "
                    style={{ width: "24rem" }}
                  >
                    <div className="reviews-card-body">
                      <p className="reviews-card-text sub-para">
                        {data.review}
                      </p>
                    </div>
                    <div className="reviews-icon-main">
                      <div className="d-flex gap-2">
                        <div>
                          <PersonCircle
                            className="Person-icon"
                            size={40}
                            style={{ color: "#F58131" }}
                          />
                        </div>

                        <div>
                          <p className="reviews-card-text">
                            <b>{data.name}</b>
                          </p>
                          <p className="reviews-card-text">
                            {data.position}
                          </p>
                        </div>
                        {
                          <p className=" ">
                            {Array.from({ length: data.rating }, (_, i) => (
                              <StarFill
                                className="reviews-ratings"
                                key={i}
                              />
                            ))}
                          </p>
                        }
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reviews;
