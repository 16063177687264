const Pickle = [
  {
    id: "avmp1",
    name: "Tomato Pickle",
    imageUrl: "https://i.ibb.co/gJfzRvG/Tomato.png",
    category: "veg",
    description:
      "Awaken your taste buds with Avakaaya Mart's homemade Tomato Pickle! ",
    additionalInfo: [
      {
        options: [
          { size: "250g", price: "60" },
          { size: "500g", price: "120" },
          { size: "1kg", price: "240" },
        ],
        // name: "Avakaaya Mart's Tangy Tomato Pickle",
        description:
          "Awaken your taste buds with Avakaaya Mart's homemade Tomato Pickle! Made using generations-old recipes from our grannies, this pickle is a burst of flavor that will transport you straight to your childhood kitchen.",
        features: [
          "Fresh, Sun-Ripened Tomatoes: We source only the juiciest, ripest tomatoes for a naturally sweet and tangy base.",
          "Secret Family Recipe: Decades of pickling experience go into every jar, ensuring a unique and balanced taste profile.",
          "Homemade with Love: Each batch is prepared with the same care and attention as if it were for our own families.",
          "Natural Ingredients: We use only the finest spices, herbs, and high-quality oils, with absolutely no artificial preservatives or flavors.",
        ],
      },
    ],
  },
  {
    id: "avmp2",
    name: "Mango Pickle",
    imageUrl: "https://i.ibb.co/r5r90Bk/Mango-pickle-image.png",
    category: "veg",
    description:
      "Indulge in the vibrant flavors of India with Avakaaya Mart's handcrafted Mango Pickle! ",
    additionalInfo: [
      {
        options: [
          { size: "250g", price: "75" },
          { size: "500g", price: "150" },
          { size: "1kg", price: "300" },
        ],
        // name: "Tangy Mango Temptation Pickle",
        description:
          "Indulge in the vibrant flavors of India with Avakaaya Mart's handcrafted Mango Pickle! Crafted using generations-old family recipes, this pickle captures the essence of summer with each juicy bite of perfectly pickled mango.",
        features: [
          "Authentic Recipe: Passed down through the ages, our secret blend of spices creates a unique harmony of sweet, tangy, and spicy flavors.",
          "Premium Mangoes: We source only the finest seasonal mangoes, ensuring a burst of fresh, fruity taste.",
          "Homemade with Care: Each batch is lovingly prepared in small quantities, reflecting the care and attention of home cooking.",
          "All-Natural Ingredients: We use only fresh mangoes, high-quality oils, and aromatic spices, with no artificial preservatives or flavors.",
          "A Burst of Versatility: Avakaaya Mart's Mango Pickle elevates any meal. ",
        ],
      },
    ],
  },
  {
    id: "avmp3",
    name: "Pandu mirchi Pickle",
    imageUrl: "https://i.ibb.co/1Q540m0/Pandu-mirchi-pickle.png",
    category: "veg",
    description:
      "Calling all heat seekers! Avakaaya Mart's Red Chilli Pickle, also known as Pandu Mirchi Pickle, is a fiery adventure for the bold foodie. ",
    additionalInfo: [
      {
        options: [
          { size: "250g", price: "75" },
          { size: "500g", price: "150" },
          { size: "1kg", price: "300" },
        ],
        // name: "A Dare for Your Taste Buds",
        description:
          "Calling all heat seekers! Avakaaya Mart's Red Chilli Pickle, also known as Pandu Mirchi Pickle, is a fiery adventure for the bold foodie. Made with fiery red chillies.",
        features: [
          "Potent Pandu Mirchis: We use only the freshest, most potent red chillies to deliver an authentic and scorching kick.",
          "Secret Family Recipe: Our time-tested recipe creates a complex flavor profile that goes beyond just heat, offering hints of tang and smokiness.",
          "Homemade with Fire: Each batch is handcrafted in small quantities, ensuring maximum flavor and fire in every bite.",
          "Pure and Natural: We use only red chillies, high-quality oils, and aromatic spices. There are absolutely no artificial preservatives or flavors, just pure fiery goodness.",
          "For the True Spice Enthusiast: Not for the faint of heart, this pickle is a true test of your spice tolerance",
        ],
      },
    ],
  },
  {
    id: "avmp4",
    name: "Usirikaya(amla) Pickle",

    imageUrl: "https://i.ibb.co/B3T2NBK/Usirikaya-amla-pickle.png",
    category: "veg",

    description:
      "Embrace the tart and tangy taste of health with Avakaaya Mart's Usirikaya (Amla) Pickle! ",
    additionalInfo: [
      {
        options: [
          { size: "250g", price: "60" },
          { size: "500g", price: "120" },
          { size: "1kg", price: "240" },
        ],
        // name: "A Powerhouse of Flavor and Wellness",
        description:
          "Embrace the tart and tangy taste of health with Avakaaya Mart's Usirikaya (Amla) Pickle! Made with the wonder fruit amla, also known as Indian gooseberry, this pickle is a delightful blend of tradition and well-being.",
        features: [
          "Natural Powerhouse: Amla is a rich source of Vitamin C and antioxidants, offering a delicious way to support your health.",
          "Tangy and Tart: The unique flavor profile of amla creates a refreshingly tart and tangy pickle experience.",
          "Secret Family Recipe: Generations of pickling wisdom go into crafting this unique and flavorful condiment.",
          "Homemade with Care: Each batch is prepared in small quantities, ensuring the highest quality and freshness.",
          "All-Natural Goodness: We use only fresh amla, high-quality oils, and aromatic spices, with no artificial preservatives or flavors.",
        ],
      },
    ],
  },
  {
    id: "avmp5",
    name: "Gongura Pickle",
    imageUrl: "https://i.ibb.co/NL7xcRj/Gongura-pickle.png",
    category: "veg",

    description:
      "Pucker up for a flavor fiesta with Avakaaya Mart's Zesty Gongura Pickle! ",
    additionalInfo: [
      {
        options: [
          { size: "250g", price: "60" },
          { size: "500g", price: "120" },
          { size: "1kg", price: "240" },
        ],
        // name: "A Burst of Sour Delight",
        description:
          "Pucker up for a flavor fiesta with Avakaaya Mart's Zesty Gongura Pickle! Made with the vibrant sorrel leaves (gongura), this pickle is a unique and tangy experience that will tantalize your taste buds.",
        features: [
          "Tangy Sensation: Gongura leaves deliver a burst of sourness, perfectly balanced by our secret blend of spices for a truly irresistible flavor.",
          "Generations-Old Recipe: We use a time-tested family recipe that captures the essence of Andhra Pradesh pickling traditions.",
          "Homemade with Love: Each batch is prepared in small quantities, ensuring the freshest ingredients and the utmost care goes into every jar.",
          "All-Natural Ingredients: We use only the finest gongura leaves, high-quality oils, and aromatic spices. There are absolutely no artificial preservatives or flavors.",
          "A Versatile Condiment: Avakaaya Mart's Gongura Pickle adds a delightful zing to any meal.",
        ],
      },
    ],
  },
  {
    id: "avmp6",
    name: "Chintakaya (tamarind) Pickle",

    imageUrl: "https://i.ibb.co/7r8cDJs/Chintakaya-tamarind-pickle-Copy.png",
    category: "veg",

    description:
      "Indulge in a delightful dance of sweet and sour with Avakaaya Mart's Tangy Chintakaya (Tamarind) Pickle! ",
    additionalInfo: [
      {
        options: [
          { size: "250g", price: "60" },
          { size: "500g", price: "120" },
          { size: "1kg", price: "240" },
        ],
        // name: "A Sweet & Sour Sensation",
        description:
          "Indulge in a delightful dance of sweet and sour with Avakaaya Mart's Tangy Chintakaya (Tamarind) Pickle! Crafted using generations-old pickling wisdom and the tangy goodness of tamarind, this pickle is a unique and flavorful addition to your culinary adventures.",
        features: [
          "Sweet & Sour Symphony: Our secret family recipe creates a perfect harmony between the tartness of tamarind and the subtle sweetness of natural ingredients.",
          "Premium Tamarind: We source only the finest tamarind pods, ensuring a burst of authentic flavor in every bite.",
          "Homemade with Care: Each batch is lovingly prepared in small quantities, reflecting the dedication and quality of home cooking.",
          "All-Natural Goodness: We use only fresh tamarind, high-quality oils, and aromatic spices. There are absolutely no artificial preservatives or flavors, just pure deliciousness.",
          "A Versatile Condiment: Avakaaya Mart's Chintakaya Pickle elevates any meal.",
        ],
      },
    ],
  },
  {
    id: "avmp7",
    name: "Lemon Pickle",

    imageUrl: "https://i.ibb.co/L61W5YC/Lemon-pickle.png",
    category: "veg",

    description:
      "Brighten your taste buds with the vibrant citrus punch of Avakaaya Mart's Luscious Lemon Pickle! ",
    additionalInfo: [
      {
        options: [
          { size: "250g", price: "60" },
          { size: "500g", price: "120" },
          { size: "1kg", price: "240" },
        ],
        // name: "A Zesty Kiss for Your Meals",
        description:
          "Brighten your taste buds with the vibrant citrus punch of Avakaaya Mart's Luscious Lemon Pickle! Crafted with generations-old family recipes and sunshine-ripened lemons, this pickle is a zesty explosion of flavor that will add a touch of magic to every meal.",
        features: [
          "Tangy and Zesty: Our secret blend of spices awakens your senses with a delightful balance of tangy lemon and aromatic warmth. ",
          "Sun-Kissed Goodness: We use only the juiciest, ripest lemons, ensuring a burst of fresh, citrusy flavor in every bite.",
          "Homemade with Love: Each batch is lovingly prepared in small quantities, reflecting the care and attention of home cooking.",
          "All-Natural Ingredients: We use only fresh lemons, high-quality oils, and aromatic spices. There are absolutely no artificial preservatives or flavors, just pure citrusy goodness.",
          "A Versatile Condiment: Avakaaya Mart's Lemon Pickle adds a refreshing zing to any meal.",
        ],
      },
    ],
  },
  {
    id: "avmp8",
    name: "Allam(ginger) Pickle",

    imageUrl: "https://i.ibb.co/f2BdhG5/Allam-ginger-pickle-Copy.png",
    category: "veg",

    description:
      "Awaken your taste buds to a fiery dance of flavor with Avakaaya Mart's Zingy Allam (Ginger) Pickle! ",
    additionalInfo: [
      {
        options: [
          { size: "250g", price: "60" },
          { size: "500g", price: "120" },
          { size: "1kg", price: "240" },
        ],
        // name: "A Spicy Symphony for Your Senses",
        description:
          "Awaken your taste buds to a fiery dance of flavor with Avakaaya Mart's Zingy Allam (Ginger) Pickle! Made with generations-old pickling wisdom and the robust kick of fresh ginger, this pickle is a true celebration of spice and tradition.",
        features: [
          "Spicy Symphony: Our secret family recipe creates a delightful harmony between the fiery warmth of ginger and a symphony of aromatic spices.",
          "Premium Ginger: We source only the freshest, most pungent ginger root, ensuring a powerful and authentic ginger experience in every bite.",
          "Homemade with Care: Each batch is lovingly prepared in small quantities, reflecting the dedication and quality of home cooking.",
          "All-Natural Goodness: We use only fresh ginger, high-quality oils, and aromatic spices. There are absolutely no artificial preservatives or flavors, just pure fiery goodness.",
          "A Flavorful Adventure: Avakaaya Mart's Allam Pickle is a versatile condiment for the adventurous eater.",
        ],
      },
    ],
  },
  {
    id: "avmp9",
    name: "Mulakkada (drumstick) Pickle",

    imageUrl: "https://i.ibb.co/7xDkYcV/Mulakkada-drumstick-pickle.png",
    category: "veg",

    description:
      "Experience the unique taste and texture of Avakaaya Mart's Crunchy Drumstick (Mulakkada) Pickle! ",
    additionalInfo: [
      {
        options: [
          { size: "250g", price: "60" },
          { size: "500g", price: "120" },
          { size: "1kg", price: "240" },
        ],
        // name: "A Delightfully Tangy Treat",
        description:
          "Experience the unique taste and texture of Avakaaya Mart's Crunchy Drumstick (Mulakkada) Pickle! Made with young, tender drumsticks (mulakkada) and generations-old pickling wisdom, this pickle offers a delightful crunch and a burst of tangy flavor that will tantalize your taste buds.",
        features: [
          "Unique Texture: Tender drumsticks are pickled to perfection, offering a satisfying crunch in every bite.",
          "Tangy and Flavorful: Our secret family recipe creates a delightful balance between the tang of pickling and a symphony of aromatic spices.",
          "Generations-Old Tradition: We use time-tested pickling methods passed down through generations, ensuring an authentic and flavorful experience.",
          "Homemade with Care: Each batch is lovingly prepared in small quantities, reflecting the care and attention of home cooking.",
          "All-Natural Goodness: We use only fresh drumsticks, high-quality oils, and aromatic spices. There are absolutely no artificial preservatives or flavors, just pure deliciousness.",
          "A Versatile Condiment: Avakaaya Mart's Drumstick Pickle adds a unique flavor and texture to any meal.",
        ],
      },
    ],
  },
  {
    id: "avmp10",
    name: "Dabbakaya (citron) Pickle",

    imageUrl: "https://i.ibb.co/FDz9sDL/Dabbakaya-citron-pickle.png",
    category: "veg",

    description:
      "Pucker up for a flavor adventure with Avakaaya Mart's Zesty Dabbakaya (Citron) Pickle! ",
    additionalInfo: [
      {
        options: [
          { size: "250g", price: "60" },
          { size: "500g", price: "120" },
          { size: "1kg", price: "240" },
        ],
        // name: "A Burst of Citrus Sunshine",
        description:
          "Pucker up for a flavor adventure with Avakaaya Mart's Zesty Dabbakaya (Citron) Pickle! Made with the unique citrus fruit dabbakaya, also known as citron, this pickle offers a vibrant explosion of tangy flavor with a hint of sweetness, transporting your taste buds to a world of sunshine and tradition.",
        features: [
          "Tangy & Sweet Symphony: Our secret family recipe creates a delightful harmony between the vibrant tartness of dabbakaya and a touch of natural sweetness for a truly unique flavor profile.",
          "Rare Citrus Delight: Dabbakaya offers a flavor experience unlike any other, adding an exciting twist to your culinary journey.",
          "Generations-Old Tradition: We use time-tested pickling methods passed down through generations, ensuring an authentic and flavorful experience.",
          "Homemade with Care: Each batch is lovingly prepared in small quantities, reflecting the care and attention of home cooking.",
          "All-Natural Goodness: We use only fresh dabbakaya, high-quality oils, and aromatic spices. There are absolutely no artificial preservatives or flavors, just pure citrusy sunshine in every bite.",
          "A Versatile Condiment: Avakaaya Mart's Dabbakaya Pickle adds a delightful zing to any meal. ",
        ],
      },
    ],
  },
  {
    id: "avmp11",
    name: "Cauliflower Pickle",

    imageUrl: "https://i.ibb.co/DwTC74F/Cauliflower-pickle.png",
    category: "veg",

    description:
      "Add a delightful crunch and a burst of tangy flavor to your meals with Avakaaya Mart's Cauliflower Pickle! ",
    additionalInfo: [
      {
        options: [
          { size: "250g", price: "60" },
          { size: "500g", price: "120" },
          { size: "1kg", price: "240" },
        ],
        // name: "A Tangy & Crunchy Treat",
        description:
          "Add a delightful crunch and a burst of tangy flavor to your meals with Avakaaya Mart's Cauliflower Pickle! Made with fresh cauliflower florets and generations-old pickling wisdom, this pickle offers a unique textural experience and a flavor explosion that will tantalize your taste buds.",
        features: [
          "Crunchy & Flavorful: Tender cauliflower florets are pickled to perfection, offering a satisfying crunch and a burst of tangy goodness in every bite.",
          "Secret Family Recipe: Our time-tested recipe creates a delightful balance between the tang of pickling and a symphony of aromatic spices.",
          "Generations-Old Tradition: We use methods passed down through generations, ensuring an authentic and flavorful experience.",
          "Homemade with Care: Each batch is lovingly prepared in small quantities, reflecting the dedication and quality of home cooking.",
          "All-Natural Goodness: We use only fresh cauliflower, high-quality oils, and aromatic spices. There are absolutely no artificial preservatives or flavors, just pure deliciousness.",
          "A Versatile Condiment: Avakaaya Mart's Cauliflower Pickle adds a unique flavor and texture to any meal.",
        ],
      },
    ],
  },
  {
    id: "avmp12",
    name: "Chikkudukaya(Broad Beans) Pickle",

    imageUrl: "https://i.ibb.co/fGkQVYS/Chikkudukaya-Broad-Beans-pickle-Copy.png",
    category: "veg",

    description:
      "Embark on a culinary adventure with Avakaaya Mart's Savory Chikkudukaya (Broad Bean) Pickle! ",
    additionalInfo: [
      {
        options: [
          { size: "250g", price: "60" },
          { size: "500g", price: "120" },
          { size: "1kg", price: "240" },
        ],
        // name: "A Unique Textural Adventure",
        description:
          "Embark on a culinary adventure with Avakaaya Mart's Savory Chikkudukaya (Broad Bean) Pickle! Made with tender broad beans (chikkudukaya) and generations-old pickling wisdom, this pickle offers a unique textural experience and a burst of savory flavor that will surprise and delight your taste buds.",
        features: [
          "Unique Textural Delight: Tender broad beans are pickled to perfection, offering a satisfying pop and a creamy texture in every bite.",
          "Savory & Flavorful: Our secret family recipe creates a delightful harmony between the savory notes of the beans and a symphony of aromatic spices.",
          "A Culinary Adventure: Broad bean pickle offers a flavor unlike any other, adding an exciting twist to your vegetarian and vegan meals.",
          "Generations-Old Tradition: We use time-tested pickling methods passed down through generations, ensuring an authentic and flavorful experience.",
          "Homemade with Care: Each batch is lovingly prepared in small quantities, reflecting the care and attention of home cooking.",
          "All-Natural Goodness: We use only fresh chikkudukaya, high-quality oils, and aromatic spices. There are absolutely no artificial preservatives or flavors, just pure deliciousness in every jar.",
          "A Versatile Condiment: Avakaaya Mart's Chikkudukaya Pickle adds a delightful savory dimension to any meal.",
        ],
      },
    ],
  },
  {
    id: "avmp13",
    name: "Vankaya(brinjal) Pickle",

    imageUrl: "https://i.ibb.co/wNmxqwN/Vankaya-brinjal-pickle.png",
    category: "veg",

    description:
      "Light up your taste buds with the fiery charm of Avakaaya Mart's Spicy Vankaya (Brinjal) Pickle! ",
    additionalInfo: [
      {
        options: [
          { size: "250g", price: "60" },
          { size: "500g", price: "120" },
          { size: "1kg", price: "240" },
        ],
        // name: "A Delightfully Fiery Adventure",
        description:
          "Light up your taste buds with the fiery charm of Avakaaya Mart's Spicy Vankaya (Brinjal) Pickle! Made with plump brinjals (eggplant) and generations-old pickling wisdom, this pickle offers a delightful dance of smoky spice and tangy flavor, perfect for the adventurous eater.",
        features: [
          "Spicy & Tangy Symphony: Our secret family recipe creates a delightful harmony between the smoky heat of chili peppers, the tang of pickling, and a hint of sweetness for a complex flavor profile.",
          "Plump & Flavorful Brinjals: We use only the freshest, ripest brinjals, ensuring a satisfying bite and a burst of flavor in every mouthful.",
          "Generations-Old Tradition: We use time-tested pickling methods passed down through generations, ensuring an authentic and flavorful experience.",
          "Homemade with Care: Each batch is lovingly prepared in small quantities, reflecting the care and attention of home cooking.",
          "All-Natural Goodness: We use only fresh brinjals, high-quality oils, aromatic spices, and chili peppers. There are absolutely no artificial preservatives or flavors, just pure fiery goodness.",
          "A Versatile Condiment for the Bold: Avakaaya Mart's Vankaya Pickle is a fiery delight for the adventurous eater.",
        ],
      },
    ],
  },
  {
    id: "avmp14",
    name: "Carrot Pickle",

    imageUrl: "https://i.ibb.co/DCLhqWQ/Carrot-pickle.png",
    category: "veg",

    description:
      "Rediscover the magic of a beloved condiment with Avakaaya Mart's Crunchy Carrot Pickle! ",
    additionalInfo: [
      {
        options: [
          { size: "250g", price: "60" },
          { size: "500g", price: "120" },
          { size: "1kg", price: "240" },
        ],
        // name: " A Classic with a Twist",
        description:
          "Rediscover the magic of a beloved condiment with Avakaaya Mart's Crunchy Carrot Pickle! Made with vibrant orange carrots and generations-old pickling wisdom, this pickle offers a delightful crunch, a touch of sweetness, and a tangy kick, making it a perfect addition to any meal.",
        features: [
          "Crunchy & Refreshing: We use julienned carrots pickled to perfection, offering a satisfying crunch and a burst of refreshing flavor in every bite.",
          "Sweet & Tangy Symphony: Our secret family recipe creates a delightful harmony between the natural sweetness of carrots, the tang of pickling, and a hint of aromatic spices for a well-rounded taste.",
          "A Timeless Classic: Carrot pickle is a beloved condiment enjoyed for generations, and Avakaaya Mart's version adds a touch of homemade magic to this classic dish.",
          "Generations-Old Tradition: We use time-tested pickling methods passed down through generations, ensuring an authentic and flavorful experience.",
          "Homemade with Care: Each batch is lovingly prepared in small quantities, reflecting the dedication and quality of home cooking.",
          "All-Natural Goodness: We use only fresh carrots, high-quality oils, aromatic spices, and a touch of natural sweetness. There are absolutely no artificial preservatives or flavors, just pure deliciousness.",
          "A Versatile Condiment: Avakaaya Mart's Carrot Pickle adds a delightful crunch and a burst of flavor to any meal.",
        ],
      },
    ],
  },
  {
    id: "avmp15",
    name: "Pulihora gongura Pickle",

    imageUrl: "https://i.ibb.co/ZM0LRJH/Pulihora-gongura-pickle.png",
    category: "veg",
    description:
      "Embrace the vibrant flavors of Andhra Pradesh with Avakaaya Mart's Tangy Pulihora Gongura Pickle! ",
    additionalInfo: [
      {
        options: [
          { size: "250g", price: "60" },
          { size: "500g", price: "120" },
          { size: "1kg", price: "240" },
        ],
        // name: "A Taste of Andhra Tradition",
        description:
          "Embrace the vibrant flavors of Andhra Pradesh with Avakaaya Mart's Tangy Pulihora Gongura Pickle! Made with fresh gongura leaves (sorrel) and tangy tamarind, this pickle captures the essence of pulihora, a beloved Andhra rice dish. Experience a delightful burst of tangy and spicy flavors that will tantalize your taste buds.",
        features: [
          "Authentic Andhra Delight: Crafted using traditional Andhra recipes, this pickle offers a taste of culinary heritage in every bite.",
          "Tangy & Spicy Symphony: Our secret family recipe creates a delightful harmony between the tanginess of tamarind and the unique sourness of gongura leaves, balanced with a touch of aromatic spices.",
          "Pulihora in a Pickle: This pickle captures the essence of pulihora, a beloved Andhra rice dish, offering a convenient and flavorful way to experience its tangy magic.",
          "Generations-Old Tradition: We use time-tested pickling methods passed down through generations, ensuring an authentic and flavorful experience.",
          "Homemade with Care: Each batch is lovingly prepared in small quantities, reflecting the dedication and quality of home cooking.",
          "All-Natural Goodness: We use only fresh gongura leaves, tamarind extract, high-quality oils, and aromatic spices. There are absolutely no artificial preservatives or flavors, just pure Andhra magic in every jar.",
          "A Versatile Condiment: Avakaaya Mart's Pulihora Gongura Pickle adds a burst of flavor and a unique tang to any meal.",
        ],
      },
    ],
  },

  {
    id: "avmp17",
    name: "Velluli Pickle",

    imageUrl: "https://i.ibb.co/h81t6Xt/Velluli-garlic-pickle.png",
    category: "veg",

    description:
      "Calling all heat seekers! Avakaaya Mart's Velluli (Garlic) Pickle is a fiery testament to Andhra pickling traditions",
    additionalInfo: [
      {
        options: [
          { size: "250g", price: "100" },
          { size: "500g", price: "200" },
          { size: "1kg", price: "400" },
        ],
        // name: "A Punch of Tradition in Every Bite",
        description:
          "Calling all heat seekers! Avakaaya Mart's Velluli (Garlic) Pickle is a fiery testament to Andhra pickling traditions. Made with only the freshest, most potent garlic cloves and generations-old family recipes, this pickle is a flavor explosion waiting to happen.",
        features: [
          "Potent Garlic Punch: We use only the spiciest, most flavorful garlic, delivering an authentic and scorching kick that will tantalize your taste buds.",
          "Secret Family Recipe: Our time-tested recipe goes beyond just heat, offering a complex flavor profile with hints of tang and smokiness.",
          "Homemade with Fire: Each batch is handcrafted in small quantities, ensuring the maximum flavor and fire in every single bite.",
          "All-Natural Goodness: We use only garlic, high-quality oils, and aromatic spices. There are absolutely no artificial preservatives or flavors, just pure fiery deliciousness.",
          "A Kick for Every Dish: Not for the faint of heart, this pickle is a true test of your spice tolerance.",
        ],
      },
    ],
  },

  {
    id: "avmp19",
    name: "Chicken bone pickle",

    imageUrl: "https://i.ibb.co/8dqDbmY/Chicken-bone-pickle.png",
    category: "non",

    description:
      "Avakaaya Mart's Chicken Bone Pickle offers a unique and flavorful experience ",
    additionalInfo: [
      {
        options: [
          { size: "500g", price: "450" },
          { size: "1kg", price: "900" },
        ],
        name: "",
        description:
          "Avakaaya Mart's Chicken Bone Pickle offers a unique and flavorful experience for those who enjoy a bit of heat and a twist on traditional pickles.",
        features: [
          "Savory and Spicy: This pickle features chicken pieces (including bones) marinated in a blend of zesty spices and tangy ingredients. The result is a savory and spicy concoction that will tantalize your taste buds.",
          "Flavorful Infusion: Avakaaya Mart likely uses a traditional pickling method where the chicken bones are marinated for an extended period. This allows the spices and tangy flavors to deeply penetrate the chicken, infusing it with a rich and complex taste.",
          "Tender Meat: Despite the presence of bones, the pickling process likely tenderizes the chicken pieces, making them enjoyable to eat.",
          "Spicy Kick: Avakaaya Mart might offer different spice levels for their Chicken Bone Pickle. You can choose a milder version for a gentler heat or a spicy option for those who crave a fiery kick.",
        ],
      },
    ],
  },
  {
    id: "avmp20",
    name: "Chicken (boneless) pickle",

    imageUrl: "https://i.ibb.co/Fg7xfnX/chicken-bonless1.png",
    category: "non",

    description:
      "Avakaaya Mart's Boneless Chicken Pickle offers a delicious and convenient way to enjoy the classic South Indian pickle experience. ",
    additionalInfo: [
      {
        options: [
          { size: "500g", price: "650" },
          { size: "1kg", price: "1300" },
        ],
        name: "",
        description:
          "Avakaaya Mart's Boneless Chicken Pickle offers a delicious and convenient way to enjoy the classic South Indian pickle experience.",
        features: [
          "Succulent Chicken: This pickle features boneless chicken pieces marinated in a flavorful blend of spices and tangy ingredients. The result is a succulent and tender chicken pickle that is both satisfying and flavorful.",
          "Spicy and Tangy: Avakaaya Mart's Boneless Chicken Pickle is likely a delightful balance of spice and tang. The spices add a pleasant heat, while the tangy notes, often from ingredients like vinegar or lemon juice, provide a refreshing counterpoint.",
          "Flavorful Infusion: Similar to their Chicken Bone Pickle, Avakaaya Mart likely uses a traditional pickling method. The boneless chicken pieces are marinated for an extended period, allowing the spices and tangy flavors to infuse the chicken deeply, resulting in a complex and delicious taste.",
          "Convenient Enjoyment: Without bones to worry about, this pickle offers a convenient way to enjoy the classic chicken pickle flavor. It's perfect for a quick and flavorful snack or as an accompaniment to various meals.",
        ],
      },
    ],
  },
  {
    id: "avmp21",
    name: "Gongura Chicken Boneless",
    imageUrl: "https://i.ibb.co/HHQr30D/Gongura-Chicken-Boneless-pickle.png",
    category: "non",
    description:
      "Avakaaya Mart's Gongura Chicken Boneless pickle offers a delicious and convenient way to enjoy the classic South Indian pickle experience with a delightful twist.",
    additionalInfo: [
      {
        options: [
          { size: "500g", price: "750" },
          { size: "1kg", price: "1500" },
        ],
        name: "",
        description:
          "Avakaaya Mart's Gongura Chicken Boneless pickle offers a delicious and convenient way to enjoy the classic South Indian pickle experience with a delightful twist.",
        features: [
          "Succulent Chicken: Just like their Boneless Chicken Pickle, this version features tender and flavorful chicken pieces, but with a twist! The chicken is marinated in a special blend that incorporates gongura leaves.",
          "Tangy Twist with Gongura: Gongura leaves, also known as sorrel leaves, are a key ingredient in this pickle. They add a unique and delightful tanginess that sets this pickle apart. The tang balances the richness of the chicken and complements the spices beautifully.",
          "Spicy Kick: Avakaaya Mart likely uses a blend of spices to create a pleasant heat in this pickle. The spice level might vary depending on the option you choose, but it's sure to tantalize your taste buds.",
          "Convenient Enjoyment: Without bones to worry about, this pickle offers a convenient way to enjoy the classic chicken pickle flavor. It's perfect for a quick and flavorful snack or as an accompaniment to various meals.",
          "Flavorful Infusion: Similar to other pickles offered by Avakaaya Mart, this one likely uses a traditional pickling method. The boneless chicken pieces are marinated with gongura and spices for an extended period, allowing the flavors to infuse deeply, resulting in a complex and delicious taste.",
        ],
      },
    ],
  },

  {
    id: "avmp23",
    name: "Natukodi pickle",

    imageUrl: "https://i.ibb.co/ZKZvWK2/natukodi1.png",
    category: "non",

    description:
      "Avakaaya Mart's Natukodi pickle brings the essence of traditional South Indian flavors to your table, offering a savory experience.",
    additionalInfo: [
      {
        options: [
          { size: "500g", price: "800" },
          { size: "1kg", price: "1600" },
        ],
        name: "",
        description:
          "Avakaaya Mart's Natukodi pickle brings the essence of traditional South Indian flavors to your table, offering a savory experience.",
        features: [
          "Free-range chicken: Natu kodi pickle uses chicken raised naturally, often resulting in meatier pieces and a richer flavor compared to broilers.",
          "Authentic spices: The pickle is prepared with a unique blend of spices that complement the chicken and tantalize your taste buds. These spices may include red chili powder, turmeric, coriander seeds, cumin seeds, mustard seeds, fenugreek, and more.",
          "Traditional recipe: Natu kodi pickle is often prepared according to generations-old recipes, ensuring a taste that is both familiar and delightful.",
          "Available with or without bones: Depending on the brand, you can find natu kodi pickle with boneless chicken pieces for a smoother texture or with bones for a more intense flavor.",
        ],
      },
    ],
  },
  {
    id: "avmp24",
    name: "Prawns Pickle",

    imageUrl: "https://i.ibb.co/sC8FxR5/Prawns.png",
    category: "non",

    description:
      "A fiery and tangy condiment bursting with the flavor of succulent prawns, this pickle is a seafood lover's delight.",
    additionalInfo: [
      {
        options: [
          { size: "500g", price: "800" },
          { size: "1kg", price: "1600" },
        ],
        name: "",
        description:
          "A fiery and tangy condiment bursting with the flavor of succulent prawns, this pickle is a seafood lover's delight.",
        features: [
          "Fresh Prawns: Made with high-quality, [insert adjective, e.g., plump, juicy] prawns, this pickle delivers a satisfying textural experience alongside its punchy flavor.",
          "Spice Symphony: A carefully curated blend of chili peppers, along with aromatic spices like turmeric, coriander, and fenugreek, creates a symphony of heat and flavor that complements the prawns perfectly.",
          "Tangy Twist: A touch of [insert ingredient, e.g., lemon juice, vinegar] adds a delightful tanginess that cuts through the richness of the prawns and spices.",
          "Versatile Enjoyment: Pair this prawn pickle with rice dishes like biryani or steamed rice for a burst of coastal flair. It's also a delicious accompaniment to curries, adding a spicy and savory dimension",
        ],
      },
    ],
  },
  {
    id: "avmp26",
    name: "Gongura Prawns Pickle",

    imageUrl: "https://i.ibb.co/wSQggvg/Gongura-Prawns-Pickle.png",
    category: "non",

    description:
      "A tangy and fiery taste sensation, this Gongura Prawns Pickle combines the vibrant flavors of Andhra Pradesh.",
    additionalInfo: [
      {
        options: [
          { size: "500g", price: "1000" },
          { size: "1kg", price: "2000" },
        ],
        name: "",
        description:
          "A tangy and fiery taste sensation, this Gongura Prawns Pickle combines the vibrant flavors of Andhra Pradesh.",
        features: [
          "Succulent Seafood: Made with plump prawns, this pickle offers a delightful textural contrast to the tangy and spicy notes of the gongura leaves.",
          "Gongura Magic: Sour and vibrant, gongura leaves lend a unique tanginess that cuts through the richness of the prawns and adds a delightful complexity to the flavor profile.",
          "Spicy Symphony: Aromatic spices like turmeric, coriander, and mustard seeds, along with the natural heat of chili peppers, create a symphony of flavor that complements the prawns and gongura perfectly.",
          "Coastal Craving: Pair this Gongura Prawns Pickle with rice dishes like steamed rice or biryani for a taste of the Andhra coast. It's also a delicious accompaniment to curries for a burst of tangy heat.",
        ],
      },
    ],
  },
  {
    id: "avmp27",
    name: "Mutton Boneless Pikcle",

    imageUrl: "https://i.ibb.co/YjXqPYK/Button-boneless.jpg",
    category: "non",

    description:
      "Aromatic, flavorful, and conveniently boneless, this Mutton Boneless Pickle is a protein-packed condiment for every occasion.",
    additionalInfo: [
      {
        options: [
          { size: "500g", price: "900" },
          { size: "1kg", price: "1800" },
        ],
        name: "",
        description:
          "Aromatic, flavorful, and conveniently boneless, this Mutton Boneless Pickle is a protein-packed condiment for every occasion.",
        features: [
          "Tender Mutton: Made with boneless mutton pieces that are slow-cooked to perfection, this pickle offers a delightful and melt-in-your-mouth meaty experience.",
          "Spice Journey: Aromatic spices like cloves, cardamom, and cumin create a complex flavor profile that tantalizes the senses, perfectly complementing the richness of the mutton.",
          "Tangy Touch: A hint of [insert ingredient, e.g., lemon juice, vinegar] adds a touch of tang that cuts through the richness and balances the flavors beautifully.",
          "Versatile Enjoyment: Enjoy Mutton Boneless Pickle with rotis, parathas, or even steamed rice for a satisfying and flavorful meal. It also adds a delicious depth of taste to dals and curries.",
        ],
      },
    ],
  },
  {
    id: "avmp28",
    name: "Gongura Mutton Pickle",

    imageUrl: "https://i.ibb.co/Kq7z6KS/Gongura-Mutton-Pickle.png",
    category: "non",

    description:
      "A fiery and tangy Andhra delight, Gongura Mutton Pickle is an explosion of flavor for the adventurous palate.",
    additionalInfo: [
      {
        options: [
          { size: "500g", price: "1000" },
          { size: "1kg", price: "2000" },
        ],
        name: "",
        description:
          "A fiery and tangy Andhra delight, Gongura Mutton Pickle is an explosion of flavor for the adventurous palate.",
        features: [
          "Succulent Mutton: Tender mutton pieces are slow-cooked to perfection, offering a satisfying and melt-in-your-mouth meaty experience.",
          "Gongura Magic: Sour and vibrant, gongura leaves lend a unique tanginess that cuts through the richness of the mutton and adds a delightful complexity to the flavor profile.",
          "Spicy Symphony: Aromatic spices like turmeric, coriander, and mustard seeds, along with the natural heat of chili peppers, create a symphony of flavor that complements the mutton and gongura perfectly.",
          "Andhra Affair: This pickle is a true representation of Andhra cuisine, known for its bold and fiery flavors. Pair it with rice dishes like biryani or steamed rice for an authentic Andhra experience. It's also a delicious accompaniment to curries for a burst of tangy heat.",
        ],
      },
    ],
  },

  {
    id: "avmp30",
    name: "Korameenu Fish Pickle",

    imageUrl: "https://i.ibb.co/rvjmBBs/Korameenu-Fish-Pickle.png",
    category: "non",

    description:
      "A spicy and aromatic Andhra classic, Korameenu Fish Pickle is a must-have for seafood lovers.",
    additionalInfo: [
      {
        options: [
          { size: "500g", price: "750" },
          { size: "1kg", price: "1500" },
        ],
        name: "",
        description:
          "A spicy and aromatic Andhra classic, Korameenu Fish Pickle is a must-have for seafood lovers.",
        features: [
          "Flavorful Fish: Made with the Indian Snakehead fish (Korameenu), known for its firm texture and rich flavor, this pickle delivers a delightful bite in every mouthful.",
          "Spice Symphony: A carefully curated blend of aromatic spices like turmeric, coriander, fenugreek, and chilies creates a symphony of flavor that perfectly complements the fish.",
          "Oil-infused Goodness: Marinated in oil, the fish absorbs the flavors of the spices and becomes infused with a richness that elevates the taste experience.",
          "Andhra Affair: This pickle is a true representation of Andhra cuisine, known for its bold and fiery flavors. Pair it with rice dishes like biryani or steamed rice for an authentic Andhra experience. It's also a delicious accompaniment to curries for a burst of spicy goodness.",
        ],
      },
    ],
  },

  {
    id: "avmp32",
    name: "Nethallu pickle",

    imageUrl: "https://i.ibb.co/NmwqpCf/nethalu2.png",
    category: "non",

    description:
      "A tangy and health-conscious delight, Nelli Pickle, also known as Gooseberry Pickle, offers a unique flavor and a powerhouse of nutrients.",
    additionalInfo: [
      {
        options: [
          { size: "500g", price: "600" },
          { size: "1kg", price: "1200" },
        ],
        name: "",
        description:
          "A tangy and health-conscious delight, Nelli Pickle, also known as Gooseberry Pickle, offers a unique flavor and a powerhouse of nutrients.",
        features: [
          "Tart and Tangy: Made with unripe gooseberries (nelli), this pickle offers a delightful sourness that is balanced by the other ingredients. Nelli is known for its health benefits, including being rich in Vitamin C and antioxidants.",
          "Spice Symphony: A blend of aromatic spices like mustard seeds, fenugreek seeds, and chilies creates a symphony of flavor that complements the tartness of the nelli perfectly.",
          "Oil-infused Goodness: Marinated in oil, the nelli absorbs the flavors of the spices and becomes infused with a richness that elevates the taste experience.",
          "Digestive Aid: Nellikkai is known to be a digestive aid, making this pickle a flavorful and functional addition to your meal. Enjoy it with rice dishes like steamed rice or khichdi for a tangy and healthy accompaniment. It's also a delicious addition to dals for a unique flavor profile.",
        ],
      },
    ],
  },

  {
    id: "avmp35",
    name: "Chicken Masala",

    imageUrl: "https://i.ibb.co/z6JXP0T/Chicken-Masala.png",
    category: "masala",

    description:
      "Aromatic and flavorful, Chicken Masala is a versatile curry blend perfect for creating restaurant-style curries at home.",
    additionalInfo: [
      {
        options: [
          { size: "100g", price: "85" },
          { size: "250g", price: "215" },
          { size: "500g", price: "425" },
          { size: "1kg", price: "850" },
        ],
        name: "",
        description:
          "Aromatic and flavorful, Chicken Masala is a versatile curry blend perfect for creating restaurant-style curries at home.",
        features: [
          "Warm Spices: A blend of aromatic spices like coriander, cumin, turmeric, and cloves creates a warm and inviting flavor profile that complements chicken beautifully.",
          "Touch of Heat: A hint of chili powder adds a touch of warmth, adjustable to your preference for a customized level of spice.",
          "Easy Weeknight Meal: This blend makes creating a delicious and flavorful chicken curry simple and convenient. Just add it to chicken, tomatoes, and coconut milk (or yogurt) for a quick and satisfying meal.",
          "Versatile Use: Chicken Masala can also be used to marinate chicken for kebabs or grilled dishes, adding depth and flavor.",
        ],
      },
    ],
  },
  {
    id: "avmp36",
    name: "Mutton masala",

    imageUrl: "https://i.ibb.co/wsh92ky/Mutton-masala.png",
    category: "masala",

    description:
      "Rich and Robust, Mutton Masala is a blend of spices specifically designed to enhance the flavor of mutton curries.",
    additionalInfo: [
      {
        options: [
          { size: "100g", price: "85" },
          { size: "250g", price: "215" },
          { size: "500g", price: "425" },
          { size: "1kg", price: "850" },
        ],
        name: "",
        description:
          "Rich and Robust, Mutton Masala is a blend of spices specifically designed to enhance the flavor of mutton curries.",
        features: [
          "Bold Spices: This blend features spices like black cardamom, cloves, and cinnamon, creating a bold and robust flavor profile that perfectly complements the richness of mutton.",
          "Warm Aromatics: Aromatic spices like coriander and cumin add warmth and depth, while a touch of turmeric provides a beautiful golden hue.",
          "Slow-cooked Perfection: Mutton Masala is ideal for slow-cooked curries, allowing the spices to deeply infuse the meat and create a melt-in-your-mouth experience.",
          "Flavorful Variations: This blend can be adapted to create a variety of regional Indian curries. Experiment with adding yogurt for a milder and creamier taste or a touch of fenugreek for a more robust flavor.",
        ],
      },
    ],
  },
  {
    id: "avmp37",
    name: "Fish masala",
    imageUrl: "https://i.ibb.co/K503hR8/Fish-masala.png",
    category: "masala",
    description:
      "Light and Fragrant, Fish Masala is a delicate blend of spices specifically crafted for seafood dishes.",
    additionalInfo: [
      {
        options: [
          { size: "100g", price: "85" },
          { size: "250g", price: "215" },
          { size: "500g", price: "425" },
          { size: "1kg", price: "850" },
        ],
        name: "",
        description:
          "Light and Fragrant, Fish Masala is a delicate blend of spices specifically crafted for seafood dishes.",
        features: [
          "Subtle Spices: Featuring spices like fennel seeds, fenugreek, and turmeric, this blend adds a touch of warmth and aroma without overpowering the delicate flavor of fish.",
          "Coastal Inspiration: Inspired by South Indian coastal cuisine, this blend allows the natural sweetness of seafood to shine through while adding a touch of complexity.",
          "Quick and Easy: Fish Masala is perfect for creating flavorful and healthy fish curries in a short amount of time. Simply add it to fish, coconut milk (or yogurt), and vegetables for a delicious and nutritious meal.",
          "Versatile Use: This blend can also be used to marinate fish for grilling or baking, infusing it with a layer of flavor..",
        ],
      },
    ],
  },
  {
    id: "avmp38",
    name: "Garam Masala",

    imageUrl: "https://i.ibb.co/nnXqKV6/Fried-Rice-Masala.png",
    category: "masala",

    description:
      "The Finishing Touch: Garam Masala is a warming blend of whole spices used to add depth and aroma to curries, stews, and dals at the end of cooking.",
    additionalInfo: [
      {
        options: [
          { size: "100g", price: "85" },
          { size: "250g", price: "215" },
          { size: "500g", price: "425" },
          { size: "1kg", price: "850" },
        ],
        name: "",
        description:
          "The Finishing Touch: Garam Masala is a warming blend of whole spices used to add depth and aroma to curries, stews, and dals at the end of cooking.",
        features: [
          "Aromatic Symphony: This blend features spices like black cardamom, cloves, cinnamon, and peppercorns, creating a symphony of warmth and aroma that elevates any dish.",
          "Blooming Technique: For the best flavor, Garam Masala is typically 'bloomed'  in hot oil or ghee before adding other ingredients. This releases the essential oils of the spices, intensifying their fragrance.",
          "Versatile Use: Beyond curries, Garam Masala can be used to add a warm and complex touch to roasted vegetables, lentil soups, or even sprinkled on popcorn for a unique flavor twist.",
        ],
      },
    ],
  },
  {
    id: "avmp39",
    name: "Biryani Masala",

    imageUrl: "https://i.ibb.co/ZM4xXV7/Biryani-Masala.png",
    category: "masala",

    description:
      "Aromatic and layered, Biryani Masala is a symphony of spices specifically designed to create flavorful and fragrant Biryanis.",
    additionalInfo: [
      {
        options: [
          { size: "100g", price: "85" },
          { size: "250g", price: "215" },
          { size: "500g", price: "425" },
          { size: "1kg", price: "850" },
        ],
        name: "",
        description:
          "Aromatic and layered, Biryani Masala is a symphony of spices specifically designed to create flavorful and fragrant Biryanis.",
        features: [
          "Basmati Bliss: This blend is crafted to complement the delicate flavors of Basmati rice, a key ingredient in authentic Biryani.",
          "Warm & Earthy: Spices like cardamom, cloves, and bay leaves create a warm and earthy base flavor profile.",
          "Floral Notes: A touch of rose petals or kewra water (optional) adds a delicate floral note, characteristic of some Biryani variations.",
          "Citrus Zing: A hint of saffron and sometimes orange or lemon peel adds a touch of brightness and complexity to the flavor profile.",
          "Layered Perfection: Biryani Masala allows you to create restaurant-style Biryani at home, with distinct layers of flavor in every bite.",
        ],
      },
    ],
  },
  {
    id: "avmp40",
    name: "Pulav Masala",

    imageUrl: "https://i.ibb.co/Yk8k0pX/Pulav-Masala.png",
    category: "masala",

    description:
      "Versatile and flavorful, Pulav Masala is a blend perfect for creating a variety of aromatic rice dishes.",
    additionalInfo: [
      {
        options: [
          { size: "100g", price: "85" },
          { size: "250g", price: "215" },
          { size: "500g", price: "425" },
          { size: "1kg", price: "850" },
        ],
        name: "",
        description:
          "Versatile and flavorful, Pulav Masala is a blend perfect for creating a variety of aromatic rice dishes.",
        features: [
          "Warm & Comforting: Spices like coriander, cumin, and turmeric create a warm and comforting base flavor profile that complements various vegetables and proteins.",
          "Customizable Heat: A touch of chili powder allows you to adjust the spice level to your preference.",
          "Beyond Rice: This blend can also be used to add flavor to dals, lentil soups, or stir-fried vegetables.",
          "Simple Weeknight Meals: Pulav Masala makes creating delicious and satisfying rice dishes quick and easy. Just add it to rice, vegetables, and your choice of protein for a complete meal.",
        ],
      },
    ],
  },
  {
    id: "avmp41",
    name: "Curry Masala",

    imageUrl: "https://i.ibb.co/7YC8Qkt/Curry-Masala.png",
    category: "masala",

    description:
      "A base for endless possibilities, Curry Masala is a versatile blend that forms the foundation for a wide range of curries.",
    additionalInfo: [
      {
        options: [
          { size: "100g", price: "85" },
          { size: "250g", price: "215" },
          { size: "500g", price: "425" },
          { size: "1kg", price: "850" },
        ],
        name: "",
        description:
          "A base for endless possibilities, Curry Masala is a versatile blend that forms the foundation for a wide range of curries.",
        features: [
          "Warm Spices: Aromatic spices like coriander, cumin, turmeric, and chilies create a warm and inviting base flavor profile that can be customized to create regional variations.",
          "Building Block: This blend is a starting point for your curry journey. Add coconut milk for a creamy Thai curry, tomatoes and yogurt for a classic Indian butter chicken, or build on it with additional spices for a more robust flavor profile.",
          "Endless Inspiration: With Curry Masala, the possibilities are endless. Explore the world of curries and create flavorful dishes from various regions.",
        ],
      },
    ],
  },
  {
    id: "avmp44",
    name: "Chat Masala",

    imageUrl: "https://i.ibb.co/pLnt5dm/Chat-Masala.png",
    category: "masala",

    description:
      "Tangy and Flavorful: Chat Masala is a unique blend of spices used to add a burst of tangy, salty, and sometimes sweet flavor to street food snacks and chaat recipes.",
    additionalInfo: [
      {
        options: [
          { size: "100g", price: "85" },
          { size: "250g", price: "215" },
          { size: "500g", price: "425" },
          { size: "1kg", price: "850" },
        ],
        name: "",
        description:
          "Tangy and Flavorful: Chat Masala is a unique blend of spices used to add a burst of tangy, salty, and sometimes sweet flavor to street food snacks and chaat recipes.",
        features: [
          "Tangy Trio: Aromatic spices like coriander and cumin are balanced by the sharp tang of amchoor (dried mango powder) and sometimes black salt.",
          "Sweet & Spicy: A touch of sugar or jaggery adds a hint of sweetness, while a sprinkle of black pepper or chili powder provides a subtle warmth.",
          "Chaat Magic: Chat Masala is the secret ingredient to elevate your favorite chaat recipes like samosa chaat, bhel puri, or fruit chaat. Simply sprinkle it on top for a burst of flavor and complexity.",
          "Beyond Chaat: This versatile blend can also be used to rim glasses for Bloody Marys, add a tangy twist to popcorn, or season fruits and salads.",
        ],
      },
    ],
  },
  {
    id: "avmp45",
    name: "Fried Rice Masala",

    imageUrl: "https://i.ibb.co/nnXqKV6/Fried-Rice-Masala.png",
    category: "masala",

    description:
      "Savory and Aromatic: Fried Rice Masala is a blend of spices specifically designed to add depth and complexity to fried rice dishes.",
    additionalInfo: [
      {
        options: [
          { size: "100g", price: "85" },
          { size: "250g", price: "215" },
          { size: "500g", price: "425" },
          { size: "1kg", price: "850" },
        ],
        name: "",
        description:
          "Savory and Aromatic: Fried Rice Masala is a blend of spices specifically designed to add depth and complexity to fried rice dishes.",
        features: [
          "Asian Inspiration: Spices like ginger, garlic, star anise, and sometimes Szechuan peppercorns create a savory and aromatic profile reminiscent of Asian cuisine.",
          "Umami Boost: The inclusion of ingredients like dried mushrooms or soy sauce adds a layer of umami richness to your fried rice.",
          "Stir-fry Magic: This blend is perfect for creating flavorful and satisfying fried rice dishes. Simply add it to cooked rice, vegetables, and your choice of protein for a quick and delicious meal.",
          "Beyond Fried Rice: Fried Rice Masala can also be used to add an Asian-inspired twist to noodle dishes, stir-fries, or even marinades.",
        ],
      },
    ],
  },

  {
    id: "avmp50",
    name: "Kandi Podi",

    imageUrl: "https://i.ibb.co/p0BN0W9/Kandi-Podi.png",
    category: "powder",

    description:
      "Unlock the taste of Andhra with Avakaaya Mart's Kandi Podi, a traditional lentil and spice blend that adds a burst of flavor and a satisfying crunch to any dish! ",
    additionalInfo: [
      {
        options: [
          { size: "100g", price: "55" },
          { size: "250g", price: "140" },
          { size: "500g", price: "275" },
          { size: "1kg", price: "550" },
        ],
        name: "",
        description:
          "Unlock the taste of Andhra with Avakaaya Mart's Kandi Podi, a traditional lentil and spice blend that adds a burst of flavor and a satisfying crunch to any dish! Crafted using generations-old family recipes and high-quality ingredients, our Kandi Podi is a secret weapon for creating authentic and delicious South Indian cuisine.",
        features: [
          "Spicy & Savory: Our Kandi Podi is a delightful blend of roasted lentils, aromatic spices, and red chilies, offering a perfect balance of savory nuttiness and fiery heat.",
          "The Secret Ingredient: Curry leaves add a unique depth of flavor and a touch of citrusy aroma, elevating the overall taste profile.",
          "Crunchy Delight: Dry-roasted lentils add a satisfying textural contrast, making Kandi Podi a delightful condiment and culinary tool.",
        ],
      },
    ],
  },
  {
    id: "avmp51",
    name: "Karam podi",

    imageUrl: "https://i.ibb.co/9hJ4vKy/Karam-podi.png",
    category: "powder",

    description:
      "Avakaaya Mart's Karam Podi is a traditional South Indian condiment, also known as Idli Karam or Gunpowder. ",
    additionalInfo: [
      {
        options: [
          { size: "100g", price: "55" },
          { size: "250g", price: "140" },
          { size: "500g", price: "275" },
          { size: "1kg", price: "550" },
        ],
        name: "",
        description:
          "Avakaaya Mart's Karam Podi is a traditional South Indian condiment, also known as Idli Karam or Gunpowder. It's a dry, chutney-like powder that packs a punch of flavor and a pleasant heat. ",
        features: [
          "Rice Enhancer: Mix a spoonful of Karam Podi with hot rice and a drizzle of ghee for a simple yet flavorful meal.",
          "Dosa and Idli Topping: The traditional way to enjoy it! Sprinkle some podi over your idlis or dosas for a burst of spice and savoriness.",
          "Vegetable Stir-fry Addition: Add a teaspoon of Karam Podi to your vegetable stir-fry for a smoky and spicy kick.",
          "Dal and Sambar Topping: Elevate your lentil dishes by sprinkling some podi on top before serving.",
        ],
      },
    ],
  },
  {
    id: "avmp52",
    name: "Idly karam podi",

    imageUrl: "https://i.ibb.co/djWV6rc/Idly-karam-podi.png",
    category: "powder",

    description:
      "Avakaaya Mart's is the quintessential South Indian Karam Podi, perfect for enjoying with idlis and dosas.",
    additionalInfo: [
      {
        options: [
          { size: "100g", price: "55" },
          { size: "250g", price: "140" },
          { size: "500g", price: "275" },
          { size: "1kg", price: "550" },
        ],
        name: "",
        description:
          "Avakaaya Mart's is the quintessential South Indian Karam Podi, perfect for enjoying with idlis and dosas.",
        features: [
          "Classic Choice: This is the quintessential South Indian Karam Podi, perfect for enjoying with idlis and dosas.",
          "Ingredient Focus: Avakaaya Mart's Idly Karam Podi likely features a blend of urad dal (black gram), dried red chilies, peanuts, cumin seeds, and curry leaves. This combination creates a well-balanced flavor profile with a moderate spice level.",
          "Texture: Idly Karam Podi is typically a coarse powder with a slightly grainy texture. This allows for a delightful crunch when sprinkled over your soft idlis or dosas.",
        ],
      },
    ],
  },
  {
    id: "avmp53",
    name: "Nalla Karam podi",

    imageUrl: "https://i.ibb.co/wwyZrNF/Nalla-karam.png",
    category: "powder",

    description:
      "Avakaaya Mart's Nalla Karam Podi, which translates to 'Black Chili Powder,' is known for its darker color and intense heat.",
    additionalInfo: [
      {
        options: [
          { size: "100g", price: "60" },
          { size: "250g", price: "150" },
          { size: "500g", price: "300" },
          { size: "1kg", price: "600" },
        ],
        name: "",
        description:
          "Avakaaya Mart's Nalla Karam Podi, which translates to 'Black Chili Powder,' is known for its darker color and intense heat.",
        features: [
          "Spicy Adventure: Nalla Karam Podi, which translates to 'Black Chili Powder', is known for its darker color and intense heat.",
          "Ingredient Spotlight: This variety often uses black urad dal with skin, along with a generous amount of red chilies. Additional ingredients like coriander seeds and roasted curry leaves might be included for added depth.",
          "Bold Flavor: Get ready for a fiery experience! Nalla Karam Podi is ideal for those who love a good dose of spice. The dark-colored urad dal and the use of more chilies contribute to its robust and intense flavor.",
        ],
      },
    ],
  },
  {
    id: "avmp54",
    name: "Putnala karam podi",

    imageUrl: "https://i.ibb.co/TrTdrFM/Putnala-karam-podi.png",
    category: "powder",

    description:
      "Putnala Karam Podi, also known as Pappula Podi, translates to 'powder made with lentils.'",
    additionalInfo: [
      {
        options: [
          { size: "100g", price: "45" },
          { size: "250g", price: "115" },
          { size: "500g", price: "225" },
          { size: "1kg", price: "450" },
        ],
        name: "",
        description:
          "Putnala Karam Podi, also known as Pappula Podi, translates to 'powder made with lentils.'",
        features: [
          "All-Purpose Delight: This versatile podi is a staple in many South Indian households. Putnala Karam Podi, also known as Pappula Podi, translates to 'powder made with lentils'.",
          "Ingredient Harmony: Avakaaya Mart's Putnala Karam Podi likely features dry-roasted chana dal (gram dal) as the base. This is often combined with ingredients like dry coconut, garlic, and red chilies for a balanced flavor profile. Some variations might include curry leaves, mustard seeds, or sesame seeds for added depth.",
          "Flavor Journey: Putnala Karam Podi offers a delightful combination of savory, slightly spicy, and a hint of sweetness from the coconut. It's a crowd-pleasing podi that complements various dishes.",
          "Versatility Unbound: This podi is a true all-rounder. ",
        ],
      },
    ],
  },
  {
    id: "avmp55",
    name: "Sambar Podi",

    imageUrl: "https://i.ibb.co/Z1qbCb9/Sambar-Podi.png",
    category: "powder",

    description:
      "Avakaaya Mart's Sambar podi is specially crafted to elevate the taste of your homemade Sambar, a popular South Indian lentil stew.",
    additionalInfo: [
      {
        options: [
          { size: "100g", price: "55" },
          { size: "250g", price: "140" },
          { size: "500g", price: "275" },
          { size: "1kg", price: "550" },
        ],
        name: "",
        description:
          "Avakaaya Mart's Sambar podi is specially crafted to elevate the taste of your homemade Sambar, a popular South Indian lentil stew.",
        features: [
          "Sambar's Secret Weapon: This podi is specially crafted to elevate the taste of your homemade Sambar, a popular South Indian lentil stew.",
          "Ingredient Focus: Avakaaya Mart's Sambar Podi likely features a blend of lentils, spices, and dried chilies. Typical ingredients might include toor dal (split pigeon pea), coriander seeds, cumin seeds, fenugreek seeds, red chilies, and sometimes tamarind for a touch of tang.",
          "Depth and Complexity: Sambar Podi adds a robust and flavorful base to your Sambar. The combination of spices and lentils creates a complex taste profile that complements the lentils and vegetables in the stew.",
          "Beyond Sambar: While it shines in Sambar, Sambar Podi can be used in other ways too. Try it as a:o	Seasoning: Similar to Putnala Karam Podi, it can be used as a seasoning for various curries and vegetable dishes. &	Masala for Vegetables: Mix some podi with yogurt or oil to create a quick and flavorful marinade for vegetables before roasting or grilling.",
        ],
      },
    ],
  },
  {
    id: "avmp56",
    name: "Rasam Podi",

    imageUrl: "https://i.ibb.co/YXVXjX1/Rasam-Podi.png",
    category: "powder",

    description:
      "This vibrant red powder is the heart and soul of a delicious bowl of Rasam, a tangy and spicy lentil soup popular in South India.",
    additionalInfo: [
      {
        options: [
          { size: "100g", price: "55" },
          { size: "250g", price: "140" },
          { size: "500g", price: "275" },
          { size: "1kg", price: "550" },
        ],
        name: "",
        description:
          "This vibrant red powder is the heart and soul of a delicious bowl of Rasam, a tangy and spicy lentil soup popular in South India.",
        features: [
          "Soul of Rasam: This vibrant red powder is the heart and soul of a delicious bowl of Rasam, a tangy and spicy lentil soup popular in South India.",
          "Ingredient Symphony: Avakaaya Mart's Rasam Podi likely features a symphony of spices and lentils. Typical ingredients might include toor dal (split pigeon pea), coriander seeds, cumin seeds, fenugreek seeds, red chilies, and sometimes black peppercorns or even dry mango powder for a touch of tang.",
          "Tangy and Spicy Punch: Rasam Podi adds a robust and complex flavor profile to your Rasam. The combination of spices creates a tangy and spicy punch that perfectly complements the lentils and the souring agent used in the soup (like tamarind or tomatoes).",
        ],
      },
    ],
  },
  {
    id: "avmp57",
    name: "Karvepaku Karam Podi ",

    imageUrl: "https://i.ibb.co/4VWR0fY/Karvepaku-Karam-Podi-Curry-Leaf-Powder.png",
    category: "powder",

    description:
      "This unique podi celebrates the aromatic curry leaf, a staple herb in South Indian cuisine. Karvepaku Karam Podi translates to 'curry leaf powder.'",
    additionalInfo: [
      {
        options: [
          { size: "100g", price: "60" },
          { size: "250g", price: "150" },
          { size: "500g", price: "300" },
          { size: "1kg", price: "600" },
        ],
        name: "",
        description:
          "This unique podi celebrates the aromatic curry leaf, a staple herb in South Indian cuisine. Karvepaku Karam Podi translates to 'curry leaf powder.'",
        features: [
          "Aromatic Delight: This unique podi celebrates the aromatic curry leaf, a staple herb in South Indian cuisine. Karvepaku Karam Podi translates to 'curry leaf powder.'",
          "Ingredient Spotlight: Avakaaya Mart's Karvepaku Karam Podi likely features dried curry leaves as the star ingredient. This might be combined with a minimal amount of spices like dry red chilies, urad dal, and chana dal for added depth and a hint of heat.",
          "Earthy and Fragrant: Karvepaku Karam Podi is known for its unique aroma and earthy flavor profile. The curry leaves add a pleasant fragrance, while the minimal spices complement without overpowering the herb's delicate taste.",
        ],
      },
    ],
  },
  {
    id: "avmp58",
    name: "Nethi Karam Podi",

    imageUrl: "https://i.ibb.co/mH8bpCz/Nethi-Karam.png",
    category: "powder",

    description:
      "This podi, also known as Ghee Karam Podi, is a fiery blend of spices and ghee, adding a rich and aromatic heat to your meals.",
    additionalInfo: [
      {
        options: [
          { size: "100g", price: "70" },
          { size: "250g", price: "175" },
          { size: "500g", price: "350" },
          { size: "1kg", price: "700" },
        ],
        name: "",
        description:
          "This podi, also known as Ghee Karam Podi, is a fiery blend of spices and ghee, adding a rich and aromatic heat to your meals.",
        features: [
          "Aromatic Heat: This podi, also known as Ghee Karam Podi, is a fiery blend of spices and ghee, adding a rich and aromatic heat to your meals.",
          "Ingredient Symphony: Avakaaya Mart's Nethi Karam Podi likely features a medley of spices like red chilies, coriander seeds, cumin seeds, and fenugreek seeds. The star ingredient, though, is ghee, which adds a rich and nutty flavor and helps bind the spices together.",
          "Fiery and Flavorful: Nethi Karam Podi packs a punch of heat, making it a favorite among spice enthusiasts. The combination of spices and ghee creates a complex flavor profile that's both fiery and savory.",
        ],
      },
    ],
  },
  {
    id: "avmp59",
    name: "Velluli Karam Podi",

    imageUrl: "https://i.ibb.co/jJ1vPwm/Velluli-Karam-Podi-Garlic-Karam-Podi.png",
    category: "powder",

    description:
      "This podi celebrates the pungent and savory flavor of garlic, adding a unique depth to your dishes.",
    additionalInfo: [
      {
        options: [
          { size: "100g", price: "65" },
          { size: "250g", price: "170" },
          { size: "500g", price: "325" },
          { size: "1kg", price: "650" },
        ],
        name: "",
        description:
          "This podi celebrates the pungent and savory flavor of garlic, adding a unique depth to your dishes.",
        features: [
          "Garlicky Goodness: This podi celebrates the pungent and savory flavor of garlic, adding a unique depth to your dishes.",
          "Ingredient Spotlight: Avakaaya Mart's Velluli Karam Podi likely features dried garlic as the main ingredient. This might be combined with a minimal amount of spices like dry red chilies, urad dal, and chana dal for added depth and a hint of heat.",
          "Garlicky and Spicy: Velluli Karam Podi is known for its intense garlic flavor and a touch of spice. The garlic adds a savory and pungent depth, while the minimal spices complement without overpowering the garlic's distinctive taste.",
        ],
      },
    ],
  },
  {
    id: "avmp60",
    name: "Kobbarri Karam Podi ",

    imageUrl: "https://i.ibb.co/xGtcmVW/Kobbarri-Karam-Podi-Coconut-Karam-Podi.png",
    category: "powder",

    description:
      "This podi showcases the richness and mild sweetness of coconut, adding a unique balance to your dishes.",
    additionalInfo: [
      {
        options: [
          { size: "100g", price: "60" },
          { size: "250g", price: "150" },
          { size: "500g", price: "300" },
          { size: "1kg", price: "600" },
        ],
        name: "",
        description:
          "This podi showcases the richness and mild sweetness of coconut, adding a unique balance to your dishes.",
        features: [
          "Coconutty Delight: This podi showcases the richness and mild sweetness of coconut, adding a unique balance to your dishes.",
          "Ingredient Harmony: Avakaaya Mart's Kobbarri Karam Podi likely features dry coconut as the base ingredient. This might be combined with a blend of spices like red chilies, coriander seeds, cumin seeds, and curry leaves for a complex flavor profile.",
          "Sweet, Spicy, and Savory: Kobbarri Karam Podi offers a delightful balance of flavors. The coconut adds a touch of sweetness and richness, while the spices provide a hint of heat and savory notes.",
        ],
      },
    ],
  },
  {
    id: "avmp61",
    name: "Avisala Karam Podi ",

    imageUrl: "https://i.ibb.co/7bxWNLr/Avisala-Karam.png",
    category: "powder",

    description:
      "This podi celebrates the tangy and sour flavor of tamarind, adding a unique dimension to your dishes.",
    additionalInfo: [
      {
        options: [
          { size: "100g", price: "70" },
          { size: "250g", price: "175" },
          { size: "500g", price: "350" },
          { size: "1kg", price: "700" },
        ],
        name: "",
        description:
          "This podi celebrates the tangy and sour flavor of tamarind, adding a unique dimension to your dishes.",
        features: [
          "Tangy and Spicy Delight: This podi celebrates the tangy and sour flavor of tamarind, adding a unique dimension to your dishes.",
          "Ingredient Harmony: Avakaaya Mart's Avisala Karam Podi likely features tamarind pulp as the key ingredient. This might be combined with a blend of spices like red chilies, coriander seeds, cumin seeds, and fenugreek seeds for a complex flavor profile.",
          "Tangy, Spicy, and Sweet: Avisala Karam Podi offers a delightful interplay of flavors. The tamarind adds a tangy and sour base, while the spices provide a hint of heat and a touch of sweetness.",
        ],
      },
    ],
  },
  {
    id: "avmp62",
    name: "Kakarakaya Karam Podi",

    imageUrl:
      "https://i.ibb.co/zHg1qyD/Kakarakaya-Karam-Podi-Bitter-Gourd-Karam-Podi.png",
    category: "powder",

    description:
      "This podi showcases the unique flavor of bitter gourd, adding a depth and complexity to your dishes.",
    additionalInfo: [
      {
        options: [
          { size: "100g", price: "90" },
          { size: "250g", price: "225" },
          { size: "500g", price: "450" },
          { size: "1kg", price: "900" },
        ],
        name: "",
        description:
          "This podi showcases the unique flavor of bitter gourd, adding a depth and complexity to your dishes.",
        features: [
          "Unique Bitter-Spicy Blend: This podi showcases the unique flavor of bitter gourd, adding a depth and complexity to your dishes.",
          "Ingredient Spotlight: Avakaaya Mart's Kakarakaya Karam Podi likely features dried bitter gourd as the main ingredient. This might be combined with a blend of spices like red chilies, coriander seeds, cumin seeds, and curry leaves for a balanced flavor profile.",
          "Bitter, Spicy, and Earthy: Kakarakaya Karam Podi offers a distinctive flavor experience. The bitter gourd adds a unique depth and complexity, while the spices provide a hint of heat and a touch of earthiness.",
        ],
      },
    ],
  },
  {
    id: "avmp63",
    name: "Pudhina Karam Podi ",

    imageUrl: "https://i.ibb.co/0VQcr8s/Pudhina-Karam-Podi-Mint-Karam-Podi.png",
    category: "powder",

    description:
      "This podi celebrates the refreshing and cooling flavor of mint, adding a burst of freshness to your dishes.",
    additionalInfo: [
      {
        options: [
          { size: "100g", price: "90" },
          { size: "250g", price: "225" },
          { size: "500g", price: "450" },
          { size: "1kg", price: "900" },
        ],
        name: "",
        description:
          "This podi celebrates the refreshing and cooling flavor of mint, adding a burst of freshness to your dishes.",
        features: [
          "Refreshing and Spicy Delight: This podi celebrates the refreshing and cooling flavor of mint, adding a burst of freshness to your dishes.",
          "Ingredient Symphony: Avakaaya Mart's Pudhina Karam Podi likely features dried mint leaves as the key ingredient. This might be combined with a blend of spices like red chilies, coriander seeds, cumin seeds, and a touch of asafoetida for added depth.",
          "Refreshing, Spicy, and Earthy: Pudhina Karam Podi offers a delightful combination of flavors. The mint adds a refreshing and cooling sensation, while the spices provide a hint of heat and a touch of earthiness.",
        ],
      },
    ],
  },
  {
    id: "avmp64",
    name: "Kothimeera Karam Podi ",

    imageUrl:
      "https://i.ibb.co/jrV9Mcb/Kothimeera-Karam-Podi-Cilantro-Karam-Podi.png",
    category: "powder",

    description:
      "This podi celebrates the vibrant and fresh flavor of cilantro, adding a burst of herbaceousness to your dishes.",
    additionalInfo: [
      {
        options: [
          { size: "100g", price: "90" },
          { size: "250g", price: "225" },
          { size: "500g", price: "450" },
          { size: "1kg", price: "900" },
        ],
        name: "",
        description:
          "This podi celebrates the vibrant and fresh flavor of cilantro, adding a burst of herbaceousness to your dishes.",
        features: [
          "Fresh and Spicy Delight: This podi celebrates the vibrant and fresh flavor of cilantro, adding a burst of herbaceousness to your dishes. ",
          "Ingredient Harmony: Avakaaya Mart's Kothimeera Karam Podi likely features dried cilantro leaves as the key ingredient. This might be combined with a blend of spices like red chilies, coriander seeds, cumin seeds, and a touch of fenugreek seeds for added depth and complexity.",
          "Fresh, Spicy, and Earthy: Kothimeera Karam Podi offers a delightful combination of flavors. The cilantro adds a bright, fresh, and herbaceous note, while the spices provide a hint of heat and a touch of earthiness.",
        ],
      },
    ],
  },
  {
    id: "avmp65",
    name: "Curry Podi",

    imageUrl: "https://i.ibb.co/syNZTfj/Curry-Podi.png",
    category: "powder",

    description:
      "This vibrant red powder is the aromatic base for many South Indian curries, stews, and lentil dishes.",
    additionalInfo: [
      {
        options: [
          { size: "100g", price: "75" },
          { size: "250g", price: "190" },
          { size: "500g", price: "375" },
          { size: "1kg", price: "750" },
        ],
        name: "",
        description:
          "This vibrant red powder is the aromatic base for many South Indian curries, stews, and lentil dishes.",
        features: [
          "The Heart of South Indian Curries: This vibrant red powder is the aromatic base for many South Indian curries, stews, and lentil dishes.",
          "Ingredient Symphony: Avakaaya Mart's Curry Podi likely features a complex blend of spices and lentils. Typical ingredients might include coriander seeds, cumin seeds, fenugreek seeds, red chilies, urad dal (black gram), and chana dal (gram dal). Some variations might include turmeric, mustard seeds, or curry leaves for additional depth.",
          "Flavorful and Versatile: Curry Podi adds a robust and well-rounded flavor profile with a touch of heat to your curries. The combination of spices and lentils creates a complex base that complements various vegetables, lentils, and meats used in South Indian curries.",
        ],
      },
    ],
  },
  {
    id: "avmp66",
    name: "Royya Podi (Shrimp Powder)",

    imageUrl: "https://i.ibb.co/Y7GQQ47/Royya-Podi-Shrimp-Powder.png",
    category: "powder",

    description:
      "This unique condiment is a powerhouse of umami flavor, adding a deep and savory taste to your dishes.",
    additionalInfo: [
      {
        options: [
          { size: "100g", price: "150" },
          { size: "250g", price: "375" },
          { size: "500g", price: "750" },
          { size: "1kg", price: "1500" },
        ],
        name: "",
        description:
          "This unique condiment is a powerhouse of umami flavor, adding a deep and savory taste to your dishes.",
        features: [
          "Umami Bomb: This unique condiment is a powerhouse of umami flavor, adding a deep and savory taste to your dishes.",
          "Ingredient Focus: Avakaaya Mart's Royya Podi likely features dried shrimp (royya) as the key ingredient. These might be dry-roasted or fried before being ground into a fine powder.",
          "Rich and Savory Punch: Royya Podi packs a powerful umami punch, adding a depth of seafood flavor to your dishes.",
        ],
      },
    ],
  },
  {
    id: "avmp67",
    name: "Mungaku karam podi",

    imageUrl:
      "https://i.ibb.co/HqqscBc/munagaku.png",
    category: "powder",

    description:
      "This podi celebrates the vibrant and fresh flavor of cilantro, adding a burst of herbaceousness to your dishes.",
    additionalInfo: [
      {
        options: [
          { size: "100g", price: "90" },
          { size: "250g", price: "225" },
          { size: "500g", price: "450" },
          { size: "1kg", price: "900" },
        ],
        name: "",
        description:
          "This podi celebrates the vibrant and fresh flavor of cilantro, adding a burst of herbaceousness to your dishes.",
        features: [
          "Fresh and Spicy Delight: This podi celebrates the vibrant and fresh flavor of cilantro, adding a burst of herbaceousness to your dishes. ",
          "Ingredient Harmony: Avakaaya Mart's Kothimeera Karam Podi likely features dried cilantro leaves as the key ingredient. This might be combined with a blend of spices like red chilies, coriander seeds, cumin seeds, and a touch of fenugreek seeds for added depth and complexity.",
          "Fresh, Spicy, and Earthy: Kothimeera Karam Podi offers a delightful combination of flavors. The cilantro adds a bright, fresh, and herbaceous note, while the spices provide a hint of heat and a touch of earthiness.",
        ],
      },
    ],
  },
  {
    id: "avmp68",
    name: "Motichur Laddu",

    imageUrl: "https://i.ibb.co/zhhcT6L/laddu.png",
    category: "sweets",

    description:
      "Motichoor Laddu, the (pearl) of Indian sweets, is a beloved dessert made with tiny, deep-fried gram flour balls (boondi) soaked in a fragrant sugar syrup.",
    additionalInfo: [
      {
        options: [
          { size: "250g", price: "150" },
          { size: "500g", price: "300" },
          { size: "1kg", price: "600" },
        ],
        name: "",
        description:
          "Motichoor Laddu, the (pearl) of Indian sweets, is a beloved dessert made with tiny, deep-fried gram flour balls (boondi) soaked in a fragrant sugar syrup.",
        features: [
          "Base: Tiny boondi made from gram flour (besan), offering a light and crispy texture.",
          "Sweetness: Sugar syrup provides a burst of sweetness, often with a subtle caramel note.",
          "	Ghee: Used for frying the boondi and sometimes drizzled on top for a touch of richness.",
          "	Flavor Boosters: Cardamom powder adds a warm and aromatic touch",
        ],
      },
    ],
  },
  {
    id: "avmp69",
    name: "Sanna boondi Laddu",

    imageUrl: "https://i.ibb.co/ZmtTzfn/SANNABOONDILADDU.png",
    category: "sweets",

    description:
      "Sanna Boondi Laddu, also known as Shenga Boondi Laddu or Kadalai Boondi Laddu, is a delightful sweet.",
    additionalInfo: [
      {
        options: [
          { size: "250g", price: "90" },
          { size: "500g", price: "180" },
          { size: "1kg", price: "360" },
        ],
        name: "",
        description:
          "Sanna Boondi Laddu, also known as Shenga Boondi Laddu or Kadalai Boondi Laddu, is a delightful sweet originating in South India, perfect for festivals and celebrations. Here's a breakdown of the classic recipe and ways to elevate its taste:",
        features: [
          "Base: Made with gram flour (channa dal flour) thats deep-fried into tiny, crispy balls called boondi.",
          "Sweetness: Typically uses jaggery or sugar for a burst of sweetness, although some variations might use palm sugar or dates.",
          "Ghee: Used for binding the ingredients and adding a nutty richness.",
          "Nuts (Optional): Chopped cashews or peanuts are sometimes added for a textural contrast and a nutty flavor.",
        ],
      },
    ],
  },
  {
    id: "avmp70",
    name: "Lavu boondi Laddu",

    imageUrl: "https://i.ibb.co/HnS9pqk/LAVUBOONDILADDU.png",
    category: "sweets",

    description:
      "Lavu Boondi Laddu also known as Bundiar Laddu, is a delightful sweet originating , perfect for festivals.",
    additionalInfo: [
      {
        options: [
          { size: "250g", price: "90" },
          { size: "500g", price: "180" },
          { size: "1kg", price: "360" },
        ],
        name: "",
        description:
          "Lavu Boondi Laddu also known as Bundiar Laddu, is a delightful sweet originating in Andhra Pradesh, perfect for festivals and celebrations. Here's a breakdown of the classic recipe and ways to elevate its taste:.",
        features: [
          "Base: Made with chickpea flour (besan) that's deep-fried into tiny, crispy balls called boondi.",
          "	Sweetness: Lavingi (a type of rock candy made from palm jaggery) provides a unique sweetness and a crunchy texture.",
          "Ghee: Used for binding the ingredients and adding a nutty richness.",
          "	Nuts (Optional): Chopped cashews or peanuts are sometimes added for a textural contrast and a nutty flavor",
        ],
      },
    ],
  },
  {
    id: "avmp71",
    name: "Mysorepak(oil)",

    imageUrl: "https://i.ibb.co/1R70yJW/Mysore-Pak.png",
    category: "sweets",

    description:
      "Mysore Pak, the (king of sweets) from Karnataka, is a dense and delectable fudge-like sweet made with gram flour (besan), ghee, and sugar.",
    additionalInfo: [
      {
        options: [
          { size: "250g", price: "90" },
          { size: "500g", price: "180" },
          { size: "1kg", price: "360" },
        ],
        name: "",
        description:
          "Mysore Pak, the (king of sweets) from Karnataka, is a dense and delectable fudge-like sweet made with gram flour (besan), ghee, and sugar. Here's a breakdown of the classic Mysore Pak and ways to elevate its taste:.",
        features: [
          "	Base: Made with gram flour (besan), offering a nutty and slightly grainy texture.",
          "Sweetness: Sugar provides sweetness, and the slow cooking process caramelizes it for a rich flavor.",
          "Ghee: Used generously, binding the ingredients and contributing a rich, nutty aroma.",
          "	Texture: Dense, fudgy, and melt-in-your-mouth.",
        ],
      },
    ],
  },
  {
    id: "avmp72",
    name: "Sunnundalu(bellam)",

    imageUrl: "https://i.ibb.co/q1Cq6Bx/SUNNUNDALU.png",
    category: "sweets",

    description:
      "Cardamom adds a warm and aromatic touch. Ghee binds the ingredients and contributes a nutty richness..",
    additionalInfo: [
      {
        options: [
          { size: "250g", price: "130" },
          { size: "500g", price: "260" },
          { size: "1kg", price: "520" },
        ],
        name: "",
        description:
          "This podi celebrates the vibrant and fresh flavor of cilantro, adding a burst of herbaceousness to your dishes.",
        features: [
          "Base: Urad dal (black gram) flour, offering a protein and fiber punch.",
          "	Sweetness: Jaggery or sugar provides natural or refined sweetness.",
          "Flavor Boosters: Cardamom adds a warm and aromatic touch. Ghee binds the ingredients and contributes a nutty richness.",
        ],
      },
    ],
  },
  {
    id: "avmp73",
    name: "Nune Ariselu",

    imageUrl: "https://i.ibb.co/f9L5xRf/OIL-ARISALU.png",
    category: "sweets",

    description:
      "Oil Ariselu is a delightful and slightly healthier twist on the traditional South Indian sweet, Ariselu.",
    additionalInfo: [
      {
        options: [
          { size: "250g", price: "90" },
          { size: "500g", price: "180" },
          { size: "1kg", price: "360" },
        ],
        name: "",
        description:
          "Oil Ariselu is a delightful and slightly healthier twist on the traditional South Indian sweet, Ariselu.",
        features: [
          "Base: Made with rice flour, offering a light and slightly chewy texture.",
          "Sweetness: Typically uses jaggery or sugar for a burst of sweetness, but with less ghee compared to traditional ariselu.",
          "	Sesame Seeds: Ariselu are often garnished with sesame seeds for a delightful crunch and a nutty flavor.",
          "	Healthier Twist: Uses sunflower oil for frying instead of ghee, making it a more calorie-conscious option.",
        ],
      },
    ],
  },
  {
    id: "avmp74",
    name: "Nethi Ariselu",

    imageUrl: "https://i.ibb.co/SsSFtLW/Nethi-Ariselu.png",
    category: "sweets",

    description:
      "Nethi Arisalu, also known as Ghee Arisalu or Adhirasam, is a delectable South Indian sweet perfect for festivals and celebrations.",
    additionalInfo: [
      {
        options: [
          { size: "250g", price: "150" },
          { size: "500g", price: "300" },
          { size: "1kg", price: "600" },
        ],
        name: "",
        description:
          "Nethi Arisalu, also known as Ghee Arisalu or Adhirasam, is a delectable South Indian sweet perfect for festivals and celebrations. Here's a breakdown of the classic Nethi Arisalu and how to elevate its taste:",
        features: [
          " Base: Made with rice flour, offering a light and slightly chewy texture.",
          "Sweetness: Jaggery provides a natural sweetness and a rich caramel-like flavor.",
          "Ghee: Used for frying and binding the ingredients, contributing a rich and nutty aroma.",
          "Sesame Seeds: Often sprinkled on the outside for a delightful crunch and a nutty taste.",
        ],
      },
    ],
  },
  {
    id: "avmp75",
    name: "Kajjikayalu(bellam)",

    imageUrl: "https://i.ibb.co/NpV4YHh/bellam-kajjikayalu.png",
    category: "sweets",

    description:
      "Bellam Kajjikayalu, a traditional Andhra Pradesh sweet, is a delightful treat made with a crispy shell and a sweet, flavourful filling.",
    additionalInfo: [
      {
        options: [
          { size: "250g", price: "100" },
          { size: "500g", price: "200" },
          { size: "1kg", price: "400" },
        ],
        name: "",
        description:
          "Bellam Kajjikayalu, a traditional Andhra Pradesh sweet, is a delightful treat made with a crispy shell and a sweet, flavourful filling. Here's a breakdown of the classic recipe and ways to elevate its taste:",
        features: [
          " Base: Made with a combination of Maida (refined flour) and ghee, providing a crispy and slightly flaky shell. ",
          "Filling: A sweet and nutty mixture of jaggery, peanuts, sesame seeds, and roasted chana dal (split chickpeas).",
          "Flavour Boosters: Cardamom powder adds a warm and aromatic touch.",
        ],
      },
    ],
  },
  {
    id: "avmp75",
    name: "Sajja Burelu",

    imageUrl: "https://i.ibb.co/cXRQZRW/SAJJABOORELU.png",
    category: "sweets",

    description:
      "Sajja Borelu, also known as Sajjapappu Vundallu, are popular deep-fried dumplings .",
    additionalInfo: [
      {
        options: [
          { size: "250g", price: "80" },
          { size: "500g", price: "160" },
          { size: "1kg", price: "320" },
        ],
        name: "",
        description:
          "A delightful combination of sweet and savory flavors. Here's a breakdown of the classic Sajja Borelu and ways to elevate their taste:",
        features: [
          " Base: Made with Sajja (Foxtail Millet), offering a unique nutty flavour and a slightly chewy texture.",

          "Sweetness: Jaggery provides a natural sweetness and a rich caramel-like flavour. ",

          "Lentil Filling (Optional): A spiced lentil mixture is sometimes added for a Savory and protein-rich element.",
          "Flavour Boosters: Cardamom adds a warm and aromatic touch. Ghee binds the ingredients and contributes a nutty richness. ",
        ],
      },
    ],
  },
  {
    id: "avmp76",
    name: "White Kalakani",

    imageUrl: "https://i.ibb.co/5xjWdkK/White-kalakand.png",
    category: "sweets",

    description:
      "White Kalakand, also known as doodh kalakand, is a classic Indian sweet made with milk solids (khoya) for a delightful milky taste and a soft, melt-in-your-mouth texture.",
    additionalInfo: [
      {
        options: [
          { size: "250g", price: "150" },
          { size: "500g", price: "300" },
          { size: "1kg", price: "600" },
        ],
        name: "",
        description:
          "White Kalakand, also known as doodh kalakand, is a classic Indian sweet made with milk solids (khoya) for a delightful milky taste and a soft, melt-in-your-mouth texture. Here's a breakdown of the classic recipe and ways to elevate its taste: ",
        features: [
          "Base: Made with milk solids (khoya) cooked down to a dense fudge-like consistency. ",

          "Sweetness: Sugar provides sweetness, and the slow cooking process caramelizes it for a rich flavor. ",

          "Flavor Boosters: Cardamom adds a warm and aromatic touch. Ghee binds the ingredients and contributes a nutty richness.",
        ],
      },
    ],
  },
  {
    id: "avmp77",
    name: "Brown Kalakani",

    imageUrl: "https://i.ibb.co/D43J0Mj/BROWNKALAKAND.png",
    category: "sweets",

    description:
      "Brown Kalakand, also sometimes called Chocolate Kalakand, is a delightful twist on the classic Indian sweet Kalakand.",
    additionalInfo: [
      {
        options: [
          { size: "250g", price: "150" },
          { size: "500g", price: "300" },
          { size: "1kg", price: "600" },
        ],
        name: "",
        description:
          "Brown Kalakand, also sometimes called Chocolate Kalakand, is a delightful twist on the classic Indian sweet Kalakand. Here's a breakdown of the classic Brown Kalakand and ways to elevate its taste: ",
        features: [
          "Base: Made with milk solids (khoya) cooked down to a dense fudge-like consistency.",

          "Sweetness: Sugar provides sweetness, and the slow cooking process caramelizes it for a rich flavour. ",

          "Chocolate: Cocoa powder adds a rich chocolatey taste and a deep brown colour.",

          "Nuts (Optional): Chopped nuts like almonds, pistachios, or even cashews are sometimes added for a textural contrast and a nutty flavour.",
        ],
      },
    ],
  },
  {
    id: "avmp78",
    name: "Boondi",

    imageUrl: "https://i.ibb.co/ZLP6B73/boondi.png",
    category: "kara and vadiyalu",

    description:
      "Boondi, those tiny golden spheres made from chickpea flour batter, are a staple in Indian cuisine.",
    additionalInfo: [
      {
        options: [
          { size: "250g", price: "80" },
          { size: "500g", price: "160" },
          { size: "1kg", price: "320" },
        ],
        name: "",
        description:
          "Boondi, those tiny golden spheres made from chickpea flour batter, are a staple in Indian cuisine. They come in two main varieties.",
        features: [
          "Sweet Boondi: Deep-fried and then soaked in a fragrant sugar syrup, offering a delightful textural contrast between the crispy exterior and the slightly soft, syrupy interior.",
          "	Savory Boondi (Kara Boondi): Similar to sweet boondi but not soaked in sugar syrup. Seasoned with spices like chili powder, cumin, and salt for a flavorful crunch.",
        ],
      },
    ],
  },
  {
    id: "avmp79",
    name: "Atukula Mixture",

    imageUrl: "https://i.ibb.co/QX59PFc/Atukula-Mixture.png",
    category: "kara and vadiyalu",

    description:
      "Atukula mixture, also known as poha chivda, is a delightful and popular South Indian snack from Andhra Pradesh.",
    additionalInfo: [
      {
        options: [
          { size: "250g", price: "80" },
          { size: "500g", price: "160" },
          { size: "1kg", price: "320" },
        ],
        name: "",
        description:
          "Atukula mixture, also known as poha chivda, is a delightful and popular South Indian snack from Andhra Pradesh. It's a symphony of textures and flavors, featuring:",
        features: [
          "Crispy Poha (flattened rice): The base of the mixture, offering a satisfying crunch. ",
          "Savory Peanuts: Adding a satisfying protein punch and a nutty undertone.",
          "Roasted Lentils (dal): Contributing a delightful earthiness and a burst of texture.",
          "Spices: A blend of chili powder, turmeric, and other spices creates a flavour  profile that's both spicy and tangy.",
        ],
      },
    ],
  },
  {
    id: "avmp80",
    name: "Chekkalu",

    imageUrl: "https://i.ibb.co/rQP8rmp/chekkalu.png",
    category: "kara and vadiyalu",

    description:
      "This podi celebrates the vibrant and fresh flavor of cilantro, adding a burst of herbaceousness to your dishes.",
    additionalInfo: [
      {
        options: [
          { size: "250g", price: "88" },
          { size: "500g", price: "175" },
          { size: "1kg", price: "350" },
        ],
        name: "",
        description:
          "A Sweet and Savory Delight and How to Elevate Its flavour ",
        features: [
          "Chekalu, also known as jalebi in some regions, are a popular spiral-shaped fried pastry from Andhra Pradesh, known for their unique texture and flavor profile. Savory Chekalu Made from a similar dough but seasoned with spices and herbs, offering a savory and slightly crunchy snack",
        ],
      },
    ],
  },
  {
    id: "avmp81",
    name: "Chegodilu",

    imageUrl: "https://i.ibb.co/L6fds6v/chegodilu.png",
    category: "kara and vadiyalu",

    description:
      "A Savory Sesame Seed Delight and How to Make it Even More Delicious.",
    additionalInfo: [
      {
        options: [
          { size: "250g", price: "85" },
          { size: "500g", price: "170" },
          { size: "1kg", price: "340" },
        ],
        name: "",
        description:
          "Chegodilu, also known as sesame seed candy or til candy, are popular sweet treats originating in Andhra Pradesh.  ",
        features: [
          "These bite-sized delights are packed with flavor and have a delightful crunch. ",
          "Base: Made primarily with jaggery (unrefined cane sugar) or sugar, offering natural sweetness.",
          "Sesame Seeds: White or black sesame seeds are used, adding a nutty flavor and a satisfying crunch.",
          "Ghee/Oil: Used for binding the ingredients and sometimes for frying, contributing a rich aroma",
        ],
      },
    ],
  },
  {
    id: "avmp82",
    name: "Chakaralu",

    imageUrl: "https://i.ibb.co/KKm3WJR/chekaralu.png",
    category: "kara and vadiyalu",

    description:
      "Murukku, also known as murumuru or jantikalu, are delightful South Indian savoury snacks. ",
    additionalInfo: [
      {
        options: [
          { size: "250g", price: "88" },
          { size: "500g", price: "175" },
          { size: "1kg", price: "350" },
        ],
        name: "",
        description:
          "These crispy, twisted delights are typically made from a rice flour dough seasoned with spices and deep-fried. Here's a breakdown of the classic murukku and ways to elevate its taste:",
        features: [
          "Base: Rice flour is the foundation, offering a light and slightly chewy texture.",
          "Flavour Boosters: Spices like urad dal flour, chana dal flour, and fenugreek seeds add a savoury depth.",
          "Chilies provide a touch of heat, while Hing (asafoetida) adds a unique and pungent aroma.",
          "	Texture Play: The deep-frying process creates a satisfyingly crispy exterior.",
        ],
      },
    ],
  },
  {
    id: "avmp83",
    name: "Minappindi Vadiyalu",

    imageUrl: "https://i.ibb.co/bBXQs6s/minapa-vadiyalu.png",
    category: "kara and vadiyalu",

    description:
      "Minapa Vadiyalu, also known as Urad Dal Badi or Black Gram Papad, is a popular and flavourful snack.",
    additionalInfo: [
      {
        options: [
          { size: "250g", price: "80" },
          { size: "500g", price: "160" },
          { size: "1kg", price: "320" },
        ],
        name: "",
        description:
          "Made with black gram dal (urad dal), spices, and often dried herbs, these crispy and savory discs are a staple in many South Indian households..",
        features: [
          "Base: Made with black gram dal (urad dal), providing a slightly chewy and nutty texture.",
          " Seasoning: Cumin seeds, jeera, and salt add a savory and aromatic flavor.",
          "Optional Ingredients: Some variations include dried curry leaves, asafoetida (hing) for a subtle seafood-like flavor, and dried red chilies for a touch of heat.",
        ],
      },
    ],
  },
  {
    id: "avmp84",
    name: "Biyyampindi Vadiyalu",

    imageUrl: "https://i.ibb.co/bPS9DDr/biyampindi-vadiyalu.png",
    category: "kara and vadiyalu",

    description:
      "Biyyam Pindi Vadiyalu, also known as Rice Flour Fryums or Rice Papad, is a popular and versatile snack .",
    additionalInfo: [
      {
        options: [
          { size: "250g", price: "80" },
          { size: "500g", price: "160" },
          { size: "1kg", price: "320" },
        ],
        name: "",
        description:
          "Made with rice flour, spices, and often sago, these crispy and flavorful discs are a staple in many South Indian households. Here's a breakdown of the classic recipe and ways to elevate its taste:",
        features: [
          "Base: Made with rice flour, providing a light and slightly chewy texture.",
          "Seasoning: Cumin seeds, jeera, and salt add a savory and aromatic flavor.",
          "Optional Ingredients: Some variations include sago (tapioca pearls) for a crispier texture, chopped onions for a mild oniony flavor, and curry leaves for an extra layer of aroma.",
        ],
      },
    ],
  },
  {
    id: "avmp85",
    name: "Gummadi Vadiyalu",

    imageUrl: "https://i.ibb.co/PWzbsRB/Gummadikaya-Vadiyalu.png",
    category: "kara and vadiyalu",

    description:
      "Gummadikaya Vadiyalu, also known as Ivy Gourd Fritters, are a popular and savory snack.",
    additionalInfo: [
      {
        options: [
          { size: "250g", price: "120" },
          { size: "500g", price: "240" },
          { size: "1kg", price: "480" },
        ],
        name: "",
        description:
          "Made with ivy gourd (tenderloins), spices, and often gram flour, these crispy and flavorful discs are a staple in many South Indian households. Here's a breakdown of the classic recipe and ways to elevate its taste:",
        features: [
          " Base: Ivy gourd (tenderloins), providing a slightly bitter and crunchy texture.",
          "Seasoning: Cumin seeds, jeera, and salt add a savory and aromatic flavor.",
          "Binding Agent: Gram flour (besan) helps bind the ingredients together and adds a slightly nutty flavor.",
        ],
      },
    ],
  },
  {
    id: "avmp86",
    name: "Saggubiyam Vadiyalu",

    imageUrl: "https://i.ibb.co/HTwD8WB/sagubiyamvadiyalu.png",
    category: "kara and vadiyalu",

    description:
      "Sagubiyam Vadiyalu, also known as Sago Papad, are light, crispy fritters made with sago pearls (sabudana) as the base.",
    additionalInfo: [
      {
        options: [
          { size: "250g", price: "60" },
          { size: "500g", price: "120" },
          { size: "1kg", price: "240" },
        ],
        name: "",
        description:
          "these flavourful discs are a delicious and versatile snack or tea-time accompaniment. Here's a breakdown of the classic recipe and ways to elevate its taste:",
        features: [
          "Base: Made with sago pearls (sabudana), offering a translucent and slightly chewy texture when cooked.",
          "Seasoning: Peanuts, cumin seeds, and curry leaves add a savory and aromatic flavor profile.",
          "	Binding Agent: Potato or tapioca starch helps bind the ingredients together.",
        ],
      },
    ],
  },
  {
    id: "avmp87",
    name: "Ooramirapakayalu",

    imageUrl: "https://i.ibb.co/LxcvC59/Oora-Mirapakayalu.png",
    category: "kara and vadiyalu",

    description:
      "Oora Mirapakayalu, also known as Curd Chilies, Majjiga Mirapakayalu, or Challa Mirapakayalu, is a popular and flavourful pickle .",
    additionalInfo: [
      {
        options: [
          { size: "250g", price: "60" },
          { size: "500g", price: "120" },
          { size: "1kg", price: "240" },
        ],
        name: "",
        description:
          ". Made with green chilies, curd (yogurt), and spices, these tangy and spicy pickles are a staple in many South Indian households. Here's a breakdown of the classic recipe and ways to elevate its taste:",
        features: [
          "Base: Green chilies, providing a spicy and slightly tangy flavour.",
          "Curd: Adds a tangy and creamy texture, balancing the heat of the chilies.",
          "Seasoning: Salt, turmeric powder, and carom seeds (jawing) add a savoury and aromatic flavour.",
        ],
      },
    ],
  },
  {
    id: "avmp88",
    name: "Ghee",

    imageUrl: "https://i.ibb.co/JKPkDjW/ghee.png",
    category: "kara and vadiyalu",

    description:
      "Ghee, also known as clarified butter, is a staple in Indian cuisine and offers a rich, nutty flavour that can elevate many dishes.",
    additionalInfo: [
      {
        options: [
          { size: "250g", price: "175" },
          { size: "500g", price: "350" },
          { size: "1kg", price: "700" },
        ],
        name: "",
        description:
          "Ghee, also known as clarified butter, is a staple in Indian cuisine and offers a rich, nutty flavour that can elevate many dishes. Here's a breakdown of ghee and ways to enhance its taste and uses:.",
        features: [
          "Richness: Ghee is butter that has had the milk solids and water removed, resulting in a concentrated butterfat with a high smoke point. This makes it ideal for high-heat cooking without burning and adds a deep, nutty flavor to dishes.",
          "Natural Sweetness: The caramelization of milk sugars during the clarification process creates a subtle sweetness that complements savory and sweet dishes alike.",
        ],
      },
    ],
  },

  {
    id: "avmp90",
    name: "Minapa Appadalu",

    imageUrl: "https://i.ibb.co/YXd4RqX/minapa-appadalu.png",
    category: "kara and vadiyalu",

    description:
      "Minapa Appadalu, also known as Urad Dal Appam or Black Gram Appam, is a popular and savory South Indian pancake made from black gram dal (urad dal) batter.",
    additionalInfo: [
      {
        options: [{ size: "250g", price: "60" }],
        name: "",
        description:
          "Minapa Appadalu is a staple in many Andhra Pradesh households and a versatile breakfast or snack option. Here's a breakdown of the classic recipe and ways to elevate its taste:.",
        features: [
          "Base: Made with black gram dal (urad dal) batter, providing a slightly nutty and savoury flavour.",
          "	Fermentation: The dal batter is fermented overnight, resulting in a soft and spongy texture.",
          "	Seasoning: Salt and green chilies add a basic flavour profile.",
        ],
      },
    ],
  },
  {
    id: "avmp91",
    name: "Pootha Rekulu",

    imageUrl: "https://i.ibb.co/nzbDL7r/Pootha-Rekulu.png",
    category: "sweets",

    description:
      "Pootharekulu, also known as Poothareku or Paper Sweet, is a delightful and delicate sweet originating in Andhra Pradesh. ",
    additionalInfo: [
      {
        options: [{ size: "5pices", price: "150" }],
        name: "",
        description:
          "These thin, papery layers are a textural and flavorful treat. Here's a breakdown of the classic Pootharekulu and ways to elevate its taste:",
        features: [
          "Base: Made with rice flour, offering a translucent and paper-like consistency.",
          "	Sweetness: Jaggery provides a natural sweetness and a rich caramel-like flavour.",
          "Ghee: Used for binding the layers and adding a touch of richness.",
          "Nuts (Optional): Crushed cashews or peanuts are sometimes sprinkled between the layers for a delightful crunch and a nutty flavour.",
        ],
      },
    ],
  },
];

export default Pickle;
