import React from "react";
import BottomNavbar from "../../components/BottomNavbar";

const RefundandCancellation = () => {
  return (
    <>
      <div className="container-fluid">
        <div className="container">
          <div>
            <h1 className="terms-main-heading">Refund and Cancellation Policy</h1>
            <h2 className="terms-headings">Refund Policy:</h2>
            <ul>
              <li className="terms-para">
                We strive to ensure your complete satisfaction with every
                purchase made from Avakaaya Mart Pickles. In the unlikely event
                that you are not entirely satisfied with your purchase, we're
                here to help.
              </li>
              <li className="terms-para">
                Refunds will be issued based on the original form of payment.
                Please allow 5 business days for the refund to be processed.
              </li>
              <li className="terms-para">
                To request a refund, please contact our customer service team
                within 2 days of receiving your order. Provide your order number
                and reason for the return. We will guide you through the
                process.
              </li>
            </ul>
            <h2 className="terms-headings">Cancellation Policy:</h2>
            <ul>
              <li className="terms-para">
                Orders can be canceled without any penalty before they are
                shipped. Once the order has been shipped, it cannot be canceled.
              </li>
              <li className="terms-para">
                To cancel an order, please contact our customer service team at
                as soon as possible with your order number.
              </li>
              <li className="terms-para">
                If the order has already been shipped, you will need to initiate
                a return once you receive the package.
              </li>
            </ul>
            <h2 className="terms-headings">Exceptions:</h2>
            <ul>
              <li className="terms-para">
                Customized or personalized items may not be eligible for returns
                or refunds unless they arrive damaged or defective.
              </li>
              <li className="terms-para">
                Perishable goods such as food items may not be eligible for
                returns unless they are received in a damaged or defective
                condition.
              </li>
            </ul>
            <h2 className="terms-headings">Damaged or Defective Items:</h2>
            <ul>
              <li className="terms-para">
                If you receive a damaged or defective item, please contact us
                immediately with photos of the product and packaging. We will
                arrange for a replacement or refund as applicable.
              </li>
            </ul>
            <h2 className="terms-headings">Contact Us:</h2>
            <p className="terms-para">
              If you have any questions about our refund and cancellation
              policy, please contact us!.
            </p>
          </div>
        </div>
      </div>
      <div>
        <BottomNavbar/>
      </div>
    </>
  );
};

export default RefundandCancellation;
