import React from "react";
import BottomNavbar from "../../components/BottomNavbar";

const PrivacyPolicy = () => {
  return (
    <>
      <div className="container-fluid">
        <div className="container">
            <div>
                <h1 className="terms-main-heading">Privacy Policy</h1>
                <p className="terms-para">AvakaayaMart is committed to ensuring the privacy and security of your personal information. This Privacy Policy outlines how we collect, use, and safeguard your data when you visit our website or use our services.</p>
                
            </div>
            <div>
                <h2 className="terms-headings">Information Collection:</h2>
                <ul><li className="terms-para">We may collect personal information such as your name, email address and phone number when you place an order or register an account on our website.</li>
               </ul>
               <h2  className="terms-headings">Use of Information:</h2>
               <ul>
                <li className="terms-para">We use your personal information to process your orders, communicate with you regarding your purchases, and provide customer support.</li>
                <li className="terms-para">Your email address may be used to send you promotional offers, newsletters, and updates about our products and services. You can opt-out of receiving these communications at any time.</li>
               </ul>
               <h2  className="terms-headings">Data Security:</h2>
               <ul>
                <li className="terms-para">We employ industry-standard security measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction.</li>
                <li className="terms-para">We restrict access to your personal information to authorized personnel only, who are required to keep your information confidential.</li>
               </ul>
               <h2  className="terms-headings">Third-Party Disclosure:</h2>
               <ul>
                <li className="terms-para">We do not sell, trade, or otherwise transfer your personal information to third parties without your consent, except as required by law or to fulfill your orders .</li>
                <li className="terms-para">Our website may contain links to third-party websites, and our Privacy Policy does not apply to their practices. We encourage you to review the privacy policies of these websites before providing any personal information.</li>
               </ul>
               <h2  className="terms-headings">Children's Privacy:</h2>
               <ul>
                <li className="terms-para">Our website is not intended for children under the age of 13. We do not knowingly collect personal information from children, and if we become aware that we have inadvertently collected such information, we will take steps to delete it.</li>
               </ul>
               <h2  className="terms-headings">Changes to Privacy Policy:</h2>
               <ul>
                <li className="terms-para">We reserve the right to update or modify this Privacy Policy at any time. Any changes will be effective immediately upon posting on our website. We encourage you to review this Privacy Policy periodically for any updates.</li>
               </ul>
               <p className="terms-para">By using our website or services, you consent to the terms of this Privacy Policy. If you have any questions or concerns about our privacy practices, please contact us.</p>
            </div>

        </div>

      </div>
      <div>
        <BottomNavbar/>
      </div>
    </>
  );
};

export default PrivacyPolicy;
