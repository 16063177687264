import React, { useEffect, useMemo, useState } from "react";
import axios from "axios";
import apiUrl from "../config/config.js";
import Pickles from "../components/Data.js";
import { FaLocationDot } from "react-icons/fa6";
import { GrFormNext } from "react-icons/gr";
import { IoChevronBack } from "react-icons/io5";

const Allorders = () => {
  const [ordersList, setOrdersList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [selectedDate, setSelectedDate] = useState("");
  const [orderId, setOrderId] = useState(""); 

  useEffect(() => {
    getAllOrders(selectedDate, orderId);
  }, [selectedDate, orderId]);

  const getAllOrders = async (date, orderId) => {
    try {
      let url = apiUrl + "/v4/order/allorders";
      let config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      if (date) {
        url += `?date=${date}`;
      }
      const res = await axios.get(url, config);
      setOrdersList(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const reversedOrderlist = useMemo(
    () => ordersList.slice().reverse(),
    [ordersList]
  );

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const currentItems = reversedOrderlist
    .filter((order) => {
      if (!selectedDate && !orderId) return true;
      const orderDate = new Date(order.createdAt)
        .toLocaleDateString("en-IN", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        })
        .split("/")
        .reverse()
        .join("-");
      const orderIdLowerCase = order.orderId.toLowerCase();
      const searchOrderIdLowerCase = orderId.toLowerCase();
      return (!selectedDate || orderDate === selectedDate) &&
             (!orderId || orderIdLowerCase.includes(searchOrderIdLowerCase));
    })
    .slice(indexOfFirstItem, indexOfLastItem);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(reversedOrderlist.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo(0, 0);
  };

  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(parseInt(e.target.value));
    setCurrentPage(1);
  };

  const handleDateChange = (e) => {
    setSelectedDate(e.target.value);
  };

  const handleOrderIdChange = (e) => {
    setOrderId(e.target.value);
  };
  const handleReset = () => {
    setSelectedDate("");
    setOrderId("");
    setOrdersList(currentItems  ); 
  };

  return (
    <div className="container-fluid">
      {/* <div>
        <h3 className="order-history-main-heading">Welcome to Aavakaaya Mart</h3>
      </div> */}
      <h5 className="order-history-main-heading">All Orders</h5>

      <div className="dropdown-alloders">
        <div>
          <label htmlFor="searchDate" className="items-pages">Search by Date:</label>
          <input
            className="search-date"
            type="date"
            id="searchDate"
            value={selectedDate}
            onChange={handleDateChange}
          />
        </div>
        <div>
          <label htmlFor="searchOrderId" className="items-pages">Search by Order ID:</label>
          <input
            className="search-date"
            type="text"
            id="searchOrderId"
            value={orderId}
            onChange={handleOrderIdChange}
          />
        </div>
        <div>
          <label className="items-pages">Items Per Page:</label>
          <select
            id="itemsPerPage"
            className="allorders-select"
            value={itemsPerPage}
            onChange={handleItemsPerPageChange}
          >
            
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={30}>30</option>
            <option value={50}>50</option>
          </select>
        </div>
        <div>
          <button className="admin-reser-btn" onClick={handleReset}>
            Reset
          </button>
        </div>
      </div>

      <table className="table table-hover table-main">
        <thead className="table-haeding">
          <tr className="">
            <th className="table-heading">S.No.</th>
            <th className="table-heading">Date</th>
            <th className="table-heading">OrderId</th>
            <th className="table-heading">Customer Name</th>
            <th className="table-heading">Phone Number</th>
            <th className="table-heading">Order Details</th>
            <th className="table-heading">Total Amount</th>
            <th className="table-heading">Address</th>
          </tr>
        </thead>
        <tbody>
          {currentItems.length === 0 && (
            <tr>
              <td colSpan="8" className="text-center">No orders were placed .</td>
            </tr>
          )}
          {currentItems.map((order, index) => {
            const orderIndex = indexOfFirstItem + index;
            const {
              totalAmount,
              createdAt,
              user,
            } = order;
            const createdAtDate = new Date(createdAt);
            const formattedCreatedAt = createdAtDate.toLocaleDateString(
              "en-IN",
              { day: "2-digit", month: "2-digit", year: "numeric" }
            );
            const parsedProducts = JSON.parse(order.products);
            const parsedAddress = JSON.parse(order.address);

            return (
              <tr key={orderIndex}>
                <td>{orderIndex + 1}</td>
                <td className="order-text-names">{formattedCreatedAt}</td>
                <td className="order-text-names">{order?.orderId}</td>
                <td className="order-text-names">{`${user?.name}`}</td>
                <td className="order-text-address">
                  {parsedAddress.mobile ? parsedAddress.mobile : user?.mobile}
                </td>
                <td>
                  <div>
                    {parsedProducts.map((items, index) => {
                      const productDetails = Pickles.find(
                        (pickle) => pickle.id === items.productId
                      );

                      return (
                        <div className="order-cards" key={index}>
                          <div>
                            {productDetails && (
                              <div>
                                <p className="order-text-names">
                                  {productDetails.name}({items.size})
                                </p>
                                <p className="order-text-names">
                                  Quantity: {items.quantity}
                                </p>
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </td>
                <td className="order-text-names">₹{totalAmount}</td>
                <td>
                  <div className="order-text-names">
                    <span className="order-text-names">{parsedAddress?.streetAddress},</span>{" "}
                    <span className="order-text-names">{parsedAddress?.city}</span>,{" "}
                    <span className="order-text-names">{parsedAddress?.district}</span>,{" "}
                    <span className="order-text-names">{parsedAddress?.state}, </span>{" "}
                    <span className="order-text-names">{parsedAddress?.country},{" "}
                    </span>
                    <span className="order-text-names">{parsedAddress?.pincode},</span>
                    <p className="order-text-names">
                      Phone Number: {parsedAddress?.mobile || user?.mobile}
                    </p>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      <div className="pagination">
        <button
          className="page-arrow"
          onClick={() => paginate(currentPage - 1)}
          disabled={currentPage === 1}
        >
          <IoChevronBack className="back-icon" />
        </button>
        {pageNumbers.map((number) => (
          <button
            key={number}
            className={`page-number ${
              currentPage === number ? "shoppingpageActive" : ""
            }`}
            onClick={() => paginate(number)}
          >
            {number}
          </button>
        ))}
        <button
          className="page-arrow"
          onClick={() => paginate(currentPage + 1)}
          disabled={currentPage === Math.ceil(reversedOrderlist.length / itemsPerPage)}
        >
          <GrFormNext className="next-icon" />
        </button>
      </div>
    </div>
  );
};

export default Allorders;
