import React, { useContext, useEffect, useState } from "react";
import "../css/login.css";
import { Await, Link, useNavigate } from "react-router-dom";
import { FaRegEyeSlash, FaEye } from "react-icons/fa";
import axios from "axios";
import apiUrl from "../../config/config";
import { NavContext } from "../../context/NavProvider";
import { toast, ToastContainer } from "react-toastify";
const Login = ({ handleLogin }) => {
  const [isFieldFocused, setIsFieldFocused] = useState(false);
  const [formError, setFormError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const navigate = useNavigate();
  const togglePasswordVisibility = () => {
    setIsFieldFocused(!isFieldFocused);
  };
  const { UserProfile } = useContext(NavContext);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    if (name === "email" && emailError) {
      setEmailError("");
    }
    if (name === "password" && passwordError) {
      setPasswordError("");
    }
    if (name === "password") {
      const trimmedValue = value.replace(/^\s+/g, "");
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: trimmedValue,
      }));
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (!formData.email.trim()) {
      setEmailError("Please enter your email.");
      return;
    }
    if (!formData.password.trim()) {
      setPasswordError("Please enter your password.");
      return;
    }

    try {
      const response = await axios.post(apiUrl + "/v1/user/login", formData ,{
      headers: {
        "Content-Type": "application/json",
      },
   
    })
      if (response.status == 200) {
        localStorage.setItem("userId", response.data.token);
        toast.success(response.data.message);
        await handleLogin();
        await UserProfile();
        setTimeout(()=>{
          navigate("/shopingpage"); 
          window.location.reload()
        },)
       
     
      }
    } catch (error) {
      setFormError(error.response?.data.message);
      console.error("Login failed:", error);
    }
  };
  const handleForgotPasswordClick = () => {
    navigate("/forgotpassword");
  };
  return (
    <div>
      <ToastContainer position="top-left"/>
      <div className="form-container">
        <form onSubmit={handleFormSubmit} className="login-form-data">
          <h4 className="login-heading">Sign In</h4>
          <div className="input-group">
            <div className="login-inputs">
              <input
                type="text"
                name="email"
                placeholder="Email"
                className="form-control login-name"
                value={formData.email}
                onChange={handleChange}
              />
              {emailError && <p className="error-message">{emailError}</p>}
              <div className="input-container">
                <input
                  type={isFieldFocused ? "text" : "password"}
                  name="password"
                  placeholder="Password"
                  className="form-control login-name"
                  value={formData.password}
                  onChange={handleChange}
                />

                {isFieldFocused ? (
                  <FaRegEyeSlash
                    onClick={togglePasswordVisibility}
                    className="eye-icon"
                  />
                ) : (
                  <FaEye
                    onClick={togglePasswordVisibility}
                    className="eye-icon"
                  />
                )}
              </div>
              {passwordError && (
                <p className="error-message">{passwordError}</p>
              )}
              {formError && <p className="error-message">{formError}</p>}
              <div className="remember-me">
                
                <p
                  className="login-remember"
                  onClick={handleForgotPasswordClick}
                >
                  Forgot Password
                </p>
              </div>
            </div>
            <div className="mt-5">
              <button type="submit" className="login-btn">
                Login
              </button>
            </div>
            <div className="navigate-login login-remember">
              <span>Don't have an account? </span>{" "}
              <Link to="/register" className="navigate-register">
                Register
              </Link>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
