import React, { useEffect, useState } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Home from "./pages/components/Home";
import Cart from "./pages/components/Cart";
import Login from "./Forms/components/Login";
import Register from "./Forms/components/Register";
import ForgotPassword from "./Forms/components/ForgotPassword";
import OrderHistory from "./pages/components/OrderHistory";
import Shopingpage from "./pages/components/Shopingpage";
import BillingPage from "./pages/components/Billingpage";
import Profile from "./pages/components/Profile";
import ViewProduct from "./pages/components/ViewProduct";
import Allorders from "./admin/Allorders";
import Footer from "./Forms/components/Footer";
import Navbar from "./components/Navbar";
import TermsAndConditions from "./pages/components/TermsAndConditions";
import DeliveryandShippingPolicy from "./pages/components/DeliveryandShippingPolicy";
import PrivacyPolicy from "./pages/components/PrivacyPolicy";
import RefundandCancellation from "./pages/components/RefundandCancellation";

function App() {
  const location = useLocation();
  const excludedPaths = ["/admin/allorders"];
  const isExcluded = excludedPaths.includes(location.pathname);

  const { pathname } = useLocation();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const checkLoggedIn = async () => {
      const userProfileId = localStorage.getItem("userId");
      setIsLoggedIn(!!userProfileId);
      setLoading(false);
    };
    checkLoggedIn();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const handleLogOut = async () => {
    localStorage.removeItem("userId");
    setIsLoggedIn(false);
  };

  const handleLogin = async () => {
    const userProfileId = localStorage.getItem("userId");
    setIsLoggedIn(!!userProfileId);
  };

  if (loading) {
    return null;
  }

  const ProtectedRoute = ({ element }) => {
    useEffect(() => {
      if (!isLoggedIn) {
        navigate("/login");
      }
    }, [navigate, isLoggedIn]);
    return isLoggedIn ? element : null;
  };

  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <div className="">
        {!isExcluded && (
          <div className="sticky-top bg-light">
            <Navbar
              scrollToSection={scrollToSection}
              handleLogin={isLoggedIn}
            />
          </div>
        )}

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/cart" element={<ProtectedRoute element={<Cart />} />} />
          <Route path="/login" element={<Login handleLogin={handleLogin} />} />
          <Route path="/register" element={<Register />} />
          <Route path="/forgotpassword" element={<ForgotPassword />} />
          <Route
            path="/myorders"
            element={<ProtectedRoute element={<OrderHistory />} />}
          />

          <Route
            path="/shopingpage"
            element={<ProtectedRoute element={<Shopingpage />} />}
          />
          <Route path="/terms-conditions" element={<TermsAndConditions />} />
          <Route path="/deliveryandshippingpolicy" element={<DeliveryandShippingPolicy />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route path="/refundandcancellationpolicy" element={<RefundandCancellation />} />
          <Route path="/billing" element={<BillingPage />} />
          <Route
            path="/profile"
            element={
              <ProtectedRoute
                element={<Profile handleLogOut={handleLogOut} />}
              />
            }
          />
          <Route
            path="/viewproduct/:id"
            element={<ProtectedRoute element={<ViewProduct />} />}
          />
          <Route path="/admin/allorders" element={<Allorders />} />
        </Routes>

        {!isExcluded && (
          <div id="contactUs">
            <Footer />
          </div>
        )}
      </div>
    </>
  );
}

export default App;
