import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter, HashRouter } from "react-router-dom";
import { NavProvider } from "../src/context/NavProvider";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <NavProvider>
    <HashRouter>
      <App />
    </HashRouter>
  </NavProvider>
);


