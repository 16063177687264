import React, { useContext, useEffect, useRef, useState } from "react";
import { BiHeart, BiLogOut } from "react-icons/bi";
import { BsHandbag } from "react-icons/bs";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { NavContext } from "../../context/NavProvider";
import apiUrl from "../../config/config";
import "../css/profile.css";
import BottomNavbar from "../../components/BottomNavbar";

const Profile = ({ handleLogOut }) => {
  const { myprofile, UserProfile } = useContext(NavContext);
  const [profileAdress, setProfileAddress] = useState([]);
  const fileInputRef = useRef(null);
  const [updateIndex, setUpdateIndex] = useState(null);
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [mobileError, setMobileError] = useState("");
  const [isEditMode, setIsEditMode] = useState(false);
  const [personalDetails, setPersonalDetails] = useState({
    name: "",
    email: "",
    mobile: "",
    image: null,
  });
  const [userDetails, setuserDetails] = useState({
    address: {
      streetAddress: "",
      country: "",
      state: "",
      district: "",
      city: "",
      pincode: "",
    },
  });
  const [addressErrors, setAddressErrors] = useState({
    streetAddress: "",
    country: "",
    state: "",
    district: "",
    city: "",
    pincode: "",
  });
  useEffect(() => {
    if (myprofile && myprofile.addresses && myprofile.addresses.length > 0) {
      setProfileAddress(myprofile.addresses[0]);
    } else {
      setProfileAddress("");
    }
  }, [myprofile]);
  const handleInputChange = (e, section) => {
    const { name, value } = e.target;
    if (section === "personal") {
      if (name === "name") {
        const trimmedValue = value.replace(/^\s+/g, "");
        const nonNumericValue = trimmedValue.replace(/\d/g, "");
        setPersonalDetails((prevDetails) => ({
          ...prevDetails,
          [name]: nonNumericValue,
        }));
      } else if (name === "email") {
       const trimmedValue = value.replace(/^\s+/g, "");
        setPersonalDetails((prevDetails) => ({
          ...prevDetails,
          [name]: trimmedValue,
        }));
      } else {
        setPersonalDetails((prevDetails) => ({
          ...prevDetails,
          [name]: value,
        }));
      }
      setEmailError("");
      setNameError("");
    } else if (section === "address") {
      if (
        name === "country" ||
        name === "state" ||
        name === "city" ||
        name === "district"
      ) {
        const trimmedValue = value.replace(/^\s+/g, "");
        const nonNumericValue = trimmedValue.replace(/\d/g, "");
        setuserDetails((prevDetails) => ({
          ...prevDetails,
          address: {
            ...prevDetails.address,
            [name]: nonNumericValue,
          },
        }));
      } else {
        setuserDetails({
          ...userDetails,
          address: {
            ...userDetails.address,
            [name]: value,
          },
        });
      }
      setAddressErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    }
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];

    setPersonalDetails({
      ...personalDetails,
      image: file,
    });
  };
  const savepersonalDetails = async (e) => {
    e.preventDefault();

    if (!personalDetails.name.trim()) {
      setNameError("Please enter a name");
      return;
    }

    const gmailRegex = /^[^\s@]+@gmail\.com$/i;
    const yahooRegex = /^[^\s@]+@yahoo\.com$/i;
    const hotmailRegex = /^[^\s@]+@hotmail\.com$/i;
    if (
      !gmailRegex.test(personalDetails.email) &&
      !yahooRegex.test(personalDetails.email) &&
      !hotmailRegex.test(personalDetails.email)
    ) {
      setEmailError(
        "Please enter a valid email address ending with gmail.com, yahoo.com, or hotmail.com."
      );
      return;
    }

    if (personalDetails.mobile.length < 10) {
      setMobileError("Mobile number must be at least 10 digits");
      return;
    }

    const formDataForApi = new FormData();
    for (let key in personalDetails) {
      formDataForApi.append(key, personalDetails[key]);
    }

    try {
      const res = await axios.post(
        `${apiUrl}/v1/user/updateUser/${myprofile.id}`,
        formDataForApi,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (res.status === 200) {
        setEmailError("");
        setMobileError("");
        setNameError("");
        toast.success(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const navigate = useNavigate();
  useEffect(() => {
    UserProfile();
  }, []);
  useEffect(() => {
    if (myprofile && myprofile.addresses) {
      setPersonalDetails((prevDetails) => ({
        ...prevDetails,
        name: myprofile.name || "",
        email: myprofile.email || "",
        mobile: myprofile.mobile || "",
        image: myprofile.image || null,
      }));

      setuserDetails({
        address: {
          streetAddress: myprofile.addresses[0]?.streetAddress || "",
          country: myprofile.addresses[0]?.country || "",
          state: myprofile.addresses[0]?.state || "",
          district: myprofile.addresses[0]?.district || "",
          city: myprofile.addresses[0]?.city || "",
          pincode: myprofile.addresses[0]?.pincode || "",
        },
      });
    }
  }, [myprofile]);

  const addNewBillingAddress = async (e) => {
    e.preventDefault();
    const { streetAddress, country, state, city, district, pincode } =
      userDetails.address;
    const newErrors = {};
    if (!streetAddress) {
      newErrors.streetAddress = "Please enter your streetAddress.";
    }
    if (!country) {
      newErrors.country = "Please enter your country.";
    }

    if (!state) {
      newErrors.state = "Please enter your state.";
    }
    if (!city) {
      newErrors.city = "Please enter your city.";
    }
    if (!district) {
      newErrors.district = "Please enter your district.";
    }
    if (!pincode) {
      newErrors.pincode = "Please confirm your pincode.";
    }
    if (pincode.trim().length < 6) {
      newErrors.pincode = "pincode must be at  6 characters .";
    }

    if (Object.keys(newErrors).length > 0) {
      setAddressErrors(newErrors);
      return;
    }

    try {
      const res = await axios.post(
        apiUrl + `/v3/address/addaddress/${myprofile.id}`,
        userDetails,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (res.status === 200) {
        UserProfile();
        toast.success(res.data.message);
        // window.location.reload();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleClickChooseImage = () => {
    fileInputRef.current.click();
  };

  const Logout = async () => {
    localStorage.removeItem("userId");
    await handleLogOut();
    await navigate("/");
    await UserProfile();
    window.location.reload();
  };

  const updateAddress = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post(
        `${apiUrl}/v3/address/updateaddress/${myprofile.id}`,
        {
          updateIndex: myprofile?.addresses[0]?.id,
          userDetails,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (res.status === 200) {
        UserProfile();
        toast.success(res.data.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <ToastContainer position="top-left" />
      <div className="container-fluid">
        <div className="container">
          <div className="account-details-main">
            <div className="account-details">
              <div className="profile-side-bar">
                <div className="account-details-left">
                  <h3 className="navigation-heading">Navigation</h3>
                  <Link className="profile-cart" to="/myorders">
                    <p className="navavigation-icons">
                      <BiHeart className="icons" />{" "}
                      <span className="profile-orderlist">My Orders</span>
                    </p>
                  </Link>
                  <Link to="/cart" className="profile-cart">
                    <p className="navavigation-icons">
                      {" "}
                      <BsHandbag className="icons" />{" "}
                      <span className="profile-orderlist">Shopping Cart</span>
                    </p>
                  </Link>

                  <p className="navavigation-icons" onClick={Logout}>
                    <BiLogOut className="icons" />{" "}
                    <span className="profile-orderlist">Log-out</span>
                  </p>
                </div>
              </div>
              <div className="profile-sub-container">
                <div className="account-details-right">
                  <div className="account-details-right-main">
                    <h3 className="account-details-heading">
                      Account Settings
                    </h3>
                    <hr className="account-line" />
                    <div className="account-details-right-inner">
                      <div className="account-details-right-left-part">
                        <form className="form-names">
                          <div className="mb-3">
                            <label
                              htmlFor="firstName"
                              className="form-label-text"
                            >
                              Name
                            </label>
                            <input
                              type="text"
                              className="form-control form-inp"
                              id="firstName"
                              name="name"
                              value={personalDetails.name}
                              placeholder="Enter Name"
                              onChange={(e) => handleInputChange(e, "personal")}
                            />
                            {nameError && (
                              <div className="error-message">{nameError}</div>
                            )}
                          </div>
                          <div className="mb-3">
                            <label htmlFor="email" className="form-label-text">
                              Email
                            </label>
                            <input
                              type="email"
                              className="form-control form-inp"
                              id="email"
                              name="email"
                              value={personalDetails.email}
                              placeholder="Email Address"
                              onChange={(e) => handleInputChange(e, "personal")}
                            />
                            {emailError && (
                              <div className="error-message">{emailError}</div>
                            )}
                          </div>
                          <div className="mb-3">
                            <label htmlFor="phone" className="form-label-text">
                              Phone Number
                            </label>
                            <input
                              className="form-control form-inp"
                              id="phone"
                              name="mobile"
                              value={personalDetails.mobile}
                              placeholder="Phone number"
                              onChange={(e) => handleInputChange(e, "personal")}
                              maxLength={10}
                              minLength={10}
                              onInput={(e) => {
                                const numericValue = e.target.value
                                  .replace(/\D/g, "")
                                  .slice(0, 10);
                                handleInputChange({
                                  target: {
                                    name: "mobile",
                                    value: numericValue,
                                  },
                                });
                              }}
                              onKeyDown={(e) => {
                                if (
                                  !(
                                    e.key === "Backspace" || e.key === "Delete"
                                  ) &&
                                  !/[\d]/.test(e.key)
                                ) {
                                  e.preventDefault();
                                }
                              }}
                              type="text"
                            />
                            {mobileError && (
                              <div className="error-message">{mobileError}</div>
                            )}
                          </div>
                          <div className="account-details-right-bottom">
                            <button
                              className="save-changes-btn"
                              onClick={savepersonalDetails}
                            >
                              Save Changes
                            </button>
                          </div>
                        </form>
                      </div>
                      <div className="account-details-right-right-part">
                        <div>
                          <label htmlFor="fileInput">
                            <div>
                              {personalDetails.image ? (
                                <img
                                  src={
                                    typeof personalDetails.image === "string"
                                      ? personalDetails.image
                                      : URL.createObjectURL(
                                          personalDetails.image
                                        )
                                  }
                                  alt=""
                                  style={{
                                    width: "180px",
                                    height: "180px",
                                    borderRadius: "50%",
                                  }}
                                />
                              ) : (
                                <img
                                  src="../images/emptydp.jpeg"
                                  alt="no image found"
                                  style={{
                                    width: "180px",
                                    height: "180px",
                                    borderRadius: "50%",
                                  }}
                                />
                              )}
                            </div>
                          </label>
                          <input
                            id="fileInput"
                            type="file"
                            accept="image/*"
                            style={{ display: "none" }}
                            onChange={handleFileUpload}
                            ref={fileInputRef}
                          />
                        </div>
                        <button
                          className="choose-imgs-btn"
                          onClick={handleClickChooseImage}
                        >
                          Choose Image
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="account-details-main">
                  <div className="account-details-right-below">
                    <div className="account-details-right-main">
                      <h3 className="account-details-heading">
                        Billing Address
                      </h3>
                      <hr className="account-line" />
                      <div className="account-details-right-left-par">
                        <form>
                          <div>
                            <div className="mb-3">
                              <label
                                htmlFor="streetAddress"
                                className="form-label-text"
                              >
                                Street Address
                              </label>
                              <input
                                type="text"
                                className="form-control form-inp-billings address"
                                id="streetAddress"
                                name="streetAddress"
                                value={userDetails.address.streetAddress}
                                placeholder="House no / street name / village / maandal / district"
                                onChange={(e) =>
                                  handleInputChange(e, "address")
                                }
                              />
                              {addressErrors.streetAddress && (
                                <div className="error-message">
                                  {addressErrors.streetAddress}
                                </div>
                              )}
                            </div>
                            <div className="country-state-zip">
                              <div className="mb-3 billings">
                                <label
                                  htmlFor="state"
                                  className="form-label-text"
                                >
                                  Town / City
                                </label>
                                <input
                                  className="form-control form-inp-billings"
                                  id="city"
                                  name="city"
                                  placeholder="city"
                                  value={userDetails.address.city}
                                  onChange={(e) =>
                                    handleInputChange(e, "address")
                                  }
                                />
                                {addressErrors.city && (
                                  <div className="error-message">
                                    {addressErrors.city}
                                  </div>
                                )}
                              </div>
                              <div className="mb-3 billings">
                                <label
                                  htmlFor="district"
                                  className="form-label-text"
                                >
                                  District
                                </label>
                                <input
                                  className="form-control form-inp-billings"
                                  id="district"
                                  name="district"
                                  placeholder="district"
                                  value={userDetails.address.district}
                                  onChange={(e) =>
                                    handleInputChange(e, "address")
                                  }
                                />
                                {addressErrors.district && (
                                  <div className="error-message">
                                    {addressErrors.district}
                                  </div>
                                )}
                              </div>

                              <div className="mb-3 billings">
                                <label
                                  htmlFor="state"
                                  className="form-label-text"
                                >
                                  State
                                </label>
                                <select
                                  className="form-control top-control"
                                  id="state"
                                  name="state"
                                  value={userDetails.address.state}
                                  onChange={(e) =>
                                    handleInputChange(e, "address")
                                  }
                                >
                                  <option value="">Select</option>
                                  <option value="Andhra Pradesh">
                                    Andhra Pradesh
                                  </option>
                                  <option value="Arunachal Pradesh">
                                    Arunachal Pradesh
                                  </option>
                                  <option value="Assam">Assam</option>
                                  <option value="Bihar">Bihar</option>
                                  <option value="Chhattisgarh">
                                    Chhattisgarh
                                  </option>
                                  <option value="Goa">Goa</option>
                                  <option value="Gujarat">Gujarat</option>
                                  <option value="Haryana">Haryana</option>
                                  <option value="Himachal Pradesh">
                                    Himachal Pradesh
                                  </option>
                                  <option value="Jharkhand">Jharkhand</option>
                                  <option value="Karnataka">Karnataka</option>
                                  <option value="Kerala">Kerala</option>
                                  <option value="Madhya Pradesh">
                                    Madhya Pradesh
                                  </option>
                                  <option value="Maharashtra">
                                    Maharashtra
                                  </option>
                                  <option value="Manipur">Manipur</option>
                                  <option value="Meghalaya">Meghalaya</option>
                                  <option value="Mizoram">Mizoram</option>
                                  <option value="Nagaland">Nagaland</option>
                                  <option value="Odisha">Odisha</option>
                                  <option value="Punjab">Punjab</option>
                                  <option value="Rajasthan">Rajasthan</option>
                                  <option value="Sikkim">Sikkim</option>
                                  <option value="Tamil Nadu">Tamil Nadu</option>
                                  <option value="Telangana">Telangana</option>
                                  <option value="Tripura">Tripura</option>
                                  <option value="Uttar Pradesh">
                                    Uttar Pradesh
                                  </option>
                                  <option value="Uttarakhand">
                                    Uttarakhand
                                  </option>
                                  <option value="West Bengal">
                                    West Bengal
                                  </option>
                                </select>
                                {addressErrors.state && (
                                  <div className="error-message">
                                    {addressErrors.state}
                                  </div>
                                )}
                              </div>
                              <div className="mb-3 billings">
                                <label
                                  htmlFor="pincode"
                                  className="form-label-text"
                                >
                                  Pin Code
                                </label>
                                <input
                                  type="text"
                                  className="form-control form-inp-billings"
                                  id="pincode"
                                  name="pincode"
                                  value={userDetails.address.pincode}
                                  placeholder="Pin Code"
                                  onChange={(e) =>
                                    handleInputChange(e, "address")
                                  }
                                  maxLength={6}
                                  minLength={6}
                                  onInput={(e) => {
                                    const numericValue = e.target.value
                                      .replace(/\D/g, "")
                                      .slice(0, 10);
                                    handleInputChange({
                                      target: {
                                        name: "mobile",
                                        value: numericValue,
                                      },
                                    });
                                  }}
                                  onKeyDown={(e) => {
                                    if (
                                      !(
                                        e.key === "Backspace" ||
                                        e.key === "Delete"
                                      ) &&
                                      !/[\d]/.test(e.key)
                                    ) {
                                      e.preventDefault();
                                    }
                                  }}
                                />
                                {addressErrors.pincode && (
                                  <div className="error-message">
                                    {addressErrors.pincode}
                                  </div>
                                )}
                              </div>
                              <div className="mb-3 billings">
                                <label
                                  htmlFor="countryRegion"
                                  className="form-label-text"
                                >
                                  Country
                                </label>
                                <select
                                  className="form-control form-inp-billings"
                                  id="countryRegion"
                                  name="country"
                                  value={userDetails.address.country}
                                  onChange={(e) =>
                                    handleInputChange(e, "address")
                                  }
                                >
                                  <option value="">Select</option>
                                  <option value="India">India</option>
                                </select>
                                {addressErrors.country && (
                                  <div className="error-message">
                                    {addressErrors.country}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="account-details-right-bottom">
                            {profileAdress ? (
                              <button
                                className="save-changes-btn-addess"
                                onClick={updateAddress}
                              >
                                Save Changes
                              </button>
                            ) : (
                              <button
                                className="save-changes-btn-addess"
                                onClick={addNewBillingAddress}
                              >
                                Add Address
                              </button>
                            )}
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <BottomNavbar />
      </div>
    </>
  );
};

export default Profile;
