import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import "./css/bottomNavbar.css";
import { SlHandbag } from "react-icons/sl";
import { BsPerson } from "react-icons/bs";
import { FiHome } from "react-icons/fi";
import { FaRegHeart } from "react-icons/fa";
import { NavContext } from "../context/NavProvider";
const BottomNavbar = () => {
  const { myprofile, UserProfile } = useContext(NavContext);
  return (
    <div>
      <div className="">
        <div className="">
          <div className="mobile-navbar d-flex justify-content-around">
            <NavLink
              to="/"
              className="mobile-navbar-links nav-link"
              activeClassName="active"
            >
              <div className="mobile-navbar-icons">
                <FiHome size={25} />
                <p>Home</p>
              </div>
            </NavLink>
            <NavLink
              to="/shopingpage"
              className="mobile-navbar-links nav-link"
              activeClassName="active"
            >
              <div className="mobile-navbar-icons ">
                <FaRegHeart size={25} />
                <p>Shoping</p>
              </div>
            </NavLink>
            <NavLink
              to="/cart"
              className="mobile-navbar-links nav-link"
              activeClassName="active"
            >
              <div className="mobile-navbar-icons cart-cout">
                <SlHandbag size={25} />
                {myprofile?.carts && myprofile.carts?.length > 0 && (
                  <div className="cart-item-count-mobile">
                    {myprofile?.carts?.length}
                  </div>
                )}
                <p>Cart</p>
              </div>
            </NavLink>
            <NavLink
              to="/profile"
              className="mobile-navbar-links nav-link"
              activeClassName="active"
            >
              <div className="mobile-navbar-icons">
                <BsPerson size={25} />
                <p>Profile</p>
              </div>
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BottomNavbar;
