import React, { useEffect, useState } from "react";
import "../css/login.css";
import { Link, useNavigate } from "react-router-dom";
import { FaRegEyeSlash, FaEye } from "react-icons/fa";
import axios from "axios";
import apiUrl from "../../config/config";

const ForgotPassword = ({ handleLogin }) => {
  const [isFieldFocused, setIsFieldFocused] = useState(false);
  const [formError, setFormError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const navigate = useNavigate();
  const togglePasswordVisibility = () => {
    setIsFieldFocused(!isFieldFocused);
  };

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    if (name === "email" && emailError) {
        setEmailError("");
      }
      if (name === "password" && passwordError) {
        setPasswordError("");
      }
    if (name === "password") {
      const trimmedValue = value.replace(/^\s+/g, "");
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: trimmedValue,
      }));
    }

  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (!formData.email.trim()) {
        setEmailError("Please enter your email.");
        return;
      }
      if (!formData.password.trim()) {
        setPasswordError("Please enter your password.");
        return;
      }
    try {
      const response = await axios.post(apiUrl + "/v1/user/resetpassword", formData  , {
        headers: {
          "Content-Type": "application/json",
        },
      
      })
   
      if (response.status == 200) {
        navigate("/login");
      } else {
        
        console.error("Password reset failed:", response.data);
        setFormError("Password reset failed. Please try again.");
      }
    } catch (error) {
      
      console.error("Reset Password failed:", error);
      setFormError(error.response.data.message);
    }
  };

  return (
    <div className="form-container">
      <form onSubmit={handleFormSubmit} className="login-form-data">
        <h4 className="login-heading">Reset Password</h4>
        <div className="input-group">
          <div className="login-inputs">
            <input
              type="text"
              name="email"
              placeholder="Email"
              className="form-control login-name"
              value={formData.email}
              onChange={handleChange}
            />
               {emailError && <p className="error-message">{emailError}</p>}
            <div className="input-container">
              <input
                type={isFieldFocused ? "text" : "password"}
                name="password"
                placeholder="Enter new Password"
                className="form-control login-name"
                value={formData.changepassword}
                onChange={handleChange}
              />
              {isFieldFocused ? (
                <FaRegEyeSlash
                  onClick={togglePasswordVisibility}
                  className="eye-icon"
                />
              ) : (
                <FaEye
                  onClick={togglePasswordVisibility}
                  className="eye-icon"
                />
              )}
            </div>
            {passwordError && <p className="error-message">{passwordError}</p>}
            {formError && <p className="error-message">{formError}</p>}
          </div>
          <div className="mt-5">
            <button type="submit" className="login-btn">
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ForgotPassword;
