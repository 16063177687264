import React, { useContext, useEffect, useMemo, useState } from "react";
import { CurrencyRupee } from "react-bootstrap-icons";
// import Pickle from "../../components/Data.json";
import "../css/orderHistory.css";

import { NavContext } from "../../context/NavProvider";
import Pickles from "../../components/Data.js";
import { FaLocationDot } from "react-icons/fa6";
import BottomNavbar from "../../components/BottomNavbar";
import { ToastContainer } from "react-toastify";

const OrderHistory = () => {
  const { myprofile, UserProfile } = useContext(NavContext);
  const [orderlist, setOrderList] = useState([]);
  const reversedOrderlist = useMemo(
    () => orderlist.slice().reverse(),
    [orderlist]
  );

  useEffect(() => {
    setOrderList(myprofile?.Orders || []);
    // UserProfile()
  }, [myprofile]);


  
  return (
    <>
      <ToastContainer />
      <div className="container-fluid order-history-main-container">
        <div className="container">
          <h5 className="order-history-main-heading">Order History</h5>
          { orderlist&& orderlist.length
            ? reversedOrderlist.map((order, orderIndex) => {
                const createdAtDate = new Date(order?.createdAt);
                const formattedCreatedAt = createdAtDate.toLocaleDateString(
                  "en-IN",
                  { day: "2-digit", month: "2-digit", year: "numeric" }
                );

                const parsedProducts = JSON.parse(order?.products);
               
                const parsedAddress = JSON.parse(order?.address);
              
         

                return (
                  <div
                    className="orderhistory-main-card-container"
                    key={orderIndex}
                  >
                    <div className="order-history-heading">
                      <p className="order-history-name-date">order Details</p>
                      <p className="order-history-name-date">order Id:{order?.orderId}</p>
                      <p className="order-history-name-date">
                        {formattedCreatedAt}
                      </p>
                    </div>
                    <div className="oderhistory-cart-main">
                      {Array.isArray(parsedProducts) && parsedProducts.map((items, index) => {
                        const productDetails = Pickles.find(
                          (pickle) => pickle.id === items.productId
                        );
      
                        const totalPrice =
                          items.quantity * parseFloat(items.price);
                        return (
                          <div className="order-cards" key={index}>
                            <div>
                              {productDetails && (
                                <div className="order-cart-container">
                                  <div>
                                    <img
                                      src={productDetails.imageUrl}
                                      className="ordercard-image"
                                      alt={`Deal ${index + 1}`}
                                    />
                                  </div>
                                  <div>
                                    <p className="order-history-product-name">
                                      {productDetails.name}
                                    </p>
                                    <span className="order-history-quantity">
                                      {items.price}
                                    </span>{" "}
                                    *{" "}
                                    <span className="order-history-quantity">
                                      {items.quantity} =
                                    </span>
                                    <span> ₹{totalPrice}</span>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    <div className="order-history-addess">
                      <p className="order-history-totalamout">
                        Total Amount : ₹{order?.totalAmount}
                      </p>
                      <span className="order-address">
                        <FaLocationDot className="order-location-icon" />{" "}
                        {parsedAddress?.streetAddress},
                      </span>
                      <span className="order-address">
                        {parsedAddress?.city},
                      </span>
                      <span className="order-address">
                        {parsedAddress?.district},
                      </span>
                      <span className="order-address">
                        {parsedAddress?.state},
                      </span>
                      <span className="order-address">
                        {parsedAddress?.country}
                      </span>
                      <span className="order-address">
                        {parsedAddress?.pincode},
                      </span>
                      <span>
                        <b>phone Number :</b> {parsedAddress?.mobile}.
                      </span>
                  
                    </div>
                  </div>
                );
              })
            : // Render a message if orderlist is empty or not an array
              "No order, Please order something"}
        </div>
      </div>
      <div>
        <BottomNavbar />
      </div>
    </>
  );
};

export default OrderHistory;
