import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "../css/home.css";
import { ArrowRight, CurrencyRupee } from "react-bootstrap-icons";
import { PiHandbagBold } from "react-icons/pi";
import { Link } from "react-router-dom";
import { SlHandbag } from "react-icons/sl";
import Reviews from "./Reviews";
import Pickle from "../../components/Data";
import logo from "../../Forms/components/avaakay-logo.png";
import BottomNavbar from "../../components/BottomNavbar";
const Home = () => {
  const navigate = useNavigate();
  const shopNow = () => {
    navigate("/shopingpage");
  };

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (location.state && location.state.scrollToContact) {
      scrollToContactUs();
    }
    if (location.state && location.state.scrollToAboutUs) {
      scrollToAboutUs();
    }
  }, [location]);

  const scrollToContactUs = () => {
    const contactUsSection = document.getElementById("contactUs");
    if (contactUsSection) {
      contactUsSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  const scrollToAboutUs = () => {
    const aboutUsSection = document.getElementById("aboutUs");
    if (aboutUsSection) {
      aboutUsSection.scrollIntoView({ behavior: "smooth" });
    }
  };
  const viewallClick = () => {
    navigate("/shopingpage");
  };

  return (
    <>
      {/* <div>
        <Navbar/>
      </div> */}
      <div className="container-fluid">
        <div className="container">
          <div className="container-hero-section mt-3 ">
            <div className="hero-section-heading  ">
              <span className="hero-section-welcome">
                Welcome to Avakaaya mart
              </span>
              <h1 className="hero-section-headind">
                A Legacy of Flavor, Delivered Fresh
              </h1>
              <p className="hero-section-sale">
                Sale up to<span className="hero-section-off">30% OFF</span>{" "}
              </p>

              <button className="hero-section-button " onClick={shopNow}>
                Shop now <ArrowRight />{" "}
              </button>
            </div>
            <div className="hero-section-image ">
              <div className=" hero-section-image-name ">
                <img
                  src="../images/hero_section_img.png"
                  alt="no image found"
                  className="home-page-image"
                />
              </div>
            </div>
          </div>
          <div className="hero-section-sub-container hero-sect-cont">
            <div className="hero-sec">
              <div className="hero-section-sub">
                <img
                  src="../images/Vector.png"
                  className="heros-img"
                  alt="no image found"
                />
                <div>
                  <p className="hero-section-sub-main-heading">Shipping</p>
                  <p className="hero-section-sub-heading">
                    shipping on all your order
                  </p>
                </div>
              </div>
              <div className="hero-section-sub">
                <img
                  src="../images/headphones.png"
                  className="heros-img"
                  alt="no image found"
                />
                <div>
                  <p className="hero-section-sub-main-heading">
                    Customer Support
                  </p>
                  <p className="hero-section-sub-heading">Support care</p>
                </div>
              </div>
            </div>
            <div className="hero-sec">
              <div className="hero-section-sub">
                <img
                  src="../images/shopping-bag.png"
                  className="heros-img"
                  alt="no image found"
                />
                <div>
                  <p className="hero-section-sub-main-heading">100% Secure</p>
                  <p className="hero-section-sub-heading">
                    We ensure your money is save
                  </p>
                </div>
              </div>
              <div className="hero-section-sub">
                <img
                  src="../images/package.png"
                  className="heros-img"
                  alt="no image found"
                />
                <div>
                  <p className="hero-section-sub-main-heading">
                    Money-Back Guarantee
                  </p>
                  <p className="hero-section-sub-heading">
                    30 Days Money-Back Guarantee
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="home-section-about-content" id="aboutUs">
            <div className="home-design-img">
              <img
                src="../images/design2.png"
                alt="no image found"
                className="design-img desg-img"
              />
            </div>
            <p className="text-center">
              {" "}
              <img src={logo} className="avaakay-logo" />
            </p>

            <div>
              <p className="desigin-content">
                Bringing traditional taste to your kitchen for coming years. we
                are backed by a couple of grannies, families in their decades of
                homemade pickling experience. Our pickles are 100% home-made
                with fresh and best quality ingredients which are all sourced
                from nature. We make each pickle in a way that, we are making it
                for our own family consumption. Best quality oils are used in
                our pickles they give the best taste that you experience by
                eating our pickles.
              </p>
              <p className="mt-3 desigin-content">
                All we can say is, Taste it once and you are sure to come back
                for more who can make pickles in such a way that, each bite of
                rice you take with our pickles will feast your taste buds We
                feel that everyone should taste our pickles at least once to
                tell this is true.
              </p>
            </div>
            <div className="home-design-img mt-3">
              <img
                src="../images/desigin1.png"
                alt="no image found"
                className="design-img desg-img"
              />
            </div>
          </div>

          <div className="mt-5">
            <div className="featured-heading">
              <p className="featured-products-heading ">Featured Products</p>
              <p className="product-rate-viewall" onClick={viewallClick}>
                View All
              </p>
            </div>
            <div className=" home-page-product-cards  ">
              {Pickle.slice(0, 4).map((val, index) => {
                return (
                  <div key={index} className="products">
                    <div
                      className="card product-card"
                      style={{ width: "14rem" }}
                    >
                      <Link to={`/viewproduct/${btoa(val.id)}`}>
                        <img
                          src={val.imageUrl}
                          className="card-img-top item-imges"
                          alt="..."
                        />

                        <div className="card-body card-body-container">
                          <div className="">
                            <h6 className="card-title">{val.name}</h6>
                            <p className="card-text">
                        
                            </p>
                          </div>
                          <div className="cart-button">
                            <button className="cart-button-1">
                              <SlHandbag />
                            </button>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          <div>
            <div className="hot-items gap-3 mt-5  ">
              <div className="all-items">
                <div className="product-rate-div">
                  <p className="product-rate-name">Hot Deals</p>
                  <p className="product-rate-viewall" onClick={viewallClick}>
                    View All
                  </p>
                </div>

                <div className="hotdeals-container">
                  {Pickle.slice(0, 3).map((deal, index) => (
                    <Link
                      to={`/viewproduct/${btoa(deal.id)}`}
                      key={index}
                      className="home-page-links"
                    >
                      <div className="hotdeals-card">
                        <img
                          src={deal.imageUrl}
                          className="hot-deals-img"
                          alt={`Deal ${index + 1}`}
                        />
                        <div>
                          <h5 className="card-title">{deal.name}</h5>
                          <p className="card-text">
                          </p>
                        </div>
                      </div>
                    </Link>
                  ))}
                </div>
              </div>
              <div className="all-items">
                <div className="product-rate-div">
                  <p className="product-rate-name">Best Seller</p>
                  <p className="product-rate-viewall" onClick={viewallClick}>
                    View All
                  </p>
                </div>
                <div className="hotdeals-container">
                  {Pickle.slice(6, 9).map((deal, index) => (
                    <Link
                      to={`/viewproduct/${btoa(deal.id)}`}
                      key={index}
                      className="home-page-links"
                    >
                      <div className="hotdeals-card">
                        <img
                          src={deal.imageUrl}
                          className="hot-deals-img"
                          alt={`Deal ${index + 1}`}
                        />
                        <div>
                          <h5 className="card-title">{deal.name}</h5>
                          <p className="card-text">
                            {/* <CurrencyRupee /> */}
                            <b>{deal.price}</b>
                          </p>
                        </div>
                      </div>
                    </Link>
                  ))}
                  
                </div>
              </div>
              <div className="all-items">
                <div className="product-rate-div">
                  <p className="product-rate-name">Top Rated</p>
                  <p className="product-rate-viewall" onClick={viewallClick}>
                    View All
                  </p>
                </div>
                <div className="hotdeals-container">
                  {Pickle.slice(3, 6).map((deal, index) => (
                    <Link to={`/viewproduct/${btoa(deal.id)}`}>
                      <div className="hotdeals-card" key={index}>
                        <img
                          src={deal.imageUrl}
                          className="hot-deals-img"
                          alt={`Deal ${index + 1}`}
                        />
                        <div>
                          <h5 className="card-title">{deal.name}</h5>
                          <p className="card-text">
                            {/* <CurrencyRupee /> */}
                            <b>{deal.price}</b>
                          </p>
                        </div>
                      </div>
                    </Link>
                  ))}
                </div>
              </div>
              <div className="">
                <div className="products-big-img">
                  <img
                    src="../images/product.png"
                    className="best-seles-masala"
                  />
                </div>
                <button
                  className="hero-section-button-best-sales "
                  onClick={shopNow}
                >
                  Shop now <ArrowRight />{" "}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-5 ">
        <Reviews />
      </div>


      <div className="sticky-bottom">
        <BottomNavbar />
      </div>
    </>
  );
};

export default Home;
