import React, { createContext, useEffect, useState } from "react";
import apiUrl from "../config/config";
import axios from "axios";
 
export const NavContext = createContext();
 
export const NavProvider = ({ children }) => {
  const [tabfilter, setTabFilter] = useState("");
  const [myprofile, setMyProfile] = useState([]);
  const token = localStorage.getItem("userId");
 
  const NavTabs = (category) => {
    setTabFilter(category);
  };
  const UserProfile = async () => {
    try {
      if (token) {
        const res = await axios.get(apiUrl + `/v1/user/myprofile`, {
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
         
        });
        setMyProfile(res.data);
      }
    } catch (err) {
      console.log("Error fetching user profile:", err);
    }
  };
 
  const contextValue = {
    tabfilter,
    NavTabs,
    UserProfile,
    myprofile,
    token,
  };
 
  useEffect(() => {
    UserProfile();
  }, [token]);
 
  return (
    <NavContext.Provider value={contextValue}>{children}</NavContext.Provider>
  );
};