import React from "react";

import "../css/footer.css";
import { PiPhoneCall } from "react-icons/pi";
import { TfiEmail } from "react-icons/tfi";
import { FaFacebookF } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { TfiTwitter } from "react-icons/tfi";
import { Link } from "react-router-dom";
import logo from "../components/avaakay-logo.png";
const Footer = () => {
  return (
    <div className="mt-5">
      <div className="container-fluid footer">
        <div className="container footer-cont">
          <div className="footer-container  ">
            <div className="footer-main row">
              <div className="foooter-logo-div  col-md-6 col-lg-4 col-sm-12 ">
                <a className="navbar-brand foot-logo" href="/">
                  <img
                    src={logo}
                    // height="100px"
                    // width="200px"
                    className="footer-avaakay-logo"
                  />
                </a>
                <p className="footer-logo-para">
                  Explore our wide selection of pickled delights and discover
                  new flavors to tantalize your taste buds.
                </p>
              </div>
              <div className="footer-company-div  col-md-6 col-lg-4 col-sm-12 ">
                <ul className="footer-ul">
                  <Link to="/profile" className="footer-links">
                    {" "}
                    <li>My Account</li>
                  </Link>
                  <Link to="/myorders" className="footer-links">
                    {" "}
                    <li className="footer-links">My Orders</li>
                  </Link>
                  <li className="footer-links">
                    <Link
                      to="/cart"
                      className="footer-links"
                      style={{ textDecoration: "none" }}
                    >
                      Shopping Cart
                    </Link>
                  </li>
                  <li className="footer-links">
                    <Link
                      to="/terms-conditions"
                      className="footer-links"
                      style={{ textDecoration: "none" }}
                    >
                     Terms And Conditions 
                    </Link>
                  </li>
                  <li className="footer-links">
                    <Link
                      to="/deliveryandshippingpolicy"
                      className="footer-links"
                      style={{ textDecoration: "none" }}
                    >
                    Delivery and Shipping Policy
                    </Link>
                  </li>
                  <li className="footer-links">
                    <Link
                      to="/privacypolicy"
                      className="footer-links"
                      style={{ textDecoration: "none" }}
                    >
                  Privacy Policy
                    </Link>
                  </li>
                  <li className="footer-links">
                    <Link
                      to="/refundandcancellationpolicy"
                      className="footer-links"
                      style={{ textDecoration: "none" }}
                    >
                 Refund and Cancellation Policy
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="footer-help-div col-md-12 col-lg-4 col-sm-12 ">
                <h3 className="h3">
                  <span style={{ color: "white" }}>Store Location: </span>1st Lane Brindavan Gardens, Opp Saffron Restaurant, Beside HP
                Petrol Bunk, Guntur, Andhra Pradesh- 522007
                </h3>
                <div className="contacts">
                  <h3 className="footer-contact-details">
                    <PiPhoneCall className="me-1" />
                   
                    <a className="maii-link" href="tel:8520034998">+91 8520034998/</a> <a className="maii-link" href="tel:9963262324">9963262324</a>
                  </h3>
                  <h3 className="footer-contact-details">
                    <TfiEmail className="me-2" />
                    <a
                      href="mailto:avakaayamart@gmail.com"
                      className="maii-link"
                    >
                      avakaayamart@gmail.com
                    </a>
                  </h3>
                </div>
                <div className="social-icons">
                  <div className="social-icons-div">
                    <a
                      href="https://www.facebook.com/profile.php?id=61556541615867"
                      className="maii-link"
                    >
                      <FaFacebookF className="fb-instagram-twit" />
                    </a>
                  </div>
                  <div className="social-icons-div">
                    <a
                      href="https://www.instagram.com/avakaayamart/"
                      className="maii-link"
                    >
                      <FaInstagram className="fb-instagram-twit" />
                    </a>
                  </div>
                  <div className="social-icons-div">
                    <a
                      href="https://twitter.com/Avakaayamart"
                      className="maii-link"
                    >
                      <TfiTwitter className="fb-instagram-twit" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr className="footer-line" />
          <div className="footer-copy-right ">
            <p className="footer-copy-rights">
              © Copyright 2024, All Rights Reserved By Avakaaya Mart
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
