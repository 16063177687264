import React, { useContext, useState } from "react";

import { BsCheck2 } from "react-icons/bs";

import { SlHandbag } from "react-icons/sl";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { LuStar } from "react-icons/lu";
import "../css/viewproduct.css";
// import Navbar from "./Navbar";
import { useParams } from "react-router-dom";
import Pickle from "../../components/Data.js";
import axios from "axios";
import apiUrl from "../../config/config";
import { NavContext } from "../../context/NavProvider";
import BottomNavbar from "../../components/BottomNavbar";
import { StarFill } from "react-bootstrap-icons";
const ViewProduct = () => {
  const { myprofile, UserProfile } = useContext(NavContext);

  const [selectedItem, setSelectedItem] = useState(null);
  const handleClick = (item) => {
    setSelectedItem(item);
  };

  const { id } = useParams();

  const decodedId = decodeURIComponent(atob(id));


  const [pickleId, setPickelId] = useState(decodedId);
  const product = Pickle.find((item) => item.id === pickleId);
  const [selectedSize, setSelectedSize] = useState(
    product.additionalInfo[0].options[0]
  );
  if (!product) return <div>Product not found!</div>;

  const AddCart = async (id, selectedSize) => {
    try {
      const res = await axios.post(apiUrl + `/v2/cart/addCart/${myprofile.id}`, {
        productId: id,
        price: selectedSize.price,
        size: selectedSize.size,
        quantity: 1,
      },{ headers: {
        "Content-Type": "application/json",
      },
  
    }
    );
      if (res.data && res.data.message) {
        toast.success(res.data.message)
        
     
        UserProfile();
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message);
    }
  };

 
  const handleSizeChange = (event) => {
    const newSize = product.additionalInfo[0].options.find(
      (option) => option.size === event.target.value
    );
    setSelectedSize(newSize);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      <ToastContainer position="top-left" />
      <div className="container-fluid">
        <div className="container">
          <div className="description-main ">
            <div className="mango-pickle-div">
              <div>
                <img src={product.imageUrl} className="pickle-img" />
              </div>
              <div>
                <div class="card-body mango-card-div">
                  <div className="mango-top-card-div">
                    <h5 class="card-title mango-title">
                      {product.name} ({selectedSize.size})
                    </h5>
                  </div>
                  <div className="reviews">
                    <div className="stars-Review">
                      <p className="stars">
                        <StarFill />
                        <StarFill />
                        <StarFill />
                        <StarFill />
                        <LuStar />
                      </p>
                      <p> 4 reviews</p>
                    </div>
                   
                  </div>
                  <div className="rate-percent">
                    <p class="card-text">
                      <span className="rates">₹{selectedSize?.price}</span>
                    </p>
                  </div>
                  <hr className="hr-line" />
                  <p class="card-text mango-card-para">
                    {product?.description}
                  </p>
                  <hr className="hr-line" />

                  <div className="add-to-cart-div">
                    <div className="w-25">
                      <select
                        onChange={handleSizeChange}
                        className="viewproduct-select-options"
                      >
                        {product.additionalInfo[0].options.map((option) => (
                          <option
                            key={option.size}
                            value={option.size}
                            className="view-product-options"
                          >
                            {option.size}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div>
                      <button
                        className="add-tocart-btn"
                        onClick={() => AddCart(product.id, selectedSize)}
                      >
                        Add to Cart <SlHandbag className="bag-btn" />
                      </button>
                    </div>
                  </div>
                  <hr className="hr-line" />
                </div>
              </div>
            </div>
            <div className="description-center">
              <div className="des">
                <div className="description-heading">
                  <h1 className="">Descriptions</h1>
                </div>

                {product?.additionalInfo?.map((info, index) => (
                  <div key={index} className="description-container">
                    <div className="description-para">
                      <span className="feature-name">{info.name}</span>
                      <p className="feature">{info.description}</p>
                    </div>
                    <div className="li-main-div">
                      <ul className="li-items">
                        {info.features.map((feature, fIndex) => (
                          <li key={fIndex}>
                            <div className="li-check-div">
                              <div className="">
                                <span className="check-div">
                                  <BsCheck2 className="check-icon" />
                                </span>
                              </div>
                              <div>
                                <span className="feature">{feature}</span>
                              </div>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div>
              <div>
                <h1 className="related-products">Related Products</h1>
              </div>

              <div className="Products view-product-filter-map">
                {Pickle.filter((item) => item.category === product.category)
                  .slice(0, 4)
                  .map((item, index) => {
                    const isInCart =
                      myprofile &&
                      myprofile.cartlist &&
                      myprofile.cartlist
                        .map((val) => val.productId)
                        .includes(item.id);

                    return (
                      <div className="view-product-cards" key={index}>
                        <div
                          className={`card pickles-card `}
                          style={{ width: "15rem" }}
                        >
                          <img
                            src={item.imageUrl}
                            onClick={() => {
                              setPickelId(item.id);
                              scrollToTop();
                            }}
                            className="card-img-top"
                            alt="..."
                          />

                          <div className="card-body viewproduct-card-body">
                            <div className="product-data">
                              <h6 className="card-title">{item.name}</h6>
                              <p className="card-text ">
                                <b>{item.price}</b>
                              </p>
                            </div>
                            <div className="cart-button">
                              <button
                                className={`cart-button-1 ${
                                  isInCart ? "active-button" : "default-button"
                                }`}
                              >
                                <SlHandbag />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <BottomNavbar />
      </div>
    </>
  );
};

export default ViewProduct;
