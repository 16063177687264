import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { SlHandbag } from "react-icons/sl";
import { IoChevronBack } from "react-icons/io5";
import { GrFormNext } from "react-icons/gr";
import { NavContext } from "../../context/NavProvider";
// import Navbar from "./Navbar";
import BottomNavbar from "../../components/BottomNavbar";
import "../css/shopingpage.css";
import Pickle from "../../components/Data.js";

const Shopingpage = () => {
  const { tabfilter, myprofile, UserProfile, filter } = useContext(NavContext);
  const [search, setSearch] = useState("");
  const [category, setCategory] = useState(tabfilter);
  const [filterData, setFilterData] = useState(Pickle);
  const [priceRange, setPriceRange] = useState("all");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(8);
  const location = useLocation();
  const searchFilter = location.state?.searchFilter || "";

  const filterProducts = () => {
    let filteredResults = Pickle.filter((val) => {
      const categoryMatch =
        tabfilter === "" ||
        val?.category?.toLowerCase() === tabfilter?.toLowerCase();
      const searchMatch = val?.name
        .toLowerCase()
        .includes(
          search?.toLowerCase() ||
            filter?.toLowerCase() ||
            searchFilter?.toLowerCase()
        );
      let priceMatch = true;
      if (priceRange === "0-500") {
        priceMatch = val.price >= 0 && val.price <= 500;
      } else if (priceRange === "500-1000") {
        priceMatch = val.price > 500 && val.price <= 1000;
      }

      return categoryMatch && searchMatch && priceMatch;
    });

    setFilterData(filteredResults);
  };

  const handleSearchChange = (e) => {
    const searchVal = e.target.value;
    setSearch(searchVal);
  };



  const handlePriceRangeChange = (e) => {
    const selectedPriceRange = e.target.value;
    setPriceRange(selectedPriceRange);
  };

  useEffect(() => {
    filterProducts();
  }, [search, category, tabfilter, priceRange, filter]);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filterData.slice(indexOfFirstItem, indexOfLastItem);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(filterData.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    UserProfile();
  }, []);


  return (
    <>
      <div className="container-fluid">
        <div className="container">
          <div className="home-container">
            <div className="combined-dropdowns">
              <div className="left-dropdowns">
                <div>
                  <input
                    type="text"
                    placeholder="Search here"
                    className="form-control"
                    value={search}
                    onChange={handleSearchChange}
                  />
                </div>
                {/* <div className="shoping-page-price">
                  <select
                    className="form-control"
                    value={priceRange}
                    onChange={handlePriceRangeChange}
                  >
                    <option value="all">Select By Price</option>
                    <option value="0-500">0 - 500</option>
                    <option value="500-1000">500 - 1000</option>
                  </select>
                </div> */}
              </div>
            </div>
            <h1 className="shoping-page-pickles-name ">
              {category === "Non"
                ? "Nonveg Pickles"
                : category === "Veg"
                ? "Veg Pickles"
                : category === "masala"
                ? "Masalas"
                : category === "powder"
                ? "Chilli powders"
                : category === "sweets"
                ? "Sweets"
                : category === "kara and vadiyalu"
                ? "Kara items and Vadiyalu"
                :   ' All Pickles'}
            </h1>

            <div className="Products">
              {currentItems.length > 0 ? (
                currentItems.map((item, index) => {
                  const isInCart =
                    myprofile &&
                    myprofile.cartlist &&
                    myprofile.cartlist.includes(item.id);
                  const firstOption = item.additionalInfo[0].options[0];
                  return (
                    <div className="" key={index}>
                      <div
                        className={`card pickles-card `}
                        style={{ width: "17rem" }}
                      >
                        <Link
                           to={`/viewproduct/${btoa(item.id)}`}
                          className="product-link shopping-name-price"
                        >
                          <img
                            src={item.imageUrl}
                            className="card-img-top"
                            alt="..."
                          />

                          <div className="card-body card-body-container">
                            <div className="product-data">
                              <h6 className="card-title ">
                                {item.name}({firstOption.size})
                              </h6>
                              <p className="card-text">₹{firstOption.price}</p>
                            </div>
                            <div className="cart-button">
                              <button
                                className={`cart-button-1 ${
                                  isInCart ? "active-button" : "default-button"
                                }`}
                              >
                                <SlHandbag />
                              </button>
                            </div>
                          </div>
                        </Link>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div>No items present</div>
              )}
            </div>

            <div className="pagination">
              <button
                className="page-arrow"
                onClick={() => paginate(currentPage - 1)}
                disabled={currentPage === 1}
              >
                {<IoChevronBack className="back-icon" />}
              </button>

              {pageNumbers
                .slice(currentPage - 1, currentPage + 5)
                .map((number) => (
                  <button
                    key={number}
                    className={`page-number ${
                      currentPage === number ? "shoppingpageActive" : ""
                    }`}
                    onClick={() => paginate(number)}
                  >
                    {number}
                  </button>
                ))}

              <button
                className="page-arrow"
                onClick={() => paginate(currentPage + 1)}
                disabled={
                  currentPage === Math.ceil(filterData.length / itemsPerPage)
                }
              >
                {<GrFormNext className="next-icon" />}
              </button>
            </div>
          </div>
        </div>
      </div>
      <BottomNavbar />
    </>
  );
};

export default Shopingpage;
