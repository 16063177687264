import React, { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "../css/billing.css";
import apiUrl from "../../config/config";
import axios from "axios";
import QRCode from "qrcode";

import { NavContext } from "../../context/NavProvider";

import Pickle from "../../components/Data";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BottomNavbar from "../../components/BottomNavbar";
const BillingPage = () => {
  const { myprofile, UserProfile } = useContext(NavContext);
  const [updateIndex, setUpdateIndex] = useState(null);
  const [address, setAddress] = useState([]);
  const location = useLocation();
  const [clickedEdit, setClickedEdit] = useState(false);
  const items = location.state;
  const navigate = useNavigate();
  const [sizeBilling, setsizeBilling] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState(
    address && address.length > 0 ? address[0] : false
  );
  const qrCodeCanvasRef = useRef(null);
  const [error, setError] = useState("");
  const [userDetails, setUserDetails] = useState({
    address: {
      streetAddress: "",
      country: "",
      state: "",
      district: "",
      city: "",
      pincode: "",
      mobile: "",
    },
  });
  const states = [
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal",
  ];
  const addNewBillingAddress = async () => {
    if (!sizeBilling) {
      try {
        const {
          streetAddress,
          country,
          state,
          pincode,
          mobile,
          city,
          district,
        } = userDetails.address;

        if (
          !streetAddress ||
          !country ||
          !state ||
          !pincode ||
          !mobile ||
          !city ||
          !district
        ) {
          setError("Please fill out all address fields.");
          return;
        }
        if (pincode.trim().length < 6) {
          setError("pincode must be at  6 characters .");
          return;
        }
        if (mobile.trim().length < 10) {
          setError("Please enter valid mobile number");
          return;
        }

        const res = await axios.post(
          apiUrl + `/v3/address/addaddress/${myprofile.id}`,
          userDetails,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        setError("");
        if (res.status === 200) {
          UserProfile();
          setError(" ");
          setClickedEdit(false);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const {
          streetAddress,
          country,
          state,
          pincode,
          mobile,
          city,
          district,
        } = userDetails.address;

        if (
          !streetAddress ||
          !country ||
          !state ||
          !pincode ||
          !mobile ||
          !city ||
          !district
        ) {
          setError("Please fill out all address fields.");
          return;
        }
        if (pincode.trim().length < 6) {
          setError("pincode must be at  6 characters .");
          return;
        }
        if (mobile.trim().length < 10) {
          setError("Please enter valid mobile number");
          return;
        }
        const res = await axios.post(
          apiUrl + `/v3/address/updateaddress/${myprofile.id}`,
          { updateIndex, userDetails },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (res.status === 200) {
          UserProfile();
          setError(" ");
          setClickedEdit(false);
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  const placeOrder = async () => {
    try {
      if (!selectedAddress) {
        setError("Please select a billing address.");
        return;
      }

      const res = await axios.post(
        apiUrl + `/v4/order/createorder/${myprofile.id}`,
        {
          products: items.map((item) => ({
            productId: item.productId,
            quantity: item.quantity,
            price: item.price,
            size: item.size,
          })),
          totalAmount: finalTotalAmount,
          address: selectedAddress,
          orderId: "AVMT" + randomNumber,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (res.status === 201) {
        toast.success(
          "Your order has been successfully placed. Please expect a contact from our dealer soon.",
          {
            className: "toast-message",
          }
        );

        UserProfile();
        setTimeout(() => {
          navigate("/myorders");
        }, 5000);
      }
    } catch (error) {
      console.error(error);
      toast.error("An error occurred while placing the order.");
    }
  };

  const cancelToBack = async () => {
    setError("");
    setClickedEdit(false);
  };

  const handleRemoveAddress = async (id) => {
    try {
      const response = await axios.post(
        apiUrl + `/v3/address/removeaddress/${myprofile.id}`,
        {
          addressId: id,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        UserProfile();
      } else {
        console.error("Failed to remove billing address:", response.statusText);
      }
    } catch (error) {
      console.error("Error removing billing address:", error.message);
    }
  };

  const handleAddressChange = (address) => {
    setSelectedAddress(address);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserDetails((prevUserDetails) => ({
      ...prevUserDetails,
      address: {
        ...prevUserDetails.address,
        [name]: value,
      },
    }));

    if (name === "city" || name === "state" || name === "district") {
      const trimmedValue = value.replace(/^\s+/g, "");
      const nonNumericValue = trimmedValue.replace(/\d/g, "");
      setUserDetails((prevUserDetails) => ({
        ...prevUserDetails,
        address: {
          ...prevUserDetails.address,
          [name]: nonNumericValue,
        },
      }));
    }
    if (name === "streetAddress") {
      const trimmedValue = value.replace(/^\s+/g, "");

      setUserDetails((prevUserDetails) => ({
        ...prevUserDetails,
        address: {
          ...prevUserDetails.address,
          [name]: trimmedValue,
        },
      }));
    }
    setError(" ");
  };

  useEffect(() => {
    if (address && address.length > 0) {
      setSelectedAddress(address[0]);
    }
  }, [address]);

  const commonFunction = () => {
    // UserProfile();
    addNewBillingAddress();
  };

  const addNewBilling = (address, addressId) => {
    setClickedEdit(true);
    setsizeBilling(true);
    setUpdateIndex(addressId);
    if (address) {
      const { streetAddress, country, state, district, city, pincode, mobile } =
        address;
      setUserDetails((prevUserDetails) => ({
        ...prevUserDetails,
        address: {
          ...prevUserDetails.address,
          streetAddress,
          country,
          state,
          district,
          city,
          pincode,
          mobile,
        },
      }));
    }
  };

  useEffect(() => {
    setAddress(myprofile?.addresses || []);
    // UserProfile()
  }, [myprofile]);

  const totalAmount1 = items
    .reduce((total, item) => total + item.price * item.quantity, 0)
    .toFixed(2);

  // const totalWeight = items.reduce((acc, item) => {
  //   const size = parseFloat(item.size);
  //   const quantity = item.quantity || 1;
  //   return acc + size * quantity;
  // }, 0);
  const totalWeight = items.reduce((acc, item) => {
    const size = parseFloat(item.size);
    const unit = item.size.replace(size, "").trim();
    let sizeInGrams;

    if (unit === "g") {
      sizeInGrams = size;
    } else if (unit === "kg") {
      sizeInGrams = size * 1000;
    } else {
      throw new Error(`Unknown unit: ${unit}`);
    }

    const quantity = item.quantity || 1;
    return acc + sizeInGrams * quantity;
  }, 0);

  const weightInKg = totalWeight / 1000;

  const deliveryCharge = () => {
    if (!selectedAddress) {
      return 0;
    }

    const state = selectedAddress.state?.toLowerCase();
    const district = selectedAddress.district?.toLowerCase();
    const city = selectedAddress.city?.toLowerCase();

    if (
      state === "andhra pradesh" ||
      state === "ap" ||
      state === "andhrapradesh"
    ) {
      if (district === "guntur") {
        if (city === "guntur") {
          return 0;
        } else {
          return Math.ceil(weightInKg) * 100;
        }
      } else {
        return Math.ceil(weightInKg) * 100;
      }
    } else {
      return Math.ceil(weightInKg) * 100;
    }
  };

  const charge = deliveryCharge();

  const finalTotalAmount = (parseFloat(totalAmount1) + charge).toFixed(2);

  const randomNumber =
    Math.floor(Math.random() * (999999 - 100000 + 1)) + 100000;

  useEffect(() => {
    generateQRCode();
  }, [finalTotalAmount]);

  const generateQRCode = () => {
    const upiID = "8790691956@ybl";
    const name = ""; 
    let upiLink = `upi://pay?pa=${upiID}&pn=${name}`;

    if (finalTotalAmount) {
      upiLink += `&am=${finalTotalAmount}`;
    }

    const canvas = qrCodeCanvasRef.current;
    QRCode.toCanvas(canvas, upiLink, { errorCorrectionLevel: "L" }, (error) => {
      if (error) console.error(error);
    });
  };

  return (
    <>
      <ToastContainer position="top-left" />
      <div className="container-fluid billing-main-container">
        <div className="container ">
          <h2 className="billing-information">Billing Information</h2>
          <div className="billing-container">
            <div className="billing-address-container-div">
              <div className="billing-address-container-div-text">
                {!clickedEdit && (
                  <>
                    {address && address?.length > 0 ? (
                      <div className="">
                        {address?.map((address, index) => (
                          <div
                            key={index}
                            className={`billing-address-card-container ${
                              selectedAddress === address ? "active" : ""
                            }`}
                          >
                            <div className="billing-address-heading">
                              <div>
                                <input
                                  type="radio"
                                  id={`address_${index}`}
                                  name="address"
                                  className="me-3"
                                  value={address}
                                  checked={selectedAddress === address}
                                  onChange={() => setSelectedAddress(address)}
                                />

                                <label className="address-radio-btn">{`address_${
                                  index + 1
                                }`}</label>
                              </div>

                              <>
                                <div className="">
                                  <div className="dropdown">
                                    <button
                                      className="card-delete-btn  "
                                      size="button"
                                      id="dropdownMenuButton1"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                    >
                                      ...
                                    </button>
                                    <ul
                                      className="dropdown-menu "
                                      aria-labelledby="dropdownMenuButton1"
                                    >
                                      <li className="">
                                        <button
                                          className="billing-remove-address-btn"
                                          onClick={() =>
                                            handleRemoveAddress(address.id)
                                          }
                                        >
                                          {" "}
                                          Delete
                                        </button>
                                      </li>
                                      <li className="">
                                        {" "}
                                        <button
                                          className="billing-remove-address-btn"
                                          onClick={() =>
                                            addNewBilling(address, address.id)
                                          }
                                        >
                                          Edit
                                        </button>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </>
                            </div>
                            <div className="billing-address-details">
                              <div className="ms-5">
                                <span className="address-radio-btn-p">
                                  {address?.streetAddress},
                                </span>
                                <span className="address-radio-btn-p">
                                  {address?.city},
                                </span>
                                <span className="address-radio-btn-p">
                                  {address?.district},
                                </span>
                                <span className="address-radio-btn-p">
                                  {address?.state},
                                </span>

                                <span className="address-radio-btn-p">
                                  {address?.country},
                                </span>
                                <p className="address-radio-btn-p mt-2">
                                  <strong>Pin code :</strong> {address?.pincode}
                                </p>
                                <p className="address-radio-btn-p mt-2">
                                  <strong>Phone Number:</strong>{" "}
                                  {address?.mobile
                                    ? address?.mobile
                                    : myprofile.mobile}
                                </p>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div>
                        {error && <p className="error-message">{error}</p>}
                        <p className="billing-information">
                          Please add delivery address.{" "}
                        </p>
                      </div>
                    )}
                  </>
                )}
                {clickedEdit && (
                  <>
                    <div className="billing-left">
                      <div className="main-form">
                        {error && <p className="error-message">{error}</p>}
                        <form>
                          <div className="street-input">
                            <div>
                              <label
                                htmlFor="streetAddress"
                                className="form-label billing-label"
                              >
                                Street Address
                              </label>
                            </div>
                            <input
                              size="text"
                              className="form-control street"
                              id="streetAddress"
                              name="streetAddress"
                              placeholder="Complete your address"
                              value={userDetails.address.streetAddress}
                              onChange={handleInputChange}
                            />
                          </div>
                          <div className="form-top-details">
                            <div className="form-top-details-inner">
                              <div>
                                <label
                                  htmlFor="city"
                                  className="form-label billing-label"
                                >
                                  City
                                </label>
                              </div>
                              <input
                                size="text"
                                className="form-control top-control"
                                id="city"
                                name="city"
                                placeholder="Your city"
                                value={userDetails.address.city}
                                onChange={handleInputChange}
                              />
                            </div>
                            <div className="form-top-details-inner">
                              <div>
                                <label
                                  htmlFor="district"
                                  className="form-label billing-label"
                                >
                                  District
                                </label>
                              </div>
                              <input
                                size="text"
                                className="form-control top-control"
                                id="district"
                                name="district"
                                placeholder="Your district"
                                value={userDetails.address.district}
                                onChange={handleInputChange}
                              />
                            </div>
                            <div className="form-top-details-inner">
                              <div>
                                <label
                                  htmlFor="state"
                                  className="form-label billing-label"
                                >
                                  State
                                </label>
                              </div>
                              <select
                                className="form-control top-control"
                                id="state"
                                name="state"
                                value={userDetails.address.state}
                                onChange={handleInputChange}
                              >
                                <option value="">Select</option>
                                {states.map((state, index) => (
                                  <option key={index} value={state}>
                                    {state}
                                  </option>
                                ))}
                              </select>
                            </div>

                            <div className="form-top-details-inner">
                              <div>
                                <label
                                  htmlFor="zipCode"
                                  className="form-label billing-label"
                                >
                                  Pin Code
                                </label>
                              </div>
                              <input
                                size="number"
                                type="text"
                                className="form-control top-control"
                                id="zipCode"
                                name="pincode"
                                placeholder="Pin Code"
                                value={userDetails.address.pincode}
                                onChange={handleInputChange}
                                maxLength={6}
                                minLength={6}
                                onInput={(e) => {
                                  const numericValue = e.target.value
                                    .replace(/\D/g, "")
                                    .slice(0, 10);
                                  handleInputChange({
                                    target: {
                                      name: "pincode",
                                      value: numericValue,
                                    },
                                  });
                                }}
                                onKeyDown={(e) => {
                                  if (
                                    !(
                                      e.key === "Backspace" ||
                                      e.key === "Delete"
                                    ) &&
                                    !/[\d]/.test(e.key)
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                              />
                            </div>

                            <div className="form-top-details-inner">
                              <div>
                                <label
                                  htmlFor="countryRegion"
                                  className="form-label billing-label"
                                >
                                  Country / Region
                                </label>
                              </div>
                              <select
                                className="form-control top-control"
                                id="countryRegion"
                                name="country"
                                value={userDetails.address.country}
                                onChange={handleInputChange}
                              >
                                <option value="">Select</option>
                                <option value="India">India</option>
                              </select>
                            </div>
                          </div>
                          <div className="form-bottom-details">
                            <div className="form-bottom-details-inner">
                              <div>
                                <label
                                  htmlFor="phone"
                                  className="form-label billing-label"
                                >
                                  Phone Number
                                </label>
                              </div>

                              <input
                                className="form-control bottom-control"
                                id="phone"
                                name="mobile"
                                placeholder="Phone number"
                                value={userDetails.address.mobile}
                                onChange={handleInputChange}
                                maxLength={10}
                                minLength={10}
                                onInput={(e) => {
                                  const numericValue = e.target.value
                                    .replace(/\D/g, "")
                                    .slice(0, 10);
                                  handleInputChange({
                                    target: {
                                      name: "mobile",
                                      value: numericValue,
                                    },
                                  });
                                }}
                                onKeyDown={(e) => {
                                  if (
                                    !(
                                      e.key === "Backspace" ||
                                      e.key === "Delete"
                                    ) &&
                                    !/[\d]/.test(e.key)
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                                size="text"
                              />
                            </div>
                          </div>
                        </form>
                        <div className="cancel-btn">
                          <button
                            className="billing-edit-details "
                            onClick={cancelToBack}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                <div className="billing-editd-container">
                  {clickedEdit ? (
                    <button
                      className="billing-edit-details"
                      onClick={() => commonFunction()}
                    >
                      {!sizeBilling ? " Save" : "Save"}
                    </button>
                  ) : (
                    <button
                      className=" billing-edit-details"
                      onClick={() => setClickedEdit((preState) => !preState)}
                    >
                      Add Address
                    </button>
                  )}
                </div>
              </div>
              {/* <p className="billing-delivery-charge-content">
                <b>Note:</b> If your order exceeds ₹399, you'll receive free
                delivery to your address in Guntur city.
              </p> */}
            </div>
            <div className="billing-right-container">
              <div className="billing-right">
                <h2 className="order-heading">Order Summary</h2>
                {items && items.length > 0 ? (
                  <>
                    {items.map((item, index) => {
                      const productDetails = Pickle.find(
                        (pickle) => pickle.id === item.productId
                      );
                      return (
                        <div key={index} className={`order-top-image`}>
                          <div className="order-top-inner">
                            <img
                              src={productDetails.imageUrl}
                              alt="image"
                              className="billing-image"
                            />
                            <p className="billing-name-quantity">
                              {productDetails.name}({item.size}) x{" "}
                              {item.quantity}
                            </p>
                          </div>
                          <p className="billing-amount">
                            ₹{(item.price * item.quantity).toFixed(2)}
                          </p>
                        </div>
                      );
                    })}
                    <div className="subtotal-div">
                      <p className="billing-name-quantity">Subtotal:</p>
                      <p className="billing-amount">₹{totalAmount1}</p>
                    </div>
                    <hr />
                    <div className="subtotal-div">
                      <p className="billing-name-quantity">Shipping:</p>
                      <p className="billing-amount">
                        {!selectedAddress ? (
                          <p className="billing-amount-delivery">
                            Please select a delivery address
                          </p>
                        ) : (
                          <p className="billing-amount">
                            ₹{charge?.toFixed(2)}
                          </p>
                        )}
                      </p>
                    </div>
                    <hr />
                    <div className="subtotal-div">
                      <p className="billing-name-quantity">Total:</p>
                      <p className="billing-amount-1">₹{finalTotalAmount}</p>
                    </div>
                    <h3 className="order-heading">Payment Method</h3>
                    <div className="form-check">
                      <label
                        className="form-check-label billing-name-quantity"
                        htmlFor="cashOnDelivery"
                      >
                        Cash on Delivery
                      </label>
                      {/* <canvas ref={qrCodeCanvasRef}></canvas> */}
                    </div>
                    <button className="place-order-btn" onClick={placeOrder}>
                      Place Order
                    </button>
                    {/* <button onClick={generateQRCode}>Generate QR Code</button> */}
                    <br />
                  </>
                ) : (
                  <p>No items in the cart</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <BottomNavbar />
      </div>
    </>
  );
};

export default BillingPage;
